import {  useEffect, useRef, useState } from "react";
import {   useHistory } from "react-router-dom";
import  t from "kls-i18n"; 
import { Dropdown, DropdownList, Tabs } from "kls-ui";
import { getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { GS, saveGlobaleState } from "kls-commons/state/global-state";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import style from "./apps-explorer.module.css" ;
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { Directory, generateResourcesFromModel, getLabelDirectory, loadAllModule } from "../module/module.service";
import Spinner from "../module/spinner"
import { EmptyResult } from "./empty-result";
import {handleClickOutside} from "kls-ui"
import { getTypeOf } from "kls-commons/service/resource-service";


export function AppsExplorer(props: any) {
  let history=useHistory();  
  let [searchKey, setSearchKey] = useState(""); 

  let [forms, setForms] = useState([] as any[]);
  let [searchs, setSearchs] = useState([] as any[]);
  let [taskLists, setTaskLists] = useState([] as any[]);
  let [menus, setMenus] = useState([] as any[]);
  let [processes, setProcess] = useState([] as any[]);
  let [apps, setApps] = useState(  [] as Directory[]); 
  let [models, setModels] = useState(  [] ); 
  let [currentModuleName , setCurrentModuleName] = useState('');
  let [currentModelName , setCurrentModelName] = useState('');
  let [currentModuleLabel , setCurrentModuleLabel] = useState(currentModuleName);
  let [currentModelLabel , setCurrentModelLabel] = useState(currentModelName);
  const [menuItems, setMenuItems] = useState<any>([]);
  const [menuItem, setMenuItem] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false) ;  
  const [currentResource, setCurrentResource] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<string>( 'view' ) ;
  const popupContainerRef = useRef<HTMLDivElement>(null) ;
  let [moduleTree, setModuleTree] = useState(undefined as unknown as WorkingDirTree);

  useEffect(
    () => {
        handleClickOutside( popupContainerRef, props.onClose );
    }, [popupContainerRef]
  )


  const onCurrentModelNameChange = ( model:any ) => {
      console.log( "onCurrentModelNameChange:", model ); 
      if( model ) {
        setCurrentModelName( model.name );
        setCurrentModelLabel( model.label );
      }  
  } 

  const onCurrentModuleNameChange = ( value:string ) => {
    getWorkingDirectorTree(value).then(moduleTree => {
      setModuleTree(moduleTree); 
    })
    console.log( "onCurrentModuleNameChange:", value ); 
    setCurrentModuleName( value );
    setCurrentModelName( '' );
    setCurrentModelLabel( '' );
    getLabelDirectory( value ).then(
      (label) => {setCurrentModuleLabel( label )}
    )
    GS.currentModuleName=value;
    saveGlobaleState(GS);
  } 

  useEffect(
      () => {
          setLoading( true ) ;

          loadAllModule().then(
              (listModules) => {
                setApps( listModules ) ;
                setLoading( false ) ;
              }
          ).catch(
              () => {
                setLoading( false ) ;
              }
          )
      } , []
  )
  

  useEffect(() => {
    models = [] ;
    parseTreeDir(moduleTree, forms, searchs, taskLists, processes, menus, models);

    if( !currentModelName ){
      setMenuItems( [] ) ;
    }

    if( moduleTree && moduleTree.directory && moduleTree?.children?.[currentModelName]?.model ) {
      let menu = generateResourcesFromModel( moduleTree?.children?.[currentModelName] ) ;
      if( menu ) {
        setMenuItems( menu ) ;
      }
      console.log("menu:",menu);
    }
    setModels(models);
  }, [searchKey, moduleTree, currentModelName]);


  useEffect(
    () => {
      if(menuItems && selectedTab)
        setMenuItem( menuItems.find( m => m.id === selectedTab ) ) ;
    } , [selectedTab, menuItems]
  )


  const handleChangeResource = (item:any) => {
    const type = getTypeOf(item.value) ;

    console.log( "handleChangeResource : ", item )
    
    history.push( '/editService/' + currentModuleName + '/' + currentModelName + '/' + type + '/' + item.name )

    props.onClose() ;
  }

  return (
    <div ref={popupContainerRef} className={style['apps-explorer'] }>
      <div className={ style['header'] }>
        <div className={ style['tohomepage-container'] } onClick={ () => history.push( '/' ) }>
          <span className="material-icon" style={{height:'16px', width:'16px'}}>chevron_left</span>
          <span>Accueil</span>
        </div>
      </div>

      <div className={ style['advanced-search'] + ' advanced-search' }>
        {
          loading ? 
          <div style={{
                      display:"flex", 
                      alignItems:'center', 
                      justifyContent:'center', 
                      height:'100%', 
                      width:'100%', 
                      marginTop:'30px'
                      }
                }>
            <Spinner scale={0.4}/>
          </div>
          :
          <>
          <span className={ style['advanced-search-title'] }>Recherche avancée</span>

          <div className={ style['apps-search-container'] }>


            
            <Dropdown   data={apps.map( m => ({ label:m.title, name:m.name }) )}
                        klsCompatible={true}
                        size="xs"
                        elementSize={32}
                        searchable={true}
                        value={currentModuleLabel}
                        listzIndex={200000}
                        args={
                          {
                            placeholder:"Application"
                          }
                        }
                        onSelect={(selectedItem) => { onCurrentModuleNameChange( selectedItem.name ) }}
            />

            <Dropdown   data={models}
                        klsCompatible={true}
                        size="xs"
                        elementSize={32}
                        searchable={true}
                        value={currentModelLabel}
                        listzIndex={200000}
                        args={
                          {
                            placeholder:"Module"
                          }
                        }
                        onSelect={(selectedItem) => { onCurrentModelNameChange( selectedItem ) }}
            />

          </div>

          <Tabs align="horizontal" 
                current= {selectedTab}
                size="small" 
                onSelect={setSelectedTab}
                tabs={[{label: 'Pages', name: 'view',type: 'tab'},{label: 'Recherches', name: 'search',type: 'tab'},{label: 'Corbeilles', name: 'tasklist',type: 'tab'},{label: 'Processus', name: 'process',type: 'tab'},{label: 'Menus', name: 'menu',type: 'tab'}]}
          />


          <div style={{position:'relative', width: '100%'}}>
                {
                    menuItem?.children ?
                    <AdvancedDropDown data={(menuItem?.children.map(item => ({ value: item.resourceQN, name: item.name, label: item.label, icon: item.icon, color: item.color })))||[]} 
                                      light={true}
                                      handleChange={(item)=>handleChangeResource(item)} 
                                      size={3} 
                                      maxItemsPerPage={8}
                                      enableSearch={true} 
                                      addNew={menuItem && menuItem.newElement?{ label: menuItem.newElement.label, handleClick: () => { props.handleCreateNewElement( { module:currentModuleName, model:currentModelName, typeResource:menuItem.id} ) ; props.onClose() } }:null} 
                                      selectedValue={ currentResource } 
                                      className={ style['advanced-search-resource'] }
                                      style={{position:'static'}}/>
                    : <EmptyResult resource={selectedTab} />
                }
                
          </div>  
          </>
        }
      </div>
    </div>
  );
}


function LinkForRubrique(props: any) {
  let ssr = props.rub;

  return (
    <div className="rubric-item">
      <div
        onClick={() => {
          props.startEdit(ssr);
        }}
        className="nav-link"
      >
        
          <i className={"link-icon "+ssr.icon}></i> 
          <span className="link-label"> {ssr.fileName}  </span>
      </div>
    </div>
  );
}

function mapModelNameToLabel(s: string) {
  if (!s) return s;
  return s.replace(/.*\//g, "");
}

function filterModels(
  ce: WorkingDirTree,
  searchKey: string,
  currentModelName: string
): WorkingDirTree {
  let ret =  ce;
  if (searchKey) {
    
  }
  if (currentModelName && currentModelName !== "*") { 
  }

  return ret;
}
function parseTreeDir(tree: WorkingDirTree, forms: any[], searchs: any[], taskLists: any[], processes: any[], menus: any[], models: any[]) {
  if(!tree)
      return;
  if(tree.model) {
      let directories = tree.info?.filePath?.split( "/" ) ; 
      if( directories && directories.length ) {
          getLabelDirectory( directories[directories.length-2] , directories[directories.length-1] ).then(
              (label) => { 
                console.log( "parseTreeDir Object : ", {name: tree.info?.fileName, label: label, value: tree.info?.filePath, moduleName: directories?.[directories.length-2]} )
                models.push({name: tree.info?.fileName, label: label, value: tree.info?.filePath, moduleName: directories?.[directories.length-2]}); 
              }
          ) 
      }
  }
  if((tree.model || tree.name==="process"  || tree.name==="search" ) && tree.children) {
     for(let k of Object.keys(tree.children)) {
      let file = tree.children[k].info; 
       
      if(!file)
        continue;
      if(file.fileName.endsWith("-view.xml")) {
        forms.push({...file, icon: "fa fa-window-maximize" });
      } else if(file.fileName.endsWith("-menu.xml")) {
        menus.push({...file, icon: "fa fa-bars" });
      } else if(file.fileName.endsWith("-taskList.xml")) {
        taskLists.push({...file, icon: "fa fa-list" });
      } else if(file.fileName.endsWith(".bpmn")) {
        processes.push({...file, icon: "fa fa-cog" });
      } else if(file.fileName.endsWith(".xml") && file.filePath.includes("/search/")) {
        searchs.push({...file, icon: "fa fa-search" });
      }




     }
   } 
  if(tree.children) {
    Object.keys(tree.children!).forEach(k=> parseTreeDir(tree.children![k], forms, searchs, taskLists, processes, menus, models));
   }
}

