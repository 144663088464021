import { addToast } from 'kls-ui';
import './support.css';
import { useState } from 'react';
import { SettingsContainerHeader } from '../../settings/settings';
export function Support(props: any) {
  const [copyClick, setCopyClick] = useState(false);
  const copyText = (identifier: any) => {
    let textToCopyElement: any;
    setCopyClick(true);
    if (identifier === 'phone') {
      textToCopyElement = document.querySelector('.app-support-cardTitle2-phone');
      let copied = true
      { copied && addToast('Text copied', "info") }
      copied = false

    } else if (identifier === 'email') {
      textToCopyElement = document.querySelector('.app-support-cardTitle2-email');
      let copied = true
      { copied && addToast("Text copied", "info") }
      setCopyClick(false)
    } else if (identifier === 'whatsapp') {
      textToCopyElement = document.querySelector('.app-support-cardTitle2-whatsapp');
      let copied = true
      { copied && addToast('Text copied', "info") }
      setCopyClick(false)
    }

    if (textToCopyElement) {
      const textToCopy = textToCopyElement.innerHTML;
      setCopyClick(true);

      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          console.log('Text copied successfully');
        })
        .catch(err => {
          console.error('Error copying text: ', err);
        });
    } else {
      console.error('Element not found for identifier: ', identifier);
    }
  };
  return (
    <div className='app-support-container'>
      <SettingsContainerHeader title='Support et aide' subtitle="Obtenez de l'aide de notre équipe de support client" />
      <div className='app-support-cardContainer'>
        <div className='app-support-card contact-support-card'>
          <div className='app-support-cardContent'>
            <span className='icon app-support-phoneIcon'>phone</span>
            <div className='app-support-cardTitle'>
              <p className='app-support-cardTitle1 support-phone'>Appelez-nous au :</p>
              <p className='app-support-cardTitle2-phone'>05 00 00 65 98</p>
            </div>
          </div>
          <span onClick={() => copyText('phone')} className='icon app-support-copyIcon'>content_copy</span>
        </div>
        <div className='app-support-card mail-support-card'>
          <div className='app-support-cardContent'>
            <span className='icon app-support-emailIcon'>alternate_email</span>
            <div className='app-support-cardTitle'>
              <p className='app-support-cardTitle1 support-email'>Contactez-nous à :</p>
              <p className='app-support-cardTitle2-email'>support@karaz.com</p>
            </div>
          </div>
          <span onClick={() => copyText('email')} className='icon app-support-copyIcon'>content_copy</span>
        </div>
        <div className='app-support-card whatsapp-support-card'>
          <div className='app-support-cardContent'>
            <img src='photos/whatsppp-icon.svg' alt='whatsapp' />
            <div className='app-support-cardTitle'>
              <p className='app-support-cardTitle1 support-whatsapp'>Contactez-nous à :</p>
              <p className='app-support-cardTitle2-whatsapp'>05 00 00 45 89</p>
            </div>
          </div>
          <span onClick={() => copyText('whatsapp')} className='icon app-support-copyIcon'>content_copy</span>
        </div>
      </div>
    </div>
  )
}

