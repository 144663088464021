import { Button, Input, Modal, TelephoneInput, ToggleButton, VerificationCode, addToast, SocialMediaInput, MobileViewVerification } from "kls-ui";
import { SettingsContainerHeader } from "../settings";
import './security.css';
import { useEffect, useState } from "react";
import { passwordStrengthValidator, testEmail } from "kls-auth/signup/validation";
import { fetchLocalUserProfile } from "kls-commons/service/userService";
import { UserAccessSecurityService, UserChangePassword, UserUpdateAccessSecurityService } from "../settings-services/security-services";
export function AccessSecurity(props: any) {
  const [isactive, setisactive] = useState(false);
  const [userName, setuserName] = useState<any>('');
  const [userNameErr, setuserNameErr] = useState('');
  const [emailid, setemailid] = useState('');
  const [emailidErr, setemailidErr] = useState(false);
  const [emailid2, setemailid2] = useState('');
  const [emailidErr2, setemailidErr2] = useState(false);
  const [telephone, setTelephone] = useState('');
  const [telephoneErr, settelephoneErr] = useState(false);
  const [verificationPop, setVerificationPop] = useState(false);
  const [changePasswordPopup, setchangePasswordPopup] = useState(false);
  let [passwordStrength, setpasswordStrength] = useState(0);
  let [isProgressVisible, setisProgressVisible] = useState(false)
  const [userAccessData, setuserAccessData] = useState<any>([])
  const [errorMessage, setErrorMessage] = useState(true);
  let [showPassword, setshowPassword] = useState(false);
  let [password, setpassword] = useState('');
  let [passwordErr, setpasswordErr] = useState('');
  let [password2, setpassword2] = useState('');
  let [password2Err, setpassword2Err] = useState('');
  let [password3, setpassword3] = useState('');
  let [password3Err, setpassword3Err] = useState('');
  let [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  let [showNewPassword, setshowNewPassword] = useState(false);
  let [focusPassword, setFocusPassWord] = useState(false);
  let [strongPasswordErr, setStongPasswordErr] = useState('');
  let [counter, setcounter] = useState(0)
  let [secondCounter, setsecondCounter] = useState(0)
  let [socialLink, setsocialLink] = useState('')
  let [socialType, setsocialType] = useState('')
  const handleactive = () => {
    setisactive(!isactive);
  }

  const changePasswordPopUp = () => {
    setchangePasswordPopup(true)
  }

  const handleVerification = () => {
    setVerificationPop(true);
  }
  const handleVerificationPopUpClose = () => {
    setVerificationPop(false);
  }

  const validationPassword = () => {
    if (password2 !== password3) {
      setpassword3Err('Mot de passe incorrect')
    }
  }

  const handleSubmitPasswordErr = () => {
    if (password === '') {
      setpasswordErr('Mot de passe requis')
    }
    if (password2 === '') {
      setpassword2Err('Mot de passe requis')
    }
    if (password3 === '') {
      setpassword3Err('Confirm mot de passe requis')
    }
    if (password2.length < 8) {
      setpassword2Err('Votre mot de passe doit comporter au moins 8 caractères');
    }
    if (password === password2) {
      setpassword2Err('Veuillez entrer un nouveau mot de passe');
    }
  }

  useEffect(() => {
    let userData = fetchLocalUserProfile()
    UserAccessSecurityService(userData?.userId).then((res) => {setuserAccessData(res.data);
      setuserName(res.data?.nomcomplet ?? userName)
      setemailid(res.data?.email ?? emailid)
      setemailid2(res.data?.emailSecond?? emailid2)
      setTelephone(res.data?.telmobile ?? telephone)
      setsocialLink(res.data?.reseauSocialLink ?? socialLink)
      setsocialType(res.data?.reseauSocialType ?? socialType)
    })
    .catch((err) => console.log(err))
  }, [])

  const UpdateUserSecurity = () => {
    let userData = fetchLocalUserProfile()
    UserUpdateAccessSecurityService(userData?.userId, `${isactive}`, telephone, socialLink, socialType, emailid2).then((res) => {res.result === "done" ? addToast("Successfully Updated!", "success") : addToast("Error", res.error)})
  }

  const updateChangePassword = async ()=>{
    let loggedinUser = fetchLocalUserProfile();
    try{
        await UserChangePassword(loggedinUser?.userId, password, password2, password3)
          .then(
            (result)=>{
              if(result?.error){
                addToast(result?.error, "error")
              }
              else if(result?.Success){
                setchangePasswordPopup(false)
                addToast("Le mot de passe est mis à jour", "success")
                setpassword(''); setpassword2(''); setpassword3('');
              }
            }
          );
    }
    catch(error){
      console.log("Api error", error)
    }
  }

  const changePasswordContent = () => {
    return (
      <div className="app-security-authContainer">
        <div className="app-security-actualPassword">
          <Input
            errorMessage={passwordErr}
            value={password}
            args={{
              type: showPassword ? "text" : "password", required: true, value: password, placeholder: 'Mot de passe actuel', onChange: (e) => {
                // setpasswordStrength(passwordStrengthValidator(e.target.value));
                setpassword(e.target.value);
                setpasswordErr('');
                if (e.target.value == '') {
                  setpasswordErr("Mot de passe requis")
                }
              }
            }}
            infoText=""
            label="Mot de passe actual"
            size="md"
          />
          {passwordErr == '' && <img className="eyeIcon security-eyeIcon" src={showPassword ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPassword(!showPassword)}></img>}
        </div>
        <div className="app-security-newPassword">
          <Input
            errorMessage={password2Err}
            value={password2}
            args={{
              type: showNewPassword ? "text" : "password", value: password2, required: true, placeholder: 'Nouveau mot de passe', onChange: (e) => {
                setpasswordStrength(passwordStrengthValidator(e.target.value));
                setpassword2(e.target.value);
                setpassword2Err('');
                if (e.target.value == '') {
                  setpassword2Err("Mot de passe requis")
                }
              },
              onFocus: () => {setFocusPassWord(true); setisProgressVisible(!isProgressVisible)},
              onBlur: () => {setFocusPassWord(false); setisProgressVisible(false)}
            }}
            infoText=""
            label="Nouveau mot de passe"
            size="md"
          />
          {password2Err == '' && <img className="eyeIcon security-eyeIcon" src={showNewPassword ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowNewPassword(!showNewPassword)}></img>}
          {focusPassword && <div>
            {isProgressVisible &&
              <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <div className="progress">
                  <div id="password-strength" className={passwordStrength == 0 ? "progress-bar" : passwordStrength <= 2 ? "progress-bar progress-bar-danger" : passwordStrength == 3 ? "progress-bar progress-bar-warning" : passwordStrength == 4 ? "progress-bar progress-bar-success" : "progress-bar"} role="progressbar"></div>
                </div>
              </div>
            }
            <p style={{ margin: '0', color: '#64748B', marginTop: '6px' }}>Votre mot de passe doit comporter au moins 8 caractères</p>
          </div>}
        </div>
        <div className="pp-security-comfirmPassword">
          <Input
            errorMessage={password3Err}
            value={password3}
            args={{
              type: showPasswordConfirm ? "text" : "password", value: password3, required: true, placeholder: 'Confirmez mot de passe', onChange: (e) => {
                // setpasswordStrength(passwordStrengthValidator(e.target.value));
                setpassword3(e.target.value);
                setpassword3Err('');
                if (e.target.value == '') {
                  setpassword3Err("Confirmer mot de passe requis")
                }
              }
            }}
            infoText=""
            label="Confirmez mot de passe"
            size="md"
          />
          {password3Err == '' && <img className="eyeIcon security-eyeIcon" src={showPasswordConfirm ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}></img>}
        </div>
      </div>
    )
  }
  return (
    <>

      <div className={changePasswordPopup || verificationPop ? "changePassword-content" : "access-security-container"}>
        <SettingsContainerHeader title="Accès et sécurité" subtitle="Gérez l'accès et la sécurité de votre compte" />
        <div className="app-security-userDetais" style={{ marginTop: changePasswordPopup ? '32px' : '0' }}>
          <div className="app-security-username-email">
            <div className="app-security-username app-security-content">
              <Input label="Nom d’utilisateur" size="md" args={{ required: true, placeholder: "Entrez votre nom d’utilisateur", value: userName, disabled: true }} errorMessage={userNameErr} />
            </div>
            <div className="app-security-email-primary app-security-content">
              <Input label="Email (Principal)" size="md" args={{ required: true, placeholder: "Entrez votre adresse email", value: emailid, onChange: (e) => { setemailid(e.target.value); setemailidErr(false); setcounter(1) } }} errorMessage={emailidErr ? testEmail(emailid) : ""} />
            </div>
            <div className="app-security-email-secondary app-security-content">
              <Input label="Email (Secondaire)" size="md" args={{ placeholder: "Entrez votre adresse email", value: emailid2, onChange: (e) => { setemailid2(e.target.value); setemailidErr2(false); setsecondCounter(1) } }} errorMessage={emailidErr2 ? testEmail(emailid2) : ""} />
            </div>
          </div>
          <div className="app-security-phone-socialMedia">
            <div className="app-security-telephone">
              <p className="app-security-phoneTitle">Téléphone</p>
              <TelephoneInput errorMessage={telephoneErr} value={telephone} setValue={setTelephone} />
            </div>
            <div className="app-security-socialMedia">
              <p className="app-security-socialTitle">Réseau social professionnel</p>
              <SocialMediaInput value={socialType} link={socialLink} setValue={setsocialType} setLink={setsocialLink} />
            </div>
          </div>
        </div>
        <div className="app-security-changePasswordContainer" style={{ marginTop: changePasswordPopup ? '32px' : '0' }}>
          <div className="app-security-changePasswordContent">
            <p className="app-security-changePasswordTitle">Mot de passe</p>
            <p className="app-security-changePasswordSubtitle">Dernière modification il y a 4 ans</p>
          </div>
          <div className="app-security-changePasswordBtn">
            <Button label="Changer votre mot de passe" size="md" color="primary" styling="outline" args={{ onClick: changePasswordPopUp }} />
          </div>
        </div>
        <div className="app-security-DoubleAuthContainer">
          <ToggleButton handleToggle={handleactive} ischecked={isactive} />
          <div className="app-security-authContent">
            <p className="app-security-authTitle">Double authentification</p>
            <p className="app-security-authSubtitle">Un code d'authentification sera envoyé à votre e-mail chaque fois que vous essayez de vous connecter à votre compte</p>
          </div>
        </div>
        <div className="app-security-btn">
          <Button label="Enregistrer" size="md" color="primary" styling="solid" args={{
            onClick: () => {
              setemailidErr(true)
              setemailidErr2(true)
              if (userName === "") { setuserNameErr('Ce champ est requis') }
              if (telephone === '') { settelephoneErr(true) }
              if (userName !== "" && testEmail(emailid) === '' && (counter === 1 || secondCounter === 1) && testEmail(emailid2) === '' && telephone !== '' && socialLink !== '') {
                console.log(telephone, "telep")
                handleVerification()
              }
              UpdateUserSecurity()
            }
          }} />
          {verificationPop && <div className="security-verification-content"><VerificationCode handleVerificationClose={handleVerificationPopUpClose} label="Veuillez saisir ci-dessous le code à 6 caractères envoyé à votre nouvelle adresse email :" title="Modification d’adresse email" description="Vous ne trouvez pas votre code ? Vérifiez votre dossier Spam" buttonLabel="Mettre à jour email" handleSubmit={() => { addToast("Email modifié avec succès", "success") }} /></div>}
          {changePasswordPopup && <div className="changePassword-content"><Modal title="Nouveau mot de passe" icon="" onClose={() => { setchangePasswordPopup(false); setpassword(''); setpassword2(''); setpassword3(''); setpasswordErr(''); setpassword2Err(''); setpassword3Err('') }} leftBtnArgs={{ label: "Annuler", color: 'neutral', size: 'sm', styling: 'solid', args: { className: 'users-popup-button', onClick: () => setchangePasswordPopup(false) } }} rightBtnArgs={{ label: "Valider", color: 'primary', size: 'sm', styling: 'solid', args: { onClick: () => { validationPassword(); handleSubmitPasswordErr(); updateChangePassword() } } }}>{changePasswordContent()} </Modal></div>}</div>
      </div>
      {changePasswordPopup && <div className="changePassword-mobile" style={{ zIndex: '20' }}>
        <div style={{ display: 'flex', gap: '25px', alignItems: 'center' }}>
          <span className="icon" style={{ width: '16px', height: '16px' }} onClick={() => setchangePasswordPopup(false)}>arrow-back</span>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ fontSize: '15px', fontWeight: '500' }}>Accès et sécurité</div>
            <div style={{ fontSize: '12px', color: '#64748B' }}>Gérez l'accès et la sécurité de votre compte</div>
          </div>
        </div>
        {/* <SettingsContainerHeader title="Accès et sécurité" subtitle="Gérez l'accès et la sécurité de votre compte" /> */}
        {changePasswordContent()}
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}><Button label="Valider" color="primary" size="sm" styling="solid" args={{ onClick: () => { validationPassword(); handleSubmitPasswordErr() } }} /></div>
      </div>}
      {verificationPop && <div className="security-verification-mobile"><MobileViewVerification title="Accès et sécurité" subtitle="Gérez l'accès et la sécurité de votre compte" label="Modification d’adresse email" labelContent="Veuillez saisir ci-dessous le code à 6 caractères envoyé à votre nouvelle adresse email :" handleSubmit={() => { addToast("Email modifié avec succès", "success") }} handleVerificationClose={handleVerificationPopUpClose} labelbtn="Mettre à jour email" findTitle="Vous ne trouvez pas votre code ? Vérifiez votre dossier Spam" findSubtitle="RENVOYER LE CODE" /></div>}

    </>)
}
