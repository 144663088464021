import React from 'react'
import { postMap } from 'kls-commons/service/karaz-remote-action'

export async function OrganizationDeleteServices(userId: any, organisationID: any, password: any, passwordConfirmation: any, login: any) {

    let actionUrl = '/karazal/ow-kls-extrat/appmanager/deleteOrganism';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userId);
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("password", password);
    urlencoded.append("passwordConfirmation", passwordConfirmation);
    urlencoded.append("login", login);


    let data = await postMap(urlencoded, actionUrl);
    return data;
}
