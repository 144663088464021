export const HLabel = ( {label,rightContent}:{label:string, rightContent:any} ) => {

    return <div className='hlabel'>
        <div className='label-hlabel' ><span>{label}</span></div>
        <div>{rightContent}</div>
    </div>

}

export const Tag = ({tag}:{tag:string|undefined}) => {
    return <div className='tag-info'>
        {tag||''}
    </div>
}

export const Status = ({status}:{status:string|undefined}) => {
    return <div className='status-info'>
        <span></span>
        <span>{status||''}</span>
    </div>
}

export const TooltipMessagesList = ({list,limit}:{list:any[],limit:number}) => {
    return (list && list.length) ? <div>
        {
            list.slice(0,limit).map(
                item => <div>{item.message}<br/></div>
            )
        }
        { 
            list.length > list.slice(0,limit).length && <div style={{width:'100%', textAlign:'center'}}>...</div>
        }
    </div>: <></>
} 