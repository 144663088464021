import t from 'kls-i18n';
import { Dropdown, Modal } from 'kls-ui';
import {Button} from 'kls-ui/Button/Button' ;
import {IconButton} from 'kls-ui/Button/IconButton' ;
import { AdvancedDropDown } from 'kls-ui';
import { useEffect, useState } from 'react';
import VersionWizar from '../publication/change-version';
import PublicationWizar from '../publication/publication';
import { currentUser } from 'kls-commons/service/userService';
import { Link } from 'react-router-dom';


export function ParameterModule( {module}:{module:string} ) {

    const publicationList = [
        { label : "Publier en production", value : 'prod' },
        { label : "Publier en pré-production", value : 'preprod'},
        { label : "Revenir à une version antérieure", value : 'change-version' }
    ] ;

    const [showDeliverList, setShowDeliverList] = useState<boolean>( false ) ;
    const [wizardToShow, setWizardToShow] = useState<string>( '' ) ;

    const [showDestUrl, setShowDestUrl] = useState<boolean>() ;
    const [destUrl, setDestUrl] = useState<string>() ; 

    useEffect(
        () => {
            if( showDestUrl ) {
                let userEntity:any = currentUser()?.entity||'' ;
                let domain ; 
                if( userEntity ) {
                    domain = userEntity.replaceAll( '/kls/', '' ).replaceAll( '/dev', '' ) ;
                }
                let url = 'https://' + domain + '.karaz.org/karazal/' ;
                setDestUrl( url ) ;
            }
        } , [showDestUrl]
    )

    
    return <div style={{display: "flex", gap: "8px"}}>

        { wizardToShow === 'change-version' && <VersionWizar moduleName={module} onClose={()=>{setWizardToShow('')}} />}
        { ['prod','preprod'].includes(wizardToShow) && <PublicationWizar moduleName={module} toProd={wizardToShow==='prod'} onClose={()=>{setWizardToShow('')}} setShowDestUrl={setShowDestUrl} />}
        
        {
            showDestUrl &&      
            <Modal  title={ "Publier l'application" } icon='' width="600px" zIndex={100001} onClose={()=>{ setShowDestUrl(false) ; }} >
                <div className='pub-url-container-modal'>
                    <div className='label-hlabel font-500' >Cliquer sur le lien ci-dessous pour accéder à votre espace de publication</div>
                        
                    <a className='url-pub-container' href={destUrl} target="_blank" >
                        <span className='url-pub'>{destUrl}</span>
                    </a>
                </div>
            </Modal>
        }   

        <IconButton
            color="neutral"
            icon="more_vert"
            size="md"
            styling="solid" 
            args={{onClick: () => {console.log( "more_vert" )}}}
        />

        <div className='list-actions-deliver' style={ {position:'relative'} }>
            <Button args={
                        { 
                            onClick: () => {  setShowDeliverList( true )  }, 
                            style: { padding: '12px' } 
                        }
                    } 
                    color="primary" 
                    label={t("Publier")} styling="solid" 
                    size={'xs'}
                    rightIcon='expand_more' />
            
            {
                showDeliverList && 
                <AdvancedDropDown   data={publicationList} enableSearch={false} light={true}
                                    handleChange={ (action:any) => setWizardToShow( action?.value ) } 
                                    size={2} 
                                    selectedValue={ "" }
                                    isLunchAction={true}
                                    style={{top:'52px', right:'0px'}} 
                                    closeWhenSelect={() => {  setShowDeliverList( false )  }}
                                    closeWhenClickOutside={() => {  setShowDeliverList( false )  }}/>
            }
        </div>

    </div>
}
