import { SettingsContainerHeader } from "../settings" 
import { useState } from 'react';
import './account-deletion.css';
import { Button, ErrorMessage, Input ,addToast} from 'kls-ui'


export function AccountDeletion(props: any) {
  const [errorMessage, setErrorMessage] = useState(false);
  let [showPassword, setshowPassword] = useState(false);
  let [password, setpassword] = useState('');
  let [passwordErr, setpasswordErr] = useState('');
  let [password2, setpassword2] = useState('');
  let [password2Err, setpassword2Err] = useState('');
  let [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  let [userName, setuserName] = useState('');
  let [settingDisplay, setSettingDisplay] = useState('flex');
  let [isSuccess,setisSuccess] = useState(true)
  
  const successToaster = () => {
    addToast("Compte supprimé avec succès", 'success', "check")
    setisSuccess(true)
    setSettingDisplay('none')
  }


  return (
    <div className="setting-deletion-container" >
      <SettingsContainerHeader title="Suppression de compte" subtitle="Supprimer votre compte" />
      {errorMessage && 
    <div className='settings-del-err-mes-btn'><ErrorMessage img='icons/error_outline.svg' label="Le nom d'utilisateur ou le mot de passe saisi est incorrect !" /></div>}
    <div className='settings-deletion-authContainer' style={{display:settingDisplay}}>
      <div className='settings-deletion-auth'>
        <div className='settings-deletion-usernameContent org-deletion-auth'>
          <Input
            // errorMessage={userNameErr}
            size="md"
            args={{
              placeholder: `Entrez votre nom d’utilisateur`, required: true, id: "userName", onChange: (e) => {
                setuserName(e.target.value);
                // setuserNameErr('');
                // if (e.target.value == '') {
                //   setuserNameErr("Nom d'utilisateur est nécessaire")
                // } else {
                //   let strRegex = new RegExp(/^[a-zA-Z0-9.]*$/);
                //   let result = strRegex.test(e.target.value);
                //   if (!result) {
                //     setuserNameErr("Renseignez nom d'utilisateur (sans espaces ni caractères spéciaux)")
                //   }
                // }
              }
            }}
            label="Nom d’utilisateur"
          ></Input>
        </div>
        <div className='settings-deletion-passwordContent settings-Password-deletion-auth'>
          <Input
            errorMessage={passwordErr}
            args={{
              id: "password",
              type: showPassword ? "text" : "password", required: true, placeholder: 'Entrez votre mot de passe', onChange: (e) => {
                // setpasswordStrength(passwordStrengthValidator(e.target.value));
                setpassword(e.target.value);
                // setpasswordErr('');
                // if (e.target.value == '') {
                //   setpasswordErr("Mot de passe requis")
                // }
              }
            }}
            infoText=""
            label="Mot de passe"
            size="md"
          />
          {passwordErr == '' && <img className="eyeIcon settings-deletion-eyeIcon" src={showPassword ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPassword(!showPassword)}></img>}
        </div>
        <div className='settings-deletion-confirmPasswordContent settings-confirmPass-deletion-auth'>
          <Input
            errorMessage={password2Err}
            args={{
              type: showPasswordConfirm ? "text" : "password", required: true, placeholder: 'Confirmez votre mot de passe', onChange: (e) => {
                setpassword2(e.target.value);
                setpassword2Err('');
                // if (e.target.value == '') {
                //   setpassword2Err("Confirmer mot de passe requis")
                // }
              }
            }}
            infoText=""
            label="Confirmer mot de passe"
            size="md"
          />
          {password2Err == '' && <img className="eyeIcon settings-deletion-eyeIcon" src={showPasswordConfirm ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}></img>}
        </div>
      </div>
      <div className='settings-deletion-btn'>
        <Button label='Supprimer mon compte' leftIcon='delete' size='md' color='error' styling='outline' args={{disabled: userName.length != 0 && password === password2 && password.length != 0 && password2.length != 0 ? false : true, id: `${userName.length != 0 && password === password2 && password.length != 0 && password2.length != 0 ? `settings-delete-undisable` : ``}`, onClick:successToaster}} />
      </div>
    </div>
    </div>)
}
