
import { MyApps } from 'kls-catalog'
import { NewModelWizard } from '../module/new-model-wizard'

export default function MyAppsPage(props) {
    return (
        <div style={{ margin: '1.5rem auto', maxWidth: '1250px', width: '100%' }} >
            <MyApps gs={props.gs} creationWizard={creationWizard()} />
        </div>
    )
}

function creationWizard() {
    return (onOk: Function, onClose: Function) => <NewModelWizard onClose={onClose} />
}