import React, { useState } from 'react';
import {ValidationMessage} from 'kls-validation/types/validation.type';
import './validation-panel.css';
import t from 'kls-i18n';
import { useBusEventListener } from 'kls-commons/state/kls-bus';
import { GLOBAL_VALIDATION_ENGINE_STATE, GLOBAL_VALIDATION_RESULT, RESOURCE_VALIDATION_DONE, validationController } from './validation-controller';

import ReactMarkdown from 'react-markdown'

 

interface ValidationData { 
   activeRubrique: string,
   activeModule: string,
   onMoveTo: Function
}

interface TabProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

const CURRENT_RESOURCE = "currentResource";
const CURRENT_APP = "currentApp";
const tabs = [{label: t("Current Resource"), name: CURRENT_RESOURCE}, 
{label: t("Module Report"), name: CURRENT_APP}]

function ValidationPanel ( {activeRubrique, activeModule, onMoveTo}: ValidationData )   {
    const [activeTab, setActiveTab] = useState('currentFile');
    const [messages, setMessages] = useState<ValidationMessage[]>([]);
    const [currentMessage, setCurrentMessage] = useState<ValidationMessage|null|undefined>();
    const onTabChange = (tabName: string) => {
      setActiveTab(tabName);
    };
    const onSelectMessage = (message:ValidationMessage|null|undefined, moveTo: boolean = false) => {
        setCurrentMessage(message);
        if(moveTo && message && message.affectedResource && onMoveTo) {
            onMoveTo(message.affectedResource)
        }
    } 

    const fetchAllErrors=()=> {
        setMessages(GLOBAL_VALIDATION_RESULT[activeRubrique]);
    }
    useBusEventListener(RESOURCE_VALIDATION_DONE, (rubric=> {
        console.log("KEE RESOURCE_VALIDATION_DONE ", rubric); 
        if(rubric===activeRubrique) {
            setActiveTab(CURRENT_RESOURCE);
        }
        fetchAllErrors();
    }))
   
    const onAction=(action: string)=> {
        if(action=== 'clear') {
            GLOBAL_VALIDATION_RESULT[activeRubrique] = [];
           fetchAllErrors();
        }
        if(action=== 'play') {
            validationController.verifiyResource(null, activeRubrique);
           fetchAllErrors();
        }
    }
    return (
      <div className="validation-panel">
        <div className="tab-container">
            {tabs.map((t,i)=> <Tab key={t.name}  name={t.label}  isActive={activeTab === t.name}  onClick={() => onTabChange(t.name)} /> )}
         
        </div>
        <div className="content-container">
          <div className="master-detail">

          <ActionButtons onAction={onAction} />
            <div className="master">
                {activeTab === CURRENT_RESOURCE && <ErrorList errors={messages} onSelectMessage={onSelectMessage} /> }
                {activeTab === CURRENT_APP && <ProjectErrorList errorsMap={GLOBAL_VALIDATION_RESULT} onSelectMessage={onSelectMessage} /> }
            </div>
            <div className="detail">
             <MessageDetail message={currentMessage} /> 
            </div>
          </div>
        </div>
      </div>
    );
  };

const Tab: React.FC<TabProps> = ({ name, isActive, onClick }) => {
  return (
    <div className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
      {name}
    </div>
  );
};

interface ErrorListProps {
  errors: ValidationMessage[];
  onSelectMessage: Function;
  rubric?: string;
}
interface ProjectErrorListProps {
    errorsMap: {[key:string]:ValidationMessage[]};
    onSelectMessage: Function;
  }
const ErrorList: React.FC<ErrorListProps> = ({ errors, onSelectMessage }) => {
  return (
    <ul className="vmm-error-list">
      {errors?.map((error, index) => (
        <li key={index} className={"vmm-error-item vmm-type-"+error.type+" vmm-type-criticity-"+error.criticity  } onClick={()=>onSelectMessage(error)} >
          <div className="vmm-error-title">
            <i className={asTokenLabel(error.type)}></i> 
            <i className={asTokenLabel(error.criticity)}></i> 
            <span>{error.errorTitle}</span></div>
          <div className="error-path vmm-error-link">
              <span onClick={()=>onSelectMessage(error, true)}>{error.affectedResource?.resourceLPath.split("/").filter(s=>!!s).map((s,i)=> <span key={i} 
              className="lpath-tkn" ><i className='fa fa-arrow-right'></i> <span>{s}</span></span>)}</span>
            </div>
         
        </li>
      ))}
    </ul>
  );
};
const ProjectErrorList: React.FC<ProjectErrorListProps> = ({ errorsMap, onSelectMessage }) => {
    console.log("ProjectErrorList errorsMap:", errorsMap);
    return (
      <div className="vmm-error-list">
        {Object.keys(errorsMap).filter(k=>!!errorsMap[k] && !!errorsMap[k].length).map((rubrique, index) => {
            let errors = errorsMap[rubrique];   
            return  <FileErrorList key={rubrique+"-"+index}  rubric={rubrique} errors={errors} onSelectMessage={onSelectMessage}  /> 
            })}
      </div>
    );
  };


const FileErrorList: React.FC<ErrorListProps> = ({ errors, rubric, onSelectMessage }) => {
    let [expanded, setExpanded]= useState(false);
    let title = rubric?.split('/').filter((s,i)=>i>1).join("/");
    return (
      <div className='vm-file-list'>
          <div className='vm-rubric-name' onClick={()=>setExpanded(!expanded)}>
              <i className={'fa '+ (expanded? 'fa-chevron-down': 'fa-chevron-right')}></i>
              <span className='vm-rubric-title' >{title} </span> 
              <span className='vm-rubric-cnt' > ({errors.length})</span>
              </div>
          {expanded &&  <ErrorList errors={errors} onSelectMessage={onSelectMessage} />}
      </div>
    );
  };




const MessageDetail  =  ({message}: {message?: ValidationMessage | null }) => {
    if(!message) {
        return <div className='vmd-no-message'></div>
    }
    return  <div>
        <div className='vmd-title'>{message.errorTitle}</div>
        <div className='vmd-hb'>
            <div className='vmd-hb-block'> <i className={asTokenLabel(message.type)}></i><span>{message.type}</span> </div>
            <div className='vmd-hb-block'> <i className={asTokenLabel(message.criticity)}></i><span>{message.criticity}</span> </div>
            <div className='vmd-hb-block'> <i className={asTokenLabel('code')}></i><span>{message.code}</span> </div>
        </div> 

        {message.errorMessage && <div className='vmd-message'> 
            <ReactMarkdown >{message.errorMessage}</ReactMarkdown>
        </div>}
        {message.ruleDescription && <div className='vmd-description'> 
            <ReactMarkdown >{message.ruleDescription}</ReactMarkdown>
        </div>}
       {message.nonCompliant && <div className='vmd-compliant'>
            <div className='vmd-title' >{t("Noncompliant Example")}</div>
            <ReactMarkdown >{message.nonCompliant}</ReactMarkdown>
        </div>}
        {message.compliant && <div className='vmd-compliant'>
            <div className='vmd-title' >{t("Compliant Solution")}</div>
            <ReactMarkdown >{message.compliant}</ReactMarkdown>
        </div>}

 
    </div>
  };



const ActionButtons: React.FC<{onAction:Function}> = ({onAction}) => {
    let [engineState, setEgineState] = useState(GLOBAL_VALIDATION_ENGINE_STATE.state); 
    let activePlay = engineState=== 'paused';
    let activeStop = engineState=== 'running';
  return (
    <div className="action-buttons">
      <div className={'vp-btn vp-btn-run '+(activePlay? 'active': '')} onClick={()=>onAction("play")} ><i className='fa fa-play'></i></div>
      <div className={'vp-btn vp-btn-stop '+(activeStop? 'active': '')} onClick={()=>onAction("stop")} ><i className='fa fa-stop'></i></div>
      <div className={'vp-btn vp-btn-clear active' } onClick={()=>onAction("clear")} ><i className='fa fa-trash'></i></div>
    </div>
  );
};



export default ValidationPanel;
function asTokenLabel(type: string): string | undefined {
    // 'error'  'warning'   'confirmation' 'blocking'   'critical'   'smell';;
   switch(type) {
       case 'error': return 'fa fa-times-circle'; 
       case 'warning': return 'fa fa-exclamation-triangle'; 
       case 'confirmation': return 'fa fa-check-circle'; 
       case 'blocking': return 'fa fa-ban'; 
       case 'critical': return 'fa fa-fire'; 
       case 'smell': return 'fa fa-bug'; 
   }
}

