import { useEffect, useState } from "react";
import "../app-params/param-app.css"
import { MenuEditor } from "kls-generic-editor/menu-editor";
import { menuEditorSchemaRoot } from "kls-generic-editor/menu-editor-schema";
import { CreationModal, Input, Modal, ToggleButton, addToast } from "kls-ui";
import { GenericTable } from 'kls-ui/table/table';
import { onStartEdit } from "../module/module.service";
import { ResourceContent } from "kls-commons/types/repo-model";
import { getTenant } from "kls-commons/service/resource-service";
import { activOrDesactivMenu } from "./param-app";
import { OtpForm } from "kls-ui/data entry/otp";
import { deleteKlsResource } from "kls-commons/service/repo-service";

const emptyResourceContent: ResourceContent = {
    content: ''
  }
 
export function MenusComponent(props: any) {


    const [showModal, setShowModal] = useState(false);
    const [dataElm, setDataElm] = useState<{ menu: string; status: string; actions: JSX.Element }[]>([]);
    const [selectedMenu,setSelectedMenu]=useState('');
    const [showMenu,setShowMenu]=useState(false);
    const [contentMenu, setContentMenu] = useState(emptyResourceContent);
    const [isActif,setIsActif]=useState(false);
    const [showSuppModal ,setShowSuppModal]=useState(false);
    const [otpValue, setOtpValue] = useState<string>() ;
    const [suppMenu,setSuppMenu]=useState('');
    const otp = "986357" ;

    const handleEdit=(menu)=> {
             console.log("lmenu",menu);
             setSelectedMenu(menu);
             setShowMenu(true);
             setIsActif(props.activedMenus.includes(menu));
    }

    const handleDelete =(menu)=> {
        console.log(menu)
        setSuppMenu(menu);
    setShowSuppModal(true);
    }

    const handleSearch = (val) => {
        if (props.allMenus) {
            const filteredData = props.allMenus
                .map((s) => ({ menu: s, 
                               status: props.activedMenus.includes(s) ? 'active' : 'active', 
                               actions:<MoreOptions 
                                        menu={s}
                                        onEdit={(menu) => handleEdit(menu)} 
                                        onDelete={(menu) => handleDelete(menu)}/>

                            }))
                .filter((item) => item.menu.toLowerCase().includes(val.toLowerCase()));

            setDataElm(filteredData);
        }
    }

    const handleActifMenu =()=>{
        let rs='klsapp/' + getTenant() + '/' + props.moduleName + '/' + props.modelName + '/kconfig.xml';
        setIsActif(!isActif);
        activOrDesactivMenu(props.kconfigContent,selectedMenu,rs,props.moduleName);
    }

    const deleteMenu =()=>{
        let rs='klsapp/' + getTenant() + '/' + props.moduleName + '/' + props.modelName + '/'+suppMenu;
            console.log('rs',dataElm);
            setDataElm((prevData) => prevData.filter((menu) => menu.menu !== suppMenu));
            console.log("menu delete",props.moduleName,rs);
            deleteKlsResource(props.moduleName,rs);

            setShowSuppModal(!showSuppModal);
            activOrDesactivMenu(props.kconfigContent,suppMenu,rs,props.moduleName);
            props.setKconfigContent(props.kconfigContent);
      
        
    }

    useEffect(() => {

        const data = props.allMenus?.map((s) => {

            return {
                menu: s,
                status: props.activedMenus.includes(s) ? 'active' : 'inactive',
                actions: <MoreOptions
                            menu={s}
                            onEdit={(menu) => handleEdit(menu)} 
                            onDelete={(menu) => handleDelete(menu)}
                    />
                }
        })
        setDataElm(data);
        
    }, [props.allMenus ,props.activedMenus])

    useEffect(()=>{console.log("dataElm",dataElm)},[dataElm])
    const columns = [
        { key: 'menu', header: 'Menu' },
        { key: 'status', header: 'Statut' },
        { key: 'actions', header: '' }
    ];
    const handleChangeResource = async () => {
        let rs='klsapp/' + getTenant() + '/' + props.moduleName + '/' + props.modelName + '/'+selectedMenu;
        console.log(" resss currentModule", rs);
        console.log("resss",props.moduleName);

        let updatedRc:any = await onStartEdit( props.moduleName, rs ) ; 
        const existingMenu = dataElm.some(elm => elm.menu === selectedMenu);

if (!existingMenu || !dataElm) {
    props.allMenus.length++;
        const newMenu = {
            menu: selectedMenu,
            status: 'active',
            actions: (
                <MoreOptions
                    menu={selectedMenu}
                    onEdit={(menu) => handleEdit(menu)}
                    onDelete={(menu) => handleDelete(menu)}
                />
            ),
        };

        setDataElm((prevData) => [...prevData, newMenu]);
    }
        setContentMenu(updatedRc);
        setShowMenu(true);
    }
    useEffect(()=>{
        if(showMenu===false){
        console.log("hello");
        props.setKconfigContent(props.kconfigContent);
    }},[showMenu])

useEffect(()=>{
    console.log("contentMenu selectedMenu",selectedMenu)
    console.log("contentMenu props.moduleName",props.moduleName)

    handleChangeResource();

},[selectedMenu])

    return (<>{!showMenu && 
    <>
    <div className="menu-creation-header">
        <div className="text-md">Menus</div>
        <div className="text-md font-500 blue-600"
            onClick={() => { setShowModal(true) }}
            style={{ cursor: "pointer",display:"flex",alignItems:"center" }}><span className="material-icon">add</span>Nouveau menu</div>
    </div>
        <span style={{ margin: "20px" }} className="text-rg slate-500 font-400">Gérez vos accès à l’aide des menus</span>
        <div className="trait-grey" style={{margin:"20px 20px 10px"}}></div>

        {!props.allMenus || props.allMenus.length === 0 ? <>

            <div className="menu-content"> <span className="material-icon menu-icon">menu</span>
                <span className="text-xl font-500">Aucun menu pour l'instant</span>
                <span className="text-lg slate-500">La liste des menus créés sera affichée ici</span>
            </div></>
            : <div className="menu-tables">
                <Input label="" size="xs" specialIcon='search' args={{ onChange: (evt) => { handleSearch(evt.target.value) }, placeholder: "Rechercher..." }} />

                {dataElm && <GenericTable data={dataElm} columns={columns} />}
            </div>
        }

        {
            showSuppModal && <>
             <Modal  title={ "Suppression du menu" }
                icon=''
                width="600px"
                zIndex={100001}
                onClose={()=>{setShowSuppModal(!showSuppModal)}}
                leftBtnArgs={{ size: "md", 
                            color: "neutral", 
                            label: "Annuler", 
                            styling: "outline", 
                            args: { onClick: () => { setShowSuppModal(!setShowSuppModal); } } }} 
                rightBtnArgs={{
                    size: "md", color: "error", label:"Supprimer le menu", styling: "solid",
                    args: {
                        onClick: () => {
                            if( otpValue !== otp ) {
                                addToast('Le code saisi est incorrect!', 'warning');
                                return ;
                            }
                            else{
                                deleteMenu();
                            }
                        }
                    }
                }}>
                <div style={{ display: "flex",padding: "24px 16px", flexDirection: "column",gap: "16px"}}>
                    <OtpForm code={otp} handleChange={setOtpValue} />
                </div>
        </Modal>
            </>
        }
        {showModal && <>
        <div className="creation-menu">
            <CreationModal
                currentModel={""}
                currentModule={props.moduleName}
                resourceType={"menu"}
                onClose={() => { setShowModal(false) }}
                handleChange={(res) => {setSelectedMenu(res); }} />
        </div>
        </>
    
        }
    </>
    }
    {
        showMenu && contentMenu && <>  
        <div className="menu-creation-header">
        <div className="text-md" style={{display:"flex",alignItems:"center",gap:"2px"}}><span className="material-icon" style={{cursor:"pointer"}} onClick={()=>setShowMenu(!showMenu)}>chevron_left</span>Menus</div>
      <div className="actif-menu">
        <span className="text-sm font-500">Activer</span>
        <ToggleButton ischecked={isActif} handleToggle={handleActifMenu} />
      </div>
    
    </div>

    
        <span style={{ margin: "20px" }} className="text-rg slate-500 font-400">Détails de menu : {selectedMenu?.replace('-menu.xml', '')}</span>
        <div className="trait-grey" style={{margin:"20px 20px 10px"}}></div>

        <MenuEditor resourceContent={contentMenu}
                    schema={menuEditorSchemaRoot}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    moduleName={props.moduleName} ></MenuEditor></>
    } 
    
    


    </>);
}


const MoreOptions = ({ menu, onEdit, onDelete }) => {
    const [showOpt, setShowOpt] = useState(false);



    useEffect(()=>{
        if(showOpt){
    document.addEventListener("click",()=>{setShowOpt(false)},{once:true});
        }
    },[showOpt])
    return (

        <>
            <span className="material-icon table-more-vert" onClick={(e) => {setShowOpt(!showOpt)}}>
                more_vert
            </span>
            {
                showOpt && <div className="more-options">
                           <div onClick={() => onEdit(menu)} className="options-details" >
                            <span className="material-icon">edit</span>Editer</div>
                            <div onClick={() => onDelete(menu)} className="options-details" >
                                <span className="material-icon">delete</span>Supprimer</div></div>
            }
        </>
    );

}


