import { postMap } from "kls-commons/service/karaz-remote-action";

export async function DomainUpdateService(userID:any,organisationID:any,organisationName:any) {
    let actionUrl = '/karazal/ow-kls-extrat/appmanager/updateOrganismeDomainName';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("userId", userID);
    urlencoded.append("DomainName", organisationName);

    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}