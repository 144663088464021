import { ResourceContent } from "kls-commons/types/repo-model";
import { ValidationResult } from "./validation-result";
import {engine as KLS_VIEW_VALIDATION_ENGINE} from "kls-validation/engines/kls-view" ;
import {engine as KLS_MENU_VALIDATION_ENGINE} from "kls-validation/engines/kls-menu" ;
import {engine as KLS_SEARCH_VALIDATION_ENGINE} from "kls-validation/engines/kls-search" ;
import {engine as KLS_BPMN_VALIDATION_ENGINE} from "kls-validation/engines/kls-bpmn" ;
import { strToXmlElement } from "kls-commons/help/tools";
import { KEE } from "kls-commons/state/kls-bus";

import {ValidationMessage} from 'kls-validation/types/validation.type';
import { GS } from "kls-commons/state/global-state";
import { fetchKlsResource } from "kls-commons/service/repo-service";


export const RESOURCE_VALIDATION_DONE ="RESOURCE_VALIDATION_DONE";
export const GLOBAL_VALIDATION_RESULT: {[key:string]: ValidationMessage[]} = {};
export const GLOBAL_VALIDATION_ENGINE_STATE = {state: "paused"}

class ValidationController  {
   async  verifiyResource(rc: ResourceContent | null, rubric: string ): Promise<ValidationResult  | null> {
        if(!rc) {
           rc = await fetchKlsResource(GS.currentModuleName, rubric);
        }
          let validationEngie =  this.getValidationEngine(rubric) ;
          if(!validationEngie) {
              console.log("No engine for "+ rubric)
              return  null ;
          }
          let subject: any = this.getSubject(rc, rubric); 
          let errors =  validationEngie.verify(subject, rubric);
          console.log("validation errors ", errors);
          GLOBAL_VALIDATION_RESULT[rubric]=errors;
          KEE.emit(RESOURCE_VALIDATION_DONE, rubric);
          if(!errors || errors.length) {
              return null;
          }
          
          return    { 
              error: true,
              blocking: errors.some(x=>x.criticity==='blocking')
          }; 

    }
    getSubject(rc: ResourceContent, rubric: string) {
        if(!rc.content)
            return null;
        return strToXmlElement(rc.content, null);
    }

    getValidationEngine(rubrique: string) {
        if(rubrique.endsWith("-menu.xml") ) { 
            return KLS_MENU_VALIDATION_ENGINE;
        }
        if(rubrique.endsWith("-view.xml")  || rubrique.includes("/view-")) {
            return KLS_VIEW_VALIDATION_ENGINE;
        }
    
    }
    
} 
export const  validationController = new ValidationController()


