import { Modal, Dropdown, Input, Button, addToast } from "kls-ui";
import { useEffect, useState } from 'react';
import { SettingsContainerHeader } from '../../settings/settings'
import { Slice } from "victory";
import { countries } from "../../common/countries";
import { typeOfLocal } from "../../common/local";
import { useParams } from "react-router-dom";
import { fetchLocalUserDetails } from "kls-commons/service/userService";
import { OrganizationAddressCreate,  OrganizationSettingsUpdate } from "../organization-settings-service";

function CreateAddress(props: any) {

    let [industries, setindustries] = useState<any[]>([]);
    let [location, setLocation] = useState<any[]>(typeOfLocal);
    let [locationValue, setlocationValue] = useState<any>('Siège social')
    let [addresslineErr, setaddresslineErr] = useState('');
    let [addressline, setaddressline] = useState('');
    let [addressLocalite, setaddressLocalite] = useState('');
    let [addressLocaliteErr, setaddressLocaliteErr] = useState('');
    let [addressPays, setaddressPays] = useState('');
    let [addressPaysErr, setaddressPaysErr] = useState('');
    let [localite, setlocalite] = useState<any>([])
    let [dateOfCreation, setdateOfCreation] = useState('')
    let [objectData, setobjectData] = useState<any>({})
    let { organisationID } = useParams() as any
    let [newAddedAddress, setnewAddedAddress] = useState<any>([])



    // const countryMap = Object.keys(country_state[0])
    // console.log(countryMap)

    const countryArray = Object.entries(countries).map(([country]) => ({
        'label': country,
        'value': country,
    }));
    // const allStates = Object.values(countries).flatMap(country=>country.states);

    console.log(addressPays)
    useEffect(() => {
        if (addressPays && countries[addressPays]) {
            const local = countries[addressPays].localite.map((c) => ({ label: c, value: c }));
            setlocalite(local);
        }
        else {
            setlocalite([]);
        }
    }, [addressPays])
    // // 

    const submitAddress = async () => {
        let isValid = true;

        if (addressline === '') {
            setaddresslineErr("Adresse champ est requis");
            isValid = false;
        } else {
            setaddresslineErr("");
        }

        if (addressLocalite === '') {
            setaddressLocaliteErr("Localite champ est requis");
            isValid = false;
        } else {
            setaddressLocaliteErr("");
        }

        if (addressPays === '') {
            setaddressPaysErr("Pays champ est requis");
            isValid = false;
        } else {
            setaddressPaysErr("");
        }
        if (isValid) {
            // console.log(props?.address, 'props?.address props?.address ')
            // let savedAddress = props?.address || [];

            // if (Array.isArray(savedAddress)) {
            //     let filtered = savedAddress.filter(function (el) {
            //         return el != null && el != '';
            //     });
            //     savedAddress = filtered;
            // } else if (typeof savedAddress === 'object' && !Array.isArray(savedAddress) && savedAddress !== null) {
            //     savedAddress = [];
            //     savedAddress.push(props?.address)
            // }

            // console.log(savedAddress);




            // console.log(savedAddress, 'test');

            // const formattedTime = new Date().toLocaleString('en-GB', {
            //     day: '2-digit',
            //     month: '2-digit',
            //     year: 'numeric',
            //     hour: '2-digit',
            //     minute: '2-digit',
            // });
            // setdateOfCreation(formattedTime)

            // savedAddress.push({
            //     "address": addressline,
            //     "localite": addressLocalite,
            //     "pays": addressPays,
            //     'addressType': locationValue
            // })

            // console.log(savedAddress, 'test2');

            let result = fetchLocalUserDetails();
            console.log(props.organisationData, 'props.organisationData')
            await OrganizationAddressCreate(result?.userID, organisationID,addressline,addressLocalite,addressPays,locationValue)

                .then(
                    (result) => {
                        console.log(result, 'result......')
                        if (result?.Exception) {
                            console.log(result);
                            addToast(result?.Exception, 'error');
                        } else if (result?.result == 'adresse added') {
                            addToast('Adresse créée avec succès', 'success');
                            localStorage.setItem('organizationData', JSON.stringify(result));
                            console.log('Organization-Address-Update')

                            setTimeout(() => {
                                props?.popupClose()
                            }, 1500)
                        }
                    },
                    (error => {
                        console.log("API_ERROR", error);
                    })
                );

        }

        return isValid;
    }

    // 

    useEffect(() => {
        props.setdataValue && props.setdataValue(objectData)
    }, [objectData])
    console.log('dateOfCreation....', objectData)

    return <div className="organisation-table-Modal-Addform">
        <Modal onClose={props?.popupClose} title={"Nouvelle adresse"} icon={""} >
            <div className="organisation-table-Modal-primary">
                <div className="organisation-table-Modal">
                    <div className="organisation-table-popup-tab-content">
                        <div className="Organisation-editor-TabContent1">
                            <div className="Organisation-editor-TabContent1-header">
                                <Dropdown value={locationValue}
                                    size="md"
                                    elementSize={36}
                                    elements={location}
                                    label="Type du local"
                                    listTopPos="105%"
                                    listBottomPos=""
                                    setValue={(e) => { setlocationValue(e); }}
                                    listLeftPos=""
                                    listRightPos=""
                                    errorMessage={''}
                                    args={{ value: locationValue }} />
                            </div>
                            <div className="Organisation-editor-Tabcontent1-address">
                                <Input size='md' label="Adresse" args={{
                                    placeholder: 'Entrez l’adresse de votre local', required: true,
                                    onChange: (e) => {
                                        setaddressline(e.target.value);
                                        setaddresslineErr('');
                                        if (e.target.value == '') {
                                            setaddresslineErr("Ce champ est requis")
                                        }
                                    }
                                }} errorMessage={addresslineErr} />
                            </div>
                            <div className="Organisation-editor-Tabcontent1-dropdown">
                                <div className="Organisation-editor-Tabcontent1-Paysdropdown">
                                    <Dropdown value={addressPays}
                                        size="md"
                                        elementSize={36}
                                        // elements={ [{'label': country_state[0].Australia[0], 'value': country_state[0].Australia[0]}]}
                                        elements={countryArray}
                                        setValue={(e) => { setaddressPays(e); setaddressPaysErr(''); setaddressLocalite('') }}
                                        label="Pays"
                                        listTopPos="105%"
                                        listBottomPos=""
                                        listLeftPos=""
                                        listRightPos=""
                                        searchable='suggest'
                                        errorMessage={addressPaysErr}
                                        args={{
                                            placeholder: 'Choisissez un pays', required: true,
                                            value: addressPays
                                        }}
                                    />
                                </div>
                                <div className={addressPays !== "" ? "Organisation-editor-Tabcontent1-Localitydropdown" : "Organisation-editor-Tabcontent1-Localitydropdown-empty"}>
                                    <Dropdown value={addressLocalite}
                                        size="md"
                                        elementSize={36}
                                        elements={localite ? localite : []}
                                        setValue={(e) => { setaddressLocalite(e); setaddressLocaliteErr('') }}
                                        label="Localité"
                                        listTopPos="105%"
                                        listBottomPos=""
                                        listLeftPos=""
                                        searchable='suggest'
                                        listRightPos=""
                                        errorMessage={addressLocaliteErr}
                                        args={{
                                            placeholder: 'Choisissez une localité', required: true, disabled: countries[addressPays] ? false : true,
                                            value: addressLocalite
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="organisation-table-Modal-Button">
                    <div className="organisation-table-Modal-Button-cancel">
                        <Button size="md" label="Annuler" styling="solid" color="primary" args={{ onClick: props?.popupClose, className: 'organistaion-cancel-button-address-model' }} />
                    </div>
                    <div className="organisation-table-Modal-Button-Success">
                        <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: submitAddress }} />
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}
function CreateAddressMobile(props: any) {

    let [industries, setindustries] = useState<any[]>([]);
    let [addresslineErr, setaddresslineErr] = useState('');
    let [addressline, setaddressline] = useState('');
    let [addressLocaliteErr, setaddressLocaliteErr] = useState('');
    let [addressPaysErr, setaddressPaysErr] = useState('');
    let [close, setClose] = useState('')
    let [infoDetails, setInfoDetails] = useState(false);
    let [addressPays, setaddressPays] = useState('');
    let [addressLocalite, setaddressLocalite] = useState('');
    let [location, setLocation] = useState<any[]>(typeOfLocal);
    let [localite, setlocalite] = useState<any>([])
    let [locationValue,setlocationValue]=useState('')

    let { organisationID } = useParams() as any

    const submitAddress =async () => {
        let isValid = true;

        if (addressline === '') {
            setaddresslineErr("Adresse champ est requis");
            isValid = false;
        } else {
            setaddresslineErr("");
        }

        if (addressLocalite === '') {
            setaddressLocaliteErr("Localite champ est requis");
            isValid = false;
        } else {
            setaddressLocaliteErr("");
        }

        if (addressPays === '') {
            setaddressPaysErr("Pays champ est requis");
            isValid = false;
        } else {
            setaddressPaysErr("");
        }
        if(isValid){
            let result = fetchLocalUserDetails();
            console.log(props.organisationData, 'props.organisationData')
            await OrganizationAddressCreate(result?.userID, organisationID,addressline,addressLocalite,addressPays,locationValue)

                .then(
                    (result) => {
                        console.log(result, 'result......')
                        if (result?.Exception) {
                            console.log(result);
                            addToast(result?.Exception, 'error');
                        } else if (result?.result == 'adresse added') {
                            addToast('Adresse créée avec succès', 'success');
                            localStorage.setItem('organizationData', JSON.stringify(result));
                            console.log('Organization-Address-Update')

                            setTimeout(() => {
                                props?.popupClose()
                            }, 1500)
                        }
                    },
                    (error => {
                        console.log("API_ERROR", error);
                    })
                );

        }
        return isValid;
    }

    const handleBackArrow = () => {
        setClose(props.popupClose)
    }

    useEffect(() => {
        const local = addressPays && countries[addressPays] && countries[addressPays]?.localite.map((c) => { return { "label": c, "value": c } })
        setlocalite(local)
    }, [addressPays])

    const countryArray = Object.entries(countries).map(([country]) => ({
        'label': country,
        'value': country,
    }));

    return (
        <div className='mobile-org-table-Modal-container'>
            <div className='mobile-org-table-Modal-view'>
                <span className="icon mobile-org-arrow-back-settings" onClick={handleBackArrow}>arrow_back</span>
                <SettingsContainerHeader title='Organisation' subtitle='Gérez votre organisation' />
                <div className="mobile-organisation-table-Modal-primary">
                    <div className="mobile-organisation-table-Modal">
                        <div className="mobile-organisation-table-popup-tab-content">
                            <div className="mobile-Organisation-editor-TabContent1">
                                <div className='mobile-create-detailsContainer'>
                                    <p style={{ margin: '0', fontSize: '15px', fontFamily: 'Inter', fontWeight: '600' }}>Nouvelle adresse</p>
                                </div>
                                <div className="Organisation-editor-TabContent1-header">
                                    <Dropdown value={locationValue}
                                        size="md"
                                        elementSize={36}
                                        setValue={(e) => { setlocationValue(e); }}
                                        elements={location}
                                        label="Type du local"
                                        listTopPos="105%"
                                        listBottomPos=""
                                        listLeftPos=""
                                        listRightPos=""
                                        errorMessage={''}
                                        args={{value:locationValue}}/>
                                </div>
                                <div className="Organisation-editor-Tabcontent1-address">
                                    <Input size='md' label="Adresse" args={{
                                        placeholder: 'Entrez l’adresse de votre local', required: true,
                                        onChange: (e) => {
                                            setaddressline(e.target.value);
                                            setaddresslineErr('');
                                            if (e.target.value == '') {
                                                setaddresslineErr("Ce champ est requis")
                                            }
                                        }
                                    }} errorMessage={addresslineErr} />
                                </div>
                                <div className="Organisation-editor-Tabcontent1-dropdown Mobile-org-editor-loclite-pay">
                                    <div className="Organisation-editor-Tabcontent1-Paysdropdown Mobile-org-editor-pays">
                                        <Dropdown value={addressPays}
                                            size="md"
                                            elementSize={36}
                                            // elements={ [{'label': country_state[0].Australia[0], 'value': country_state[0].Australia[0]}]}
                                            elements={countryArray}
                                            setValue={(e) => { setaddressPays(e); setaddressPaysErr(''); setaddressLocalite('') }}
                                            label="Pays"
                                            listTopPos="105%"
                                            listBottomPos=""
                                            listLeftPos=""
                                            listRightPos=""
                                            errorMessage={addressPaysErr}
                                            args={{
                                                placeholder: 'Choisissez un pays', required: true,
                                                value:addressPays
                                            }}
                                        />
                                    </div>
                                    <div className={addressPays !== "" ? "Organisation-editor-Tabcontent1-Localitydropdown Mobile-Org-editor-TabContentEdit" : "Organisation-editor-Tabcontent1-Localitydropdown-empty"}>
                                        <Dropdown value={addressLocalite}
                                            size="md"
                                            elementSize={36}
                                            elements={localite ? localite : []}
                                            setValue={(e) => { setaddressLocalite(e); setaddressLocaliteErr('') }}
                                            label="Localité"
                                            listTopPos="105%"
                                            listBottomPos=""
                                            listLeftPos=""
                                            listRightPos=""
                                            errorMessage={addressLocaliteErr}
                                            args={{
                                                placeholder: 'Choisissez une industrie', required: true, value: addressLocalite, defaultValue: addressLocalite, disabled: countries[addressPays] ? false : true
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="organisation-table-Modal-Button mobile-organisation-table-Modal-Button">
                        <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: submitAddress }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CreateAddress, CreateAddressMobile };