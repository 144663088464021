import { useEffect, useRef, useState } from "react";
import "../app-params/param-app.css";
import {
  AdvancedDropDown,
  Button,
  ColorInput,
  IconCssList,
  PilTabs,
  TagInput,
  TextArea,
  addToast,
} from "kls-ui";
import { Model } from "kls-commons/types/application-model";
import { Input, Modal } from "kls-ui";
import { Base64 } from "js-base64";
import {
  NewModel,
  appConfiguration,
  saveappConfiguration,
} from "../module/module.service";
interface AppComponentProps {
    sendDataToParent: (data: string) => void; 
    moduleName:string
  }
  
export function AppComponent(props: AppComponentProps) {
    const {sendDataToParent,moduleName}=props
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [open, setopen] = useState(false);
  const [appdescriptin, setAppDescription] = useState("");
  const [appNom, SetAppNom] = useState("");
  const [appindustrie, setIndustrie] = useState("");
  const [selectedColor, setselectedColor] = useState("");
  let [val, setVal] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [base64Data, setbase64Data] = useState("");
  let [logoFormat, setlogoFormat] = useState("");
  const [largeImage,setlargeImage]=useState(false)
  const [applicationOwner,SetApplicationOwner]=useState('')
  //Errors
  const [AppNameError,setAppNameError]=useState('')
  let organizationFile = useRef<HTMLInputElement>(null);
  const colorPickerInputRef = useRef<HTMLInputElement>(null);
  const tabs = [
    { name: "Publique", label: "Publique", icon: "public" },
    { name: "Privée", label: "Privée", icon: "public_off" },
  ];
  let [newModel, setNewModel] = useState<NewModel>({
    module: {
      name: props.moduleName,
      title: "",
    },
    model: {
      name: "",
      title: "",
    },
  } as any);

  const handleTagsChange = (newTags: string[]) => {
    setTags(newTags);
  };

  let onChange = async (value: string, path: string) => {
    setErrorMessage("");
  };
  let onChangee = (d) => {
    val = d;
    setVal(val);
  };
  useEffect(() => {
    let jsondata = appConfiguration(props.moduleName);
    jsondata.then((result) => {
      setAppDescription(result.description);
      setIndustrie(result.industrie);
      setbase64Data(result.image)
      SetAppNom(result.title);
      sendData(result.title)
      setselectedColor(result.color)
      console.log('result.color',result.color);
      
      setSelectedItem(result.accountState)
      setVal(result.icon)
      SetApplicationOwner(result.owner?.fullName);
            if (result.tags !== undefined) {
        setTags(result.tags);
      }

      console.log("jsondatadebugging", result, result.tags);
    });
  }, []);
  const HandleAppconfig = () => {
    let AppconfigurationData = {
      name: "",
      title: appNom,
      description: appdescriptin,
      industrie: appindustrie,
      tags: tags,
      color:selectedColor,
      image:base64Data,
      accountState:selectedItem,
      icon:val,
      owner:applicationOwner
    };

    if(!appNom){
        addToast('error', 'error');
        setAppNameError('entrer app name')
        return;
    }
    sendData(appNom)
    saveappConfiguration(AppconfigurationData, props.moduleName);
    addToast('success', 'success');
  };
  console.log("modulee", props.moduleName);

  const triggerOrganizationFileInput = () => {
    if (organizationFile.current != null) {
      organizationFile.current.click();
    }
  };
  function isImageTooLarge(file: File): boolean {
    const maxSizeBytes = 2 * 1024 * 1024; // 2 megabytes in bytes
    return file?.size > maxSizeBytes;
}
  const organizationFileData = (e) => {
    let file = e.target.files[0];
   let imageSize= isImageTooLarge(file)
   if(imageSize){
    setlargeImage(true)
    return
   }
   setlargeImage(false)
    if (file) {
      let filetype = "";
      switch (file?.type) {
        case "image/jpeg":
          filetype = "jpeg";
          break;
        case "image/jpg":
          filetype = ".jpeg";
          break;
        case "image/png":
          filetype = ".png";
          break;
        case "image/svg":
          filetype = ".svg";
          break;
        default:
          filetype = "other";
          break;
      }
      if (filetype != "" && filetype != "other") {
        setlogoFormat(filetype);
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(file);
        reader.readAsBinaryString(file);
      } else {
        setbase64Data("");
        setlogoFormat("");
        addToast("Type de fichier invalide pour le logo", "error");
      }
    }
  };
  const _handleReaderLoaded = (e) => {
    let binaryString = e.target.result;
    setbase64Data(btoa(binaryString));
  };

  const handleContainerClick = () => {
    if (colorPickerInputRef.current) {
      colorPickerInputRef.current.click(); 
    }
  };
  const handleColorChange = (color) => {
    setselectedColor(color);
   
  };
  const sendData = (data) => {
 
    
    sendDataToParent(data);
  };
  console.log('ownertest',applicationOwner)
  return (
    <div className="app-configuration-container">
      <div style={{ borderBottom: "1px solid #E2E8F0", paddingBottom: "10px" }}>
        <div>
          <span className="appcomponent-label">Application</span>
        </div>
        <div>
          <span className="text-rg slate-500 font-400">
            Gérez votre application
          </span>
        </div>
      </div>
      {largeImage&&<div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'30px',backgroundColor:'#FEF2F2',border:'1px solid #FCA5A5',borderRadius:'5px'}}>
        <div>
            <div style={{display:'flex',gap:'5px',justifyContent:'center',alignItems:'center'}}>
            <span className="material-icon" style={{color:'#DC2626'}}>error_outline</span>
            <span className="text-md font-500">Votre image dépasse la taille de fichier maximale de 2 Mo autorisée</span>
            </div>
       
        </div>
      </div>
      }
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div className="logo-section-appconfig">
          {base64Data != null && base64Data != "" ? (
             <div className="OrganizationLogo">
             <div className="editorganizationLogo" onClick={() => triggerOrganizationFileInput()} >
               <img className="edit-icon" src="/icons/edit.svg" />
               <img className="curson-icon" src="/icons/Pointer.svg" />
             </div>
             <img className="OrganizationLogoImg" src={`data:image;base64,${base64Data}`} />
           </div>
          ) : (
            <div className="OrganizationLogo">
            <div className="editorganizationLogo" onClick={() => triggerOrganizationFileInput()} >
              <img className="edit-icon" src="/icons/edit.svg" />
              <img className="curson-icon" src="/icons/Pointer.svg" />
            </div>
            <img className="login-logo-organization" src="photos/ApplicationLogo.svg" alt="logo" onClick={() => triggerOrganizationFileInput()} />
          </div>
     
  
          )}

          <input
            ref={organizationFile}
            style={{ display: "none" }}
            type="file"
            name="organizationLogo"
            accept=".jpg, .jpeg, .png, .svg"
            onChange={(e) => organizationFileData(e)}
          />

          <div>
            <span className="text-rg slate-500 font-400">
              Cliquez sur l’icône pour attacher le logo de votre application
            </span>
          </div>
        </div>

        <div className="piltab-appComponent">
          <div className="screeen-size-selector">
            <PilTabs
              tabs={tabs}
              screenSize={selectedItem}
              onSelectScreenSize={setSelectedItem}
              needIcon={"false"}
            />
          </div>
        </div>
      </div>
      <div className="application-title-Proprietaire" style={{ flex: "2" }}>
        <div className="name-directory-config">
          <Input
            label={"Nom"}
            size="md"
            errorMessage={AppNameError}
            args={{
              placeholder: "Entrez le nom de votre application",
              value: appNom,
              onClick:()=>{setAppNameError('')
              },
              onChange: (evt) => {
                SetAppNom(evt.target.value);
                

              },
            }}
          />
          <div className="id-directory">
            <span>ID :</span>
            <span>{props.moduleName}</span>
          </div>
        </div>
        <div className="name-directory-config">
          <Input
            label={!props.moduleName ? "Propriétaire" : "Propriétaire"}
            size="md"
            errorMessage={errorMessage}
            args={{
              placeholder: 'Propriétaire',
              value: applicationOwner,
              disabled: true,
            }}
            containerStyle={{color:'#1E293B'}}
          />
        </div>
      </div>

      <div>
        <TextArea
          size="md"
          label="Description"
          args={{
            placeholder: "Décrivez brièvement votre application",
            onChange: (evt) => setAppDescription(evt.target.value),
            value: appdescriptin,
          }}
        />
      </div>
      <div style={{ backgroundColor: "#E2E8F0", height: "1px" }}></div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <div style={{ flex: "1" }}>
          <Input
            label={"Industrie"}
            size="md"
            args={{
              placeholder: "Entrez l'industrie de votre application",
              value: appindustrie,
              onChange: (evt) => setIndustrie(evt.target.value),
            }}
          />
        </div>
        <div style={{ flex: "1" }}>
          <div style={{ paddingBottom: "5px" }}>
            <span className="text-md font-500">Icône</span>
          </div>
          <div style={{ position: "relative" }}>
            <div
              className="dropdown-icon-list-appconfig"
              onClick={() => setopen(!open)}
            >
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  paddingLeft: "7px",
                  alignItems: "center",
                }}
              >
                <span className={val} style={{ color: "#3B82F6" }}></span>
                <span style={{ color: `${val ? '' : '#94A3B8'}` }}>{val ? val : 'Choisissez un icône'}</span>
              </div>
              <span className="material-icon" style={{paddingRight:'5px'}}>expand_more</span>
            </div>
            {open ? (
              <AdvancedDropDown
                data={IconCssList.map((icon) => ({
                  value: icon,
                  label: icon,
                  icon: (
                    <span className={icon} style={{ color: "#3B82F6" }}></span>
                  ),
                }))}
                handleChange={(iconObj) => onChangee(iconObj.value)}
                size={3}
                light={true}
                enableSearch={true}
                selectedValue={val || ""}
                style={{ position: "absolute", width: "100%" }}
                maxPageToDisplay={5}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div  style={{ flex: "1" }}>
       <ColorInput color={selectedColor} onColorChange={handleColorChange} />
        </div>
  
      </div>
      <div style={{ flex: "1" }}>
        <TagInput tags={tags} onTagsChange={handleTagsChange} />
      </div>
      <div className="Enregistrer-btn-appConfig">
        <Button
          size="md"
          color="primary"
          styling="solid"
          args={{ onClick: HandleAppconfig }}
          label="Enregistrer"
        />
      </div>
    </div>
  );
}
