import { Dropdown, ElementWithTooltip, Modal, ToggleButton, addToast } from 'kls-ui';
import React, { useEffect, useRef, useState } from 'react'
import { HLabel, Status, Tag, TooltipMessagesList } from './aux-component';
import { checkoutTag, commitModule, fetchKlsResource, getWorkingDirectorTree, listTags } from 'kls-commons/service/repo-service';
import { VersionType, getActualVersion, getLastVersion, loadAllModule, setModelJson } from '../module/module.service';
import { OtpForm } from 'kls-ui/data entry/otp';
import { Loading } from 'kls-ui';

window["listTags"] = listTags ;
window["loadAllModule"] = loadAllModule ;
window["commitModule"] = commitModule ;
window["checkoutTag"] = checkoutTag ;
window["fetchKlsResource"] = fetchKlsResource ;
window["getWorkingDirectorTree"] = getWorkingDirectorTree ;

interface Tag {
    message : string,
    version : string,
}

export default function VersionWizar( {moduleName, onClose} ) {

    const [pushToProd, setSendToProd] = useState<boolean>( false ) ;
    const [pushToPreProd, setPushToPreProd] = useState<boolean>( false ) ;
    const [tags, setTags] = useState<Tag[]>([]) ;
    let [actualVersion, setActualVersion] = useState<VersionType>( ) ;
    const [lastVersion, setLastVersion] = useState<string>( '' ) ;
    const [selectedTag, setSelectedTag] = useState<any>( ) ;
    const [step, setStep] = useState<number>(1) ;
    const [otpValue, setOtpValue] = useState<string>() ;
    const [isCommiting, setIsCommiting] = useState<boolean>( false ) ;

    const otp = "986357" ;

    useEffect(
        () => {
            listTags(moduleName, '', '', false).then(   
                r=>{ 
                    console.log( "debugingi  : " , r )
                    setTags( r?.map( r => ({...r,version:r.tag.replace("refs/tags/", "")}) ) || [] );
                }
            )
            getLastVersion(moduleName).then(  (v:string) => {  setLastVersion( v )  }  ) ;
            getActualVersion(moduleName).then(   (v:VersionType) => {  setActualVersion( v )  }   )

        } , [moduleName]
    )


    return (
        isCommiting ? <Loading message='Nous publions votre application...'/> :
        <Modal  title={ "Revenir à une version antérieure" }
                icon=''
                width="600px"
                zIndex={100001}
                onClose={onClose as () => void}
                leftBtnArgs={{ size: "md", color: "neutral", label: "Annuler", styling: "outline", args: { onClick: () => { onClose(); } } }} 
                rightBtnArgs={{
                    size: "md", color: "primary", label:"Publier", styling: "solid",
                    args: {
                        onClick: () => {
                            
                            if( !selectedTag || (!pushToProd && !pushToPreProd) ) {
                                addToast('Veuillez sélectionner au moins un espace de publication !', 'warning');
                                return;
                            }

                            if( step === 2 && otpValue !== otp ) {
                                addToast('Le code saisi est incorrect!', 'warning');
                                return ;
                            }

                            if( pushToProd && step === 1 ) {
                                setStep( 2 ) ;
                                return ; 
                            }
                            
                            setIsCommiting( true ) ;
                            checkoutTag(moduleName, selectedTag.version, selectedTag.message, pushToProd ? "prod" : "env").then(r => {
                                if (r.error) {
                                    addToast( <>La publication que vous essayez d'accomplir a échoué.<br/>La ressource demandée n'a pas été trouvée sur le serveur (Erreur 404). <br/>Veuillez vérifier vos paramètres et réessayer.<br/></>, "error" ) ;
                                } else {
                                    addToast( "La publication a été accomplie avec succès.", "success" ) ;
                                    if( !actualVersion ) actualVersion = {} as VersionType ;
                                    if( pushToProd ) {
                                        actualVersion.prod = selectedTag.version ;
                                    }
                                    actualVersion.preprod = selectedTag.version ;
                                    setModelJson( moduleName, 'version', actualVersion ) ;

                                }
                            });
                            setIsCommiting( true ) ;
                            onClose() ;

                        }
                    }
                }}>
            <div className={'publication-content step' + step} >
                {
                    step === 1 ?
                    <>
                        <HLabel label='Statut actuel' rightContent={<Status status={'Publique'} />} />
                        <HLabel label='Version actuelle en pré-production' rightContent={<Tag tag={actualVersion?.preprod||''}/>} />
                        <HLabel label='Version actuelle en production' rightContent={<Tag tag={actualVersion?.prod}/>} />
                        <div style={{background: '#E2E8F0', height: '1px', width: '100%'}}></div>
                        <ElementWithTooltip tooltipContent={ (tags&&tags.length) ? <TooltipMessagesList list={tags} limit={3}/> : null }
                                            element={
                                                <Dropdown   data={tags.map( v => ({label:v.version + ' : ' + v.message,name:v}) )}
                                                            label='Choisissez une version'
                                                            klsCompatible={true}
                                                            size="xs"
                                                            elementSize={32}
                                                            searchable={true}
                                                            valuePath='label'
                                                            value={ selectedTag?(selectedTag.version + ' : ' + selectedTag.message):'' }
                                                            listzIndex={200000}
                                                            onSelect={(selectedItem) => { setSelectedTag( selectedItem.name ) }}
                                                />   
                                            }
                        />
                        <ToggleButton size={'sm'} label='Publier en pré-production' handleToggle={() => {setPushToPreProd( !pushToPreProd )}} ischecked={pushToPreProd}></ToggleButton>
                        <ToggleButton size={'sm'} className='important-color' label='Publier en production' handleToggle={() => {setSendToProd( !pushToProd )}} ischecked={pushToProd}></ToggleButton>


                    </>
                    :
                    <OtpForm code={otp} handleChange={setOtpValue} />
                }
            </div>
        </Modal>
    )
}



