
export function CreateAppCourse(props: any) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '50px'}}>
            <div style={{position: 'relative'}}>
                <img src="/photos/Rectangle 23.svg" alt="video" style={{maxWidth: '95%'}} />
                <img src="/photos/play_circle.svg" alt="play icon" style={{background: 'black', borderRadius: '50%', position: 'absolute', top: '37%', width: '8%'}} className="create-course-playicon" />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
                <div style={{ fontSize: '16px', fontWeight: '500' }}>Description du vidéo</div>
                <div style={{color: '#64748B', textAlign: 'justify', width: '90%', fontFamily: 'Inter', fontSize: '13px'}}>
                    <div>
                        Lorem ipsum dolor sit amet consectetur. Sit neque in nisl dolor aliquam. Eget mattis scelerisque ut morbi 
                        ipsum euismod aliquam nisl. Ornare nullam senectus sem bibendum egestas lectus massa sodales orci. Odio sit 
                        bibendum aliquam elit ut pellentesque. Id pulvinar quam aliquet tristique senectus egestas nisi. Facilisis dui 
                        at cras leo id praesent tempus at leo. Amet fermentum sit turpis sem lectus. Egestas ultrices at diam et 
                        gravida ipsum ullamcorper odio congue. Enim maecenas justo orci sed tortor cursus hac.
                    </div>
                    <div>
                        In pretium aliquam mauris sed volutpat nascetur consequat amet. Ut suspendisse diam pulvinar arcu lectus 
                        blandit. Rhoncus tincidunt placerat massa nunc quis tellus aliquam. Pretium amet neque velit sed viverra 
                        scelerisque. Nec pharetra viverra at cras fermentum lectus ac diam neque. In iaculis tortor mauris vulputate 
                        ultrices integer felis. Amet eget fermentum malesuada ultrices. Nec et justo amet donec cursus odio tellus. 
                        Risus sit amet ut viverra. Et amet neque proin eget adipiscing ut nisl.
                    </div>
                    <div>
                        Urna aenean nisi faucibus et posuere ipsum ut sit cras. At ante magna amet ut orci. Imperdiet odio sit tempor 
                        justo nunc etiam. Suscipit nulla ipsum vitae non semper. Senectus habitasse neque magna at in donec leo. 
                        Cursus ornare malesuada facilisis in tristique commodo et suscipit. Nullam turpis tincidunt metus in habitasse. 
                        Quam at eget nulla.
                    </div>
                </div>
            </div>
        </div>
    );
}
