import React from 'react'
import { postMap } from 'kls-commons/service/karaz-remote-action'

export async function OrganizationSettingsService(userID: any, organisationID: any) {

    let actionUrl = '/karazal/ow-kls-extrat/appmanager/organismedetails';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organismId", organisationID);
    urlencoded.append("userId", userID);


    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}



export async function OrganizationSettingsUpdate(userID: any, organisationID: any, organizationname: any, acronyme: any, industry: any, selectedColor: any, description: any, tagvalue: any, organizationMail: any, telephoneValue: any, identifiant: any, webSite: any, selectedImage: any) {

    let actionUrl = '/karazal/ow-kls-extrat/appmanager/updateOrganisme';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("userId", userID);
    urlencoded.append("name", organizationname);
    urlencoded.append("domain", "auccccc@karaz.org");
    urlencoded.append("industrie", industry);
    urlencoded.append("email", organizationMail);
    urlencoded.append("phoneNumber", telephoneValue);
    urlencoded.append("tags", tagvalue);
    urlencoded.append("couleur", selectedColor);
    urlencoded.append("adresses", "");
    urlencoded.append("acronyme", acronyme);
    urlencoded.append("description", description);
    urlencoded.append("identifiant", identifiant);
    urlencoded.append("site", webSite);
    urlencoded.append("logoGedId", selectedImage);

    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}

// TEsting

export async function OrganizationAddressCreate(userID: any, organisationID: any, addressline:any,addressLocalite:any,addressPays:any,locationValue:any) {
    let actionUrl = '/karazal/ow-kls-extrat/appmanager/addAdresse';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("organisationId", organisationID);
    urlencoded.append("userId", userID);
    urlencoded.append("addressType", locationValue);
    urlencoded.append("address", addressline);
    urlencoded.append("pays", addressPays);
    urlencoded.append("localite", addressLocalite);

    let data = await postMap(urlencoded, actionUrl);
    return data;
}