import { useState } from "react";
import "../../app-params/param-app.css"
import React from 'react';

export function UsersCard(props: any) {
    const [usersMoreOptions, setusersMoreOptions] = useState(false)
    const [usersMoreOptionsHovered, setusersMoreOptionsHovered] = useState(false)
    const moreOptionsIcon = {
        borderRadius: '4px',
        cursor: 'pointer',
        background: usersMoreOptions ? '#E2E8F0' : (usersMoreOptionsHovered ? '#F1F5F9' : '#fff'),
        color: usersMoreOptions ? '#1E293B' : (usersMoreOptionsHovered ? '#475569' : ' #64748B'),
        fontVariationSettings: "'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 16",
        fontSize: '16px'
    }
    let [avatarType, setavatarType] = useState('image');
    let inital = '';
    const GetInitials = (string) => {
        if (string != '' && string != null) {
            var names = string.split(' '),
                initials = names[0].substring(0, 1).toUpperCase();

            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        } else if (string === '') {
            return initials = props.email.substring(0, 2).toUpperCase();
        } else {
            return '';
        }
    }
    if (avatarType == 'inital') {
        inital = GetInitials(props.avatarInital);
    }
    const RoleColor = props.role === "Proprietaire" ? '#34D399' : props.role === "Administrateur" ? '#60A5FA' : props.role === "Citizen Developer" ? '#A78BFA' : props.role === "Utilisateur" ? '#FACC15' : 'grey';
    return (
        <div className="user-card-main">
            <div className="user-card-container" onMouseLeave={() => setusersMoreOptionsHovered(false)} onMouseOver={() => setusersMoreOptionsHovered(true)}>
                {
                    ((usersMoreOptions || usersMoreOptionsHovered) &&
                        <div className="user-moreoptions-container">
                            <span className="material-icon" style={moreOptionsIcon} onClick={() => setusersMoreOptions(true)} onMouseLeave={() => setusersMoreOptionsHovered(false)} onMouseOver={() => setusersMoreOptionsHovered(true)}>more_horiz</span>

                            {
                                (usersMoreOptions) &&
                                React.cloneElement(props.actions, { closeCallback: () => { setusersMoreOptions(false) }, style: { top: '25px', right: '0', minWidth: '100px' } })
                            }
                        </div>
                    )
                }
                <div className="user-card-avatar">
                    <div className="user-card-profile" style={{ borderRight: `2.28px solid ${RoleColor}`, borderBottom: `2.28px solid ${RoleColor}`, borderTop: `2.28px solid ${RoleColor}` }}>
                        <div className="user-card-profile-outline" style={{ border: `1px solid ${RoleColor}` }}>
                            {
                                avatarType == 'image' ?
                                    <div>
                                        <img className="user-card-profile-image" src={props.image} alt="avatar" onError={(e) => setavatarType('inital')} />
                                    </div>
                                    : <div className="user-card-profile-initial" style={{ color: RoleColor }}>{inital}</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="user-card-details">
                    <span style={{ fontWeight: '700', fontSize: '16px' }}>{props.avatarInital !== '' ? props.avatarInital : '-'}</span>
                    <span style={{ color: '#26235C', fontSize: '12px' }}>{props.role}</span>
                    <span className="user-card-email" style={{ backgroundColor: RoleColor }}>{props.email}</span>
                </div>
            </div>
        </div>
    )
}