import { useEffect, useState } from "react";
import { OraganizationAddressTable, VerificationCode, Input, addToast, MobileViewVerification, MoreActions } from "kls-ui";
import { EditAddress, EditAddressMobile } from "./edit";
import { CreateAddress, CreateAddressMobile } from "./create";
import { SettingsContainerHeader } from "../../settings/settings";
export function AddressList(props: any) {
    let [verificationcodeErr, setverificationcodeErr] = useState("");
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [rowData, setRowData] = useState<any>();
    const [verification, setVerification] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [toaster, setToaster] = useState(false)
    let [viewDelete, setViewDelete] = useState<any>()
    const [dataValue, setdataValue] = useState<any>()
    const handleTableEditClick = (row) => {
        setRowData(row);
        window.scrollTo({ top: 0 })
        document.body.classList.add("mobile-edit-tab-view")
        setIsEditFormOpen(true);
    };
    const handleTableDeleteClick = (row) => {
        setRowData(row);
        setVerification(true);
        window.scrollTo({ top: 0 })
        document.body.classList.add("mobile-verify-tab-view")
        setToaster(false)
    }
    const verifiactionToaster = () => {
        addToast(" L’adresse a été supprimé avec succès", 'success', "check")
        setToaster(false)
    }
    const handleVerificationClose = () => {
        setVerification(false)
    }
    const handleSubmit = (verificaitoncode) => {
        setToaster(true)
    }

    console.log('props.address.', props.address)
    console.log('organisationData.', props.organisationData)

    let savedAddress = props?.address || [];

    if (Array.isArray(savedAddress)) {
        let filtered = savedAddress.filter(function (el) {
            return el != null && el != '';
        });
        savedAddress = filtered;
    } else if (typeof savedAddress === 'object' && !Array.isArray(savedAddress) && savedAddress !== null) {
        savedAddress = [];
        savedAddress.push(props?.address)
    }

    let data: any = savedAddress;
    console.log('dataValue',dataValue)
    console.log('data', data)

    useEffect(()=>{
        if (dataValue != undefined ){
            data.push(dataValue)
        }
    },[dataValue])
  
    if (data?.length > 0) {
        console.log(data[0], 'firstrecord');
        console.log(data[0]?.address, 'firstrecord');
    }

    // data.push({
    //     id: 1,
    //     address: '123 Main St',
    //     pays: "Afghanistan",
    //     localite: "Badakhshan",
    //     premises: "Siège social",
    //     createdAt: "24/10/2000 03:55",
    //     modifiedAt: "16/10/2024 18:16",
    // }, {
    //     id: 2,
    //     address: '123 Near St',
    //     pays: "Albania",
    //     localite: "Durrës",
    //     premises: "Espaces de restauration",
    //     createdAt: "16/05/1999 01:05",
    //     modifiedAt: "16/10/2024 18:16",
    // })


    // Filter data based on searchQuery
    const filteredData = data?.length > 0 ? data?.filter(item =>
        item?.address!=null && item?.address!=undefined && item?.address?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];
    // const filteredData =[];

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    const datas = filteredData?.length > 0 ? filteredData : [{ id: '', address: 'Aucun résultat' }];
    const handleEditFormClose = () => {
        setIsEditFormOpen(false);
        setIsAddFormOpen(false);
    };
    const moreActions = [
        {
            label: 'Editer',
            value: 'edit',
            handleClick: (row) => handleTableEditClick(row),
            icon: 'edit',
            color: 'black'
        },
        {
            label: 'Supprimer',
            value: 'Supprimer',
            handleClick: (row) => handleTableDeleteClick(row),
            icon: 'delete',
            labelColor: '#DC2626',
            color: '#EF4444'
        }
    ];
    const mobileAdd = () => {
        setIsAddFormOpen(!isAddFormOpen)
        window.scrollTo({ top: 0 })
        document.body.classList.add("mobile-org-tab-view")
    }

    useEffect(() => {
        if (!isAddFormOpen) {
            document.body.classList.remove("mobile-org-tab-view")
        }
        if (!verification) {
            document.body.classList.remove("mobile-verify-tab-view")
        }
        if (!isEditFormOpen) {
            document.body.classList.remove("mobile-edit-tab-view")
        }
    }, [isAddFormOpen, verification, isEditFormOpen])

    useEffect(() => {
        const updatedVerification = window.innerWidth >= 600 ? <VerificationCode handleVerificationClose={handleVerificationClose} verificationcodeErr={verificationcodeErr} handleSubmit={handleSubmit} label='Nous avons envoyé un code à 6 caractères à votre adresse email.Veuillez le saisir ci-dessous :' description='Vous ne trouvez pas votre code ? Vérifiez votre dossier Spam' title='Suppression d’adresse' buttonLabel='Supprimer adresse' /> : <MobileViewVerification title="Organisation" subtitle="Gérez votre organisation" label="Modification d’adresse email" labelContent="Veuillez saisir ci-dessous le code à 6 caractères envoyé à votre nouvelle adresse email :" handleSubmit={handleSubmit} handleVerificationClose={handleVerificationClose} labelbtn="Mettre à jour email" findTitle="Vous ne trouvez pas votre code ?" findSubtitle="RENVOYER LE CODE" />
        setViewDelete(updatedVerification)
    }, [])

    useEffect(()=>{
        if(isEditFormOpen == false || isAddFormOpen == false){
            props.setAPInewData && props?.setAPInewData('Done')
        }
        setTimeout(() => {
            props.setAPInewData && props?.setAPInewData('')
        }, 3000);
    },[isEditFormOpen,isAddFormOpen])

    return (
        isAddFormOpen && window.innerWidth <= 600 ? <>
            <CreateAddressMobile address={props.address} popupClose={handleEditFormClose} setdataValue={setdataValue} />
        </> :
            <div className="organisation-address">
                <SettingsContainerHeader title="Adresses" subtitle="Les adresses des locaux de votre organisation" enableAction={true} buttonName="Nouvelle adresse" icon="add" onClick={() => { mobileAdd() }} />
                {
                    isAddFormOpen && <CreateAddress organisationData={props?.organisationData} address={props.address}  popupClose={handleEditFormClose} />
                }
                <div className="organisation-search">
                    <div className="organisation-search-bar" style={{ width: "100%" }}>
                        <Input
                            args={{
                                value: searchQuery,
                                id: "search",
                                type: "text",
                                placeholder: 'Rechercher',
                                onChange: handleSearchChange,
                            }}
                            infoText=""
                            label=""
                            size="md"
                        />
                    </div>
                    <div className="iconsSection-org">
                        <img className="clearSearch" src="/photos/close.svg" alt="clear" onClick={handleClearSearch}></img>
                        <img className="searchIcon" src="/icons/search.svg" alt="search" onClick={() => { console.log(handleClearSearch) }} ></img>
                    </div>
                </div>
                <div className="organisation-table">
                    <OraganizationAddressTable data={datas?.map((item: any, index: any) => ({
                        ...item,
                        action: <MoreActions actions={[
                            {
                                label: 'Editer',
                                value: 'edit',
                                handleClick: () => handleTableEditClick(item),
                                icon: 'edit',
                                color: 'black'
                            },
                            {
                                label: 'Supprimer',
                                value: 'Supprimer',
                                handleClick: () => handleTableDeleteClick(item),
                                icon: 'delete',
                                labelColor: '#DC2626',
                                color: '#EF4444'
                            }
                        ]} />
                    }))} />

                    {isEditFormOpen && <div>
                        <div className="address-edit-lap"><EditAddress popupClose={handleEditFormClose} addressData={rowData} /></div>
                        <div className="address-edit-mobile"><EditAddressMobile popupClose={handleEditFormClose} addressData={rowData} /></div>
                    </div>}
                    {verification && viewDelete}
                    {toaster && verifiactionToaster()}
                </div>
            </div>
    );
}
