import React from 'react'
import { postMap } from 'kls-commons/service/karaz-remote-action'

export async function AppearanceServices(userId: any) {
    let actionUrl = '/karazal/ow-kls-extrat/usermanager/apparencedetails';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userId);


    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}

export async function AppearanceUpdateServices(userId: any, mode: any, color: any, formatDate: any) {
    let actionUrl = '/karazal/ow-kls-extrat/usermanager/updateapparencedetails';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userId);
    urlencoded.append("mode", mode);
    urlencoded.append("color", color);
    urlencoded.append("formatDate", formatDate);


    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}