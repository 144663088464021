import { useState } from 'react';
import './organisation-deletion.css';
import { SettingsContainerHeader } from '../../settings/settings';
import { Button, ErrorMessage, Input, addToast } from 'kls-ui';
import { OrganizationDeleteServices } from './organisation-delete-services';
import { fetchLocalUserProfile } from 'kls-commons/service/userService';
import { useHistory, useParams } from 'react-router-dom';
export function OrganisationDeletion(props: any) {
  const [errorMessage, setErrorMessage] = useState(false);
  let [showPassword, setshowPassword] = useState(false);
  let [password, setpassword] = useState('');
  let [passwordErr, setpasswordErr] = useState('');
  let [password2, setpassword2] = useState(' ');
  let [password2Err, setpassword2Err] = useState('');
  let [showPasswordConfirm, setshowPasswordConfirm] = useState(false);
  let [userName, setuserName] = useState('');
  let { organisationID } = useParams() as any;
  let history = useHistory();

  const deleteOrganisation = async () => {
    try {
      let loggedinUser = fetchLocalUserProfile();
      await OrganizationDeleteServices(loggedinUser?.userId, organisationID, password, password2, userName)
        .then(
          (result: any) => {
            console.log(result, "result")
            if (!result?.result) {
              let error: any = JSON.parse(Object.keys(result)[0]).error
              addToast(error, 'error');
              console.log("Error")
            } else if (result?.result == 'organism Deleted') {
              console.log("Success")
              history.push('/organisation')
              addToast("L'organisation est supprimée", "success")
            }
          },
          (error => {
            console.log("API_ERROR", error);
          })
        );
    }
    catch {
      console.log("Fetching error in organisation deletion")
    }
  }

  return (<div className="organisation-deletion-container">
    <SettingsContainerHeader title="Suppression d’organisation" subtitle="Supprimer votre organisation" />
    {errorMessage &&
      <div className='org-del-err-mes-btn'><ErrorMessage img='icons/error_outline.svg' label="Le nom d'utilisateur ou le mot de passe saisi est incorrect !" /></div>}
    <div className='organisation-deletion-authContainer'>
      <div className='organisation-deletion-auth'>
        <div className='organisation-deletion-usernameContent org-deletion-auth'>
          <Input
            size="md"
            args={{
              placeholder: `Entrez votre nom d’utilisateur`, required: true, id: "userName", onChange: (e) => {
                setuserName(e.target.value);
              }
            }}
            label="Nom d’utilisateur"
          ></Input>
        </div>
        <div className='organisation-deletion-passwordContent org-deletion-auth'>
          <Input
            errorMessage={passwordErr}
            args={{
              id: "password",
              type: showPassword ? "text" : "password", required: true, placeholder: 'Entrez votre mot de passe', onChange: (e) => {
                setpassword(e.target.value);
              }
            }}
            infoText=""
            label="Mot de passe"
            size="md"
          />
          {passwordErr == '' && <img className="eyeIcon org-deletion-eyeIcon" src={showPassword ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPassword(!showPassword)}></img>}
        </div>
        <div className='organisation-deletion-confirmPasswordContent org-deletion-auth'>
          <Input
            errorMessage={password2Err}
            args={{
              type: showPasswordConfirm ? "text" : "password", required: true, placeholder: 'Confirmez votre mot de passe', onChange: (e) => {
                setpassword2(e.target.value);
                setpassword2Err('');
              }
            }}
            infoText=""
            label="Confirmer mot de passe"
            size="md"
          />
          {password2Err == '' && <img className="eyeIcon org-deletion-eyeIcon" src={showPasswordConfirm ? "/icons/visibility_off.svg" : "/icons/remove_red_eye.svg"} alt="eye" onClick={() => setshowPasswordConfirm(!showPasswordConfirm)}></img>}
        </div>
      </div>
      <div className='organisation-deletion-btn'>
        <Button label='Supprimer organisation' leftIcon='delete' size='md' color='error' styling='outline' args={{ onClick: deleteOrganisation, disabled: userName.length != 0 && password === password2 && password.length != 0 && password2.length != 0 ? false : true, id: `${userName.length != 0 && password === password2 && password.length != 0 && password2.length != 0 ? `org-delete-undisable` : ``}` }} />
      </div>
    </div>
  </div>)
}

