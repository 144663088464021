
import { MoreActions, SearchInput } from "kls-ui";
import { SettingsContainerHeader } from "../settings";
import './role.css';
import { RolesPermissionTable } from "kls-ui/table/table";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { useEffect, useState } from "react";
export function Roles(props: any) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [ filter, setFilter ] = useState('');
  const itemsPerPage = 5;
  
 

  const datas = [
    {
      organisationImg: "photos/Ellipse16.svg",
      organisation: "RAM",
      expand: "Royal Air Maroc",
      role: "Propriétaire",
    },
    {
      organisationImg: "photos/Ram-handling.svg",
      organisation: "RAM Handling",
      role: "Administrateur",
    },
    {
      organisationImg: "photos/AirPort-Mhd.svg",
      organisation: "Aéroport Casablanca Mohammed V",
      role: "Citizen Developer",
    },
    {
      organisationImg: "photos/Direction-civil.svg",
      organisation: "DGAC",
      expand: "Direction Générale de l'aviation Civile",
      role: "Utilisateur",
    },
    {
      organisationImg: "photos/Ecole.svg",
      organisation: "ERA",
      expand: "École royale de l'air de Marrakech",
      role: "Utilisateur",
    },
  ]

  const handlePageChange = (start: number, end: number) => {
    console.log(start, end, "roles")
    const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
    setCurrentPage(newCurrentPage);
  };

  const totalItems = datas.length;
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, datas.length);
  
  const filteredRoleData = datas.filter(o =>
    o&&o.organisation.toLowerCase().includes(filter.toLowerCase())
  );

  const totalPages = Math.ceil(filteredRoleData.length / itemsPerPage);

  const dataRow = filteredRoleData && filteredRoleData.slice(startIndex, endIndex)


  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };

  console.log(filter, "filter")
  return (
    <div className="roler-container">
      <SettingsContainerHeader title="Roles" subtitle="Consultez vos rôles et permissions au sein des organisations et des applications." />
      <div>
        <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} />
      </div>
      <RolesPermissionTable data={dataRow.map((item) => ({
        ...item,
        actions: <MoreActions actions={[
          {
            label: 'Editer',
            value: 'edit',
            icon: 'edit',
            color: 'black'
          },
          {
            label: 'Supprimer',
            value: 'Supprimer',
            icon: 'delete',
            labelColor: '#DC2626',
            color: '#EF4444'
          }
        ]} />
      }))} />
      <Pagination light={true} totalItems={filteredRoleData.length} itemsPerPage={itemsPerPage} maxPage={totalPages} handlePageChange={handlePageChange}/>
    </div>)
}
