import { useState, useEffect } from 'react';
import './settingsProfile.css';
import { GetInitials, AdvancedDropDown, SearchInput } from 'kls-ui';
import { currentUser } from "kls-commons/service/userService";
import { myorganisme } from "kls-auth/service/auth-service";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";

export const SettingsProfile = ({ image, username, label, type }: { type: string, image: string, username: string, label: string }) => {
    let [avatarType, setavatarType] = useState('image');
    let [displayName, setdisplayName] = useState(username);
    let [inital, setInital] = useState('');
    let [imageUser, setImage] = useState(image);
    let [toggle, settoggle] = useState(false);
    let [finalresult, setfinalresult] = useState<any[]>([]);
    let { organisationID } = useParams() as any;
    let [filter, setfilter] = useState('')
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [PagedData, setPagedData] = useState<any>(finalresult.slice(0, 5) ?? [])
    const [filteredData, setfilteredData] = useState<any>([])
    const itemsPerPage = 5;
    let totalItems, startIndex, endIndex, totalPages;
    let history = useHistory();
    const handleChange= (value) => {
        if (value?.image != '') {
            setavatarType('image');
            setImage(value?.image)
        } else {
            setavatarType('inital');
            setInital(GetInitials(value?.label));
        }
        setdisplayName(value?.label);
        settoggle(!toggle);
        history.push("/organisation-settings/" + value?.id + '/');
    }

    useEffect(() => {
        if (avatarType == 'inital') {
            setInital(GetInitials(username));
        }
        async function fetchMyOrganization() {
            await myorganisme('', '', currentUser().userId, '')
                .then(
                    (result) => {
                        console.log(result, 'resultresult')
                        if (result?.error) {
                        } else {
                            let tempResult: any[] = [];

                            result?.data.forEach((val) => {
                                if (type == 'organisation') {
                                    if (organisationID == val?.organisationId) {
                                        setdisplayName(val?.name);
                                        setInital(GetInitials(val?.name));
                                        setImage(val?.logoGedId != '' ? "/karazal/DownloadFile?gedId=" + val?.logoGedId : "");
                                        setavatarType(val?.logoGedId != '' ? "image" : "inital");
                                    }
                                }
                                let resp = {
                                    label: val?.name,
                                    value: val?.name,
                                    id: val?.organisationId,
                                    image: val?.logoGedId != '' ? "/karazal/DownloadFile?gedId=" + val?.logoGedId : "",

                                };
                                tempResult.push(resp);
                            });
                            setfinalresult(tempResult);
                            setfilteredData(tempResult)
                            totalItems = tempResult.length;
                            startIndex = (currentPage - 1) * itemsPerPage;
                            endIndex = Math.min(startIndex + itemsPerPage, tempResult.length);
                            totalPages = Math.ceil(tempResult.length / itemsPerPage);
                            setPagedData(tempResult.slice(startIndex, endIndex))
                        }
                    },
                    (error => {
                        setdisplayName(username);
                        setInital(GetInitials(username));
                        console.log("LOGIN_ERROR", error);
                    })
                );
        }
        if (type == 'user') {
            setdisplayName(username);
            setInital(GetInitials(username));
        } else {
            fetchMyOrganization();
        }
    }, []);
    const handleFilterValue = (value) => {
        setfilter(value)
        setfilteredData(value !== '' ? finalresult.filter((c) => c.label&& c.label.toLowerCase().includes(value.toLowerCase())) : finalresult)
        totalItems = finalresult.filter((c) => c.label&& c.label.toLowerCase().includes(value.toLowerCase())).length;
        startIndex = (currentPage - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, finalresult.filter((c) => c.label&& c.label.toLowerCase().includes(value.toLowerCase())).length);
        totalPages = Math.ceil(finalresult.filter((c) => c.label&& c.label.toLowerCase().includes(value.toLowerCase())).length / itemsPerPage);
        setPagedData(finalresult.filter((c) => c.label&& c.label.toLowerCase().includes(value.toLowerCase())).slice(startIndex, endIndex))
        value === '' && setPagedData(finalresult.slice(startIndex, endIndex))
    }
    const handlePageChange = (value) => {
        setCurrentPage(value)
        totalItems = filteredData.length;
        startIndex = (value - 1) * itemsPerPage;
        endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);
        totalPages = Math.ceil(filteredData.length / itemsPerPage);
        setPagedData(filteredData.slice(startIndex, endIndex))
    }

    return (
        <div style={{position: 'relative', color: '#1E293B'}}>
            <div className="setting-profile" onClick={() => {type == 'organisation' && settoggle(!toggle); setfilter(''); setfilteredData(finalresult); setPagedData(finalresult.slice(0, 5))}}>
                <div>
                    {avatarType == 'image' ? <img onError={(e) => setavatarType('inital')} src={imageUser} alt={imageUser} className="set-pro-img" /> : <div data-settings-inital={inital}></div>}
                </div>
                <p className='setting-label '>{label}</p>
                <div className="user-drop">
                    <h4 className='setting-user'>{type == 'organisation' ? displayName : username}</h4>
                    <span className="more-icon-set icon">expand_more</span>
                </div>
            </div>
            {type == 'organisation' && toggle &&
                <div className='organisation-header-dropdown'>
                    <SearchInput light={true} size={3} value={filter} setValue={(value) => handleFilterValue(value)} addClearbutton={true} />
                    {PagedData&& PagedData.length > 0 ? PagedData.map((c) => {
                        return(
                            <div style={{padding: '5px 12px', fontFamily: 'Inter', fontSize: '13px'}} onClick={() => handleChange(c)}>{c?.label}</div>
                        )
                    }) : <div style={{textAlign: 'center', color: '#94A3B8', fontSize: '13px'}}>Aucun Resultat</div>}
                    {/* <AdvancedDropDown
                        handleChange={(value) => {
                            if (value?.image != '') {
                                setavatarType('image');
                                setImage(value?.image)
                            } else {
                                setavatarType('inital');
                                setInital(GetInitials(value?.label));
                            }
                            setdisplayName(value?.label);
                            settoggle(!toggle);
                            history.push("/organisation-settings/" + value?.id + '/');
                        }}
                        data={dataRow}
                        enableSearch
                        light
                        selectedValue={displayName}
                        size={2}
                    /> */}
                    {filteredData.length > 3 && <div style={{width: '95%', border: '1px solid #E2E8F0'}}></div>} 
                    {filteredData.length > 3 && 
                        <div style={{display: 'flex', gap: '5px', justifyContent: 'center', alignItems: 'center', cursor: 'default', fontSize: '13px'}}>
                            <span className='icon' style={{color: currentPage > 1 ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(1)}>keyboard_double_arrow_left</span>
                            <span className='icon' style={{color: currentPage > 1 ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}>chevron_left</span>
                            <span className='current-page'>{currentPage}</span>
                            <span className='icon' style={{color: currentPage != Math.ceil(filteredData.length / itemsPerPage) ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(currentPage < Math.ceil(filteredData.length / itemsPerPage) ? currentPage + 1 : Math.ceil(filteredData.length / itemsPerPage))}>chevron_right</span>
                            <span className='icon' style={{color: currentPage != Math.ceil(filteredData.length / itemsPerPage) ? '#64748B' : '#E2E8F0'}} onClick={() => handlePageChange(Math.ceil(filteredData.length / itemsPerPage))}>keyboard_double_arrow_right</span>
                        </div>
                    }
                </div>
            }
        </div>
    )
}