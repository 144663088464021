import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter, Link, Route, Switch, useLocation, useRouteMatch, } from "react-router-dom";
import { checkFirstConnect, currentUser, fetchLocalUserDetails, fetchLocalUserProfile, signout, storeLocalUserProfile } from "kls-commons/service/userService";
import { GS, setToStorage } from "kls-commons/state/global-state";
import { getTemplatesDirectoryTree, listModules } from "kls-commons/service/repo-service";
import ModuleEditor from "./module/module-editor";
import { Header } from "./layout/header";
import { ProfileHeader } from 'kls-ui';
import { ParamApp } from "./app-params/param-app";
import { PwdReset, Signup, Login, FirstLogin } from "kls-auth";
import MyAppsPage from "./on-boarding-pages/my-apps";
import { Home } from "./on-boarding-pages/home-page";
import { SettingsModule } from "./organization-settings/settings-module";
import { CataloguePage, MyOrganization } from "kls-catalog";
import { StepsBar, StepsBarMobile } from "./on-boarding-pages/steps-bar";
import { CommonHeader } from "./common-header";
// import { MyOrganization } from "kls-catalog";

function AppHome(props: any) {

  const [itemToDisplay, setItemToDisplay] = useState<string>();
  const location = useLocation();

  useEffect(
    () => {
      setItemToDisplay(location.pathname.split("/")?.[1]);
    }, [location]
  )

  const [firstConnection, setFirstConnection] = useState<boolean>( false ) ;
  const [loader, setLoader] = useState<boolean>(false);

  useEffect( 
    () => {
      setLoader(true);
      let userPRofileText = localStorage.getItem("USER_PROFILE");
      let userID = '';
      if (userPRofileText) {
        let result = JSON.parse(userPRofileText);
        userID = result === null || result === void 0 ? void 0 : result.userId;
      }
      if (userID != undefined) {
        checkFirstConnect(userID).then(
          async (authenticated) => {
            if (authenticated) {
              setLoader(false);
              let result = fetchLocalUserDetails(); 
              if (result?.firstConnect == true) {
                setFirstConnection(result?.firstConnect)
              } 
            } else {
              console.log(authenticated)
            }
          }).catch(error => {
            setLoader(false);
            console.log(error)
          });
      }
    } , []
  )

  const [themeHandler, setthemeHandler] = useState(false)
  const [hintType, sethintType] = useState("")
  const [mobilescreen, setmobilescreen] = useState(false)
  useEffect(() => {
    !themeHandler && sethintType("")
    if (window.innerWidth <= 700) {
      setmobilescreen(true)
    }
    else {
      setmobilescreen(false)
    }
  }, [themeHandler])
 

  return <Switch>
  
    <Route path="/editService/:moduleName/:modelName?/:resourceType?/:resourceName?" >
      <ModuleEditor></ModuleEditor>
    </Route>

    <Route path="/parametreApp/:moduleName" exact>
      <CommonHeader selectedItem={'application'} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      <ParamApp />
    </Route>

    <Route path="/organisation-settings/:organisationID" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "organisation-settings" && <SettingsModule type="organizationSettings" tab="Organisation" gs={GS} />}
    </Route>
    <Route path="/application-settings/:applicationID" exact>
      <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />
      {itemToDisplay === "application-settings" && <SettingsModule type="applications" tab="application" gs={GS} />}
    </Route>

    <Route path="/">

      {
        firstConnection ? 
        <FirstLogin gs={GS} connected={true}></FirstLogin>
        :
          loader ? <div className="inprogress-inner">
            <div className="spinner">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          </div> : <>        
            <CommonHeader selectedItem={itemToDisplay} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />

            {itemToDisplay === 'application' && <MyAppsPage gs={GS} />}

            {itemToDisplay === 'catalog' && <CataloguePage />}

            {itemToDisplay === "settings" && <SettingsModule type="settings" tab={"workspace"} gs={GS} />}
            {itemToDisplay === "appearance" && <SettingsModule type="settings" tab={"appearance"} gs={GS} />}

            {itemToDisplay === "users" && <SettingsModule type="organisation" tab={"Users"} hintType={hintType} sethintType={sethintType} />}
            {itemToDisplay === "organisation" && <MyOrganization hintType={hintType} gs={GS} ></MyOrganization>}

            {itemToDisplay === "" && <Home gs={GS} themeHandler={themeHandler} setthemeHandler={setthemeHandler} hintType={hintType} sethintType={sethintType} />}
          </>
      }


    </Route>
  </Switch>

}


function App() {
  let [userProfile, setUserProfile] = useState(fetchLocalUserProfile());

  useEffect(() => {
    getTemplatesDirectoryTree(undefined, undefined).then((s) => {
      if (s) {
        setToStorage("TEMPLATE_MODELS", s);
      }
    });

  }, []);

  return (
    <HashRouter>
      <div className="App">
        <Switch>
          <Route path="/reset-pwd/:activationId/:email">
            <PwdReset></PwdReset>
          </Route>
          <Route path="/login">
            <Login postSignIn={setUserProfile}></Login>
          </Route>
          <Route path="/signup">
            <Signup postSignIn={setUserProfile}></Signup>
          </Route>
          <Route path="/">
            {userProfile && <AppHome gs={GS}></AppHome>}
            {!userProfile && <Login postSignIn={setUserProfile} ></Login>}
          </Route>
        </Switch>
        <div className="toasts-container"></div>
      </div>
    </HashRouter>
  );
}


function NavBar({ selectedItem }: { selectedItem: string }) {

  const navBar = [
    {
      link: '',
      label: 'Accueil'
    },
    {
      link: 'organisation',
      label: 'Organisations'
    },
    {
      link: 'application',
      label: 'Applications'
    },
    {
      link: 'catalog',
      label: 'Catalogue'
    },
    {
      link: 'resource',
      label: 'Ressources'
    }
  ]


  return <div className="navbar-container">
    {
      navBar.map(
        (item: any, index: number) => <Link key={index} className={`link-container ${selectedItem === item.link ? 'selected' : ''}`} to={`/${item.link}`}><span className="color-link">{item.label}</span></Link>
      )
    }
  </div>
}


export {NavBar}
export default App;
