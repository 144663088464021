export const timeZonesValue = [
    "UTC-12:00 - Ligne de changement de date ouest",
    "UTC-11:00 - Temps universel coordonné moins 11 heures",
    "UTC-10:00 - Hawaï-Aleutien standard time",
    "UTC-09:00 - Alaska standard time",
    "UTC-08:00 - Pacifique standard time",
    "UTC-07:00 - Montagne standard time",
    "UTC-06:00 - Centre standard time",
    "UTC-05:00 - Est standard time",
    "UTC-04:00 - Atlantique standard time",
    "UTC-03:00 - Argentine standard time",
    "UTC-02:00 - Îles Midway standard time",
    "UTC-01:00 - Azores standard time",
    "UTC±00:00 - Temps moyen de Greenwich",
    "UTC+01:00 - Heure d'Europe centrale",
    "UTC+02:00 - Heure d'Europe de l'Est",
    "UTC+03:00 - Moscou standard time",
    "UTC+04:00 - Golfe standard time",
    "UTC+05:00 - Pakistan standard time",
    "UTC+06:00 - Omsk standard time",
    "UTC+07:00 - Indochine standard time",
    "UTC+08:00 - Heure de l'Australie occidentale",
    "UTC+09:00 - Japon standard time",
    "UTC+10:00 - Australie de l'Est standard time",
    "UTC+11:00 - Îles Salomon standard time",
    "UTC+12:00 - Nouvelle-Zélande standard time"
  ]