import "./verification-settings.css";
import { SettingsContainerHeader } from "../../settings/settings";
import { Button, Modal } from "kls-ui";
import { useEffect, useState } from "react";
import { Pagination } from "kls-ui/paginationKls/pagination";

export function VerificationSettings(props: any) {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadErr, setUploadErr] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [currentDocumentContent, setCurrentDocumentContent] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState('');
  const [globalIndexes, setGlobalIndexes] = useState<number[]>([]);

  let TestData = (uploadedFiles.length/itemsPerPage)
  const lastPage = Math.ceil(TestData)+1

  const onChange = (file: File) => {
    console.log(file, "file")
    const reader = new FileReader();
    console.log(reader, "reader")
    reader.onload = () => {
      const content = reader.result;
      console.log(content, "content")
      if (file.type === 'application/pdf') {
        const b64 = content as string;
        const byteString = atob(b64.split(",")[1]);

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url);
      } else {
        if (content) {
          if (file.type.startsWith('image/')) {
            setCurrentDocumentContent({ type: 'image', data: content });
          } else {
            setCurrentDocumentContent({ type: 'other', data: content });
          }
          setIsModalOpen(true);
        }
      }
    };
    reader.readAsDataURL(file);
    setFileName(file.name)
  };  

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (start: number, end: number) => {
    const newCurrentPage = Math.floor(start / itemsPerPage) + 1;
    setCurrentPage(newCurrentPage);
  };

  function handleFileUploadClick() {
    const fileInput = document.querySelector('.file-upload-verification-settings') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files) {
      const validFiles = Array.from(files).filter(file => {
        const allowedFormats = ['application/pdf', 'image/png', 'image/jpeg'];
        return allowedFormats.includes(file.type);
      });
      if (validFiles.length === files.length) {
        setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
        setUploadErr('')
      } else {
        setUploadErr('Invalid file formats. Please upload files in PDF, PNG, or JPEG format.');
      }
    } else {
      setUploadErr('No files selected.');
    }
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) {
      const validFiles = Array.from(files).filter(file => {
        const allowedFormats = ['application/pdf', 'image/png', 'image/jpeg'];
        return allowedFormats.includes(file.type);
      });
      setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  function formatFileSize(bytes: number) {
    if (bytes === 0) return '0 KB';
    const k = 1024;
    const sizeInKB = bytes / k;
    return parseFloat(sizeInKB.toFixed(2)) + ' KB';
  }

  const removeData = (index: number) => {
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFiles(newUploadedFiles);
    if (currentFiles.length === 1 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, uploadedFiles.length);

  const currentFiles = uploadedFiles.slice(startIndex, endIndex);

  useEffect(() => {
    const indexes = currentFiles.map((_, index) =>  index);
    setGlobalIndexes(indexes);
  }, [uploadedFiles,currentPage]);

  return (
    <div className="verification-settings-container">
      <SettingsContainerHeader
        title="Vérification"
        subtitle="Veuillez fournir des justificatifs permettant de vérifier l'authenticité de votre organisation et la fonction que vous y occupez."
      />
      {uploadErr && (
        <div className="error-message">
          <span className="icon error-logo-org">error_outline</span>
          <div className="orgination-error-message">{uploadErr}</div>
        </div>
      )}
      <div className="verification-settings-content-container">
        <div className="verification-setting-content-status">
          <h1>Statut vérification :</h1>
          <div className="verification-setting-content-status-subDiv">
            <p>Non vérifiée</p>
            <div className="verification-setting-content-status-color"></div>
          </div>
        </div>
        <div className="verification-settings-upload-container">
          <div className="verification-settings-upload-header">
            <h1>Dépôt des pièces justificatives</h1>
          </div>
          <div className="verification-settings-upload-box" onClick={handleFileUploadClick} onDrop={handleDrop} onDragOver={handleDragOver}>
            <div className="verification-settings-upload-icon">
              <span className="icon upload-icon-verify-settings">file_upload</span>
              <input type="file" className="file-upload-verification-settings" onChange={handleFileUpload} />
            </div>
            <div className="verification-settings-upload-description">
              <h1>Faites glisser ou déposez vos pièces justificatives ici</h1>
              <h2>Formats pris en charge : PDF, PNG, JPEG</h2>
            </div>
          </div>
        </div>
        <div className="verification-setting-uploaded-data">
          {currentFiles.length > 0 && (
            <div>
              {currentFiles.map((file, index) => (
                <ul key={globalIndexes[index]} className="verification-setting-uploaded-data-ul">
                  <li className="verification-setting-uploaded-data-li">
                    <div className="verification-setting-uploaded-data-li-detials">
                      <span className="icon icon-doc-verify">text_snippet</span>
                      <span className="verification-setting-uploaded-data-name">{file.name}</span>
                      <span className="verification-setting-uploaded-data-size">{formatFileSize(file.size)}</span>
                    </div>
                    <div className="verification-setting-uploaded-data-li-options">
                      <span className="icon icon-eye-verify" onClick={() => onChange(file)}>visibility</span>
                      <span className="icon icon-delete-verify" onClick={() => removeData(index)}>delete_outline</span>
                    </div>
                  </li>
                </ul>
              ))}
              <Pagination
                light={true}
                totalItems={uploadedFiles.length}
                itemsPerPage={itemsPerPage}
                maxPage={uploadedFiles.length}
                handlePageChange={handlePageChange}
              />
               {isModalOpen && <Modal onClose={closeModal} title={fileName} icon={""}  >
                <div className="verification-setting-model-popup">
               {currentDocumentContent && currentDocumentContent.type === 'image' && (
                <img src={currentDocumentContent?.data} alt="Uploaded Image" height='100%' width='100%'/>
               )}
               </div>
               </Modal>
              }
            </div>
          )} 
         
        </div>
        <div className="verification-settings-upload-submit">
          <Button size={"md"} label="Procéder à la vérification" color={"primary"} styling={"solid"} args={{}} />
        </div>
      </div>
    </div>
  );
}
