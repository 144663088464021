import { useState, useEffect } from "react";
import "./organization-settings.css";
import { SettingsContainerHeader } from "../settings/settings";
import { Dropdown, Input, Button, TextArea, TelephoneInput, ColorInput, HashtagInput, TagInput, addToast } from "kls-ui";
import { checkFirstConnect, fetchLocalUserDetails, fetchLocalUserProfile } from "kls-commons/service/userService"
import { testEmail } from "kls-auth/signup/validation"
import { AddressList } from "./address/list";
import { OrganizationSettingsService, OrganizationSettingsUpdate } from "./organization-settings-service";
import { useLocation, useParams } from "react-router-dom";

export function OrganisationSettings(props: any) {
  const location = useLocation();
  let [industries, setindustries] = useState<any[]>([]);
  let [tagvalue, setTagvalue] = useState('')
  let [organizationnameErr, setorganizationnameErr] = useState('');
  let [organizationname, setorganizationname] = useState('');
  let [organizationMail, setorganizationMail] = useState('');
  let [organizationMailErr, setorganizationMailErr] = useState('');
  let [organizationDrop, setorganizationDrop] = useState('');
  let [industryErr, setindustryErr] = useState('');
  let [industry, setindustry] = useState('');
  let [errorMessage, setErrorMessage] = useState("");
  let [selectedColor, setselectedColor] = useState("");
  let [acronyme, setAcronyme] = useState('')
  let [owner, setOwner] = useState('')
  let [description, setDescription] = useState('')
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [telephoneErr, setTelephoneErr] = useState(false)
  let [telephoneValue, setTelephoneValue] = useState('')
  let [identifiant, setIdentifiant] = useState('')
  let [webSite, setWebSite] = useState('')
  let [address, setAddress] = useState<any>([])
  let [organisationData, setorganisationData] = useState<any>([])
  let [hashTag, setHashTag] = useState([])
  let [APInewData, setAPInewData] = useState<any>()
  let { organisationID } = useParams() as any
  const handleTagsChange = (newTags) => {
    setHashTag(newTags);
  };

  const handleClearValue = () => {
    setHashTag([])
  }

  useEffect(() => {
    let loggedinUser = fetchLocalUserProfile();
    if (loggedinUser?.userId != undefined)
      checkFirstConnect(loggedinUser?.userId).then(
        (authenticated) => {
          if (authenticated) {
            let result = fetchLocalUserDetails();
            if (result) {
              let data1 = { "label": 'Toute industrie', "value": '' };
              let array = [data1, ...result?.industries];
              setindustries(array)
            }
          }
        },
        (error => {
          console.log("LOGIN_ERROR", error);
        })
      );
  }, []);

  function handleEditClick() {
    const fileInput = document.querySelector('.file-upload') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const allowedFormats = ['image/jpeg', 'image/svg+xml', 'image/png'];
      if (allowedFormats.includes(selectedFile.type)) {
        const fileSize = selectedFile.size / 1024 / 1024;
        if (fileSize <= 2) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = () => {
              if (img.width >= 1 && img.height >= 1) {
                setSelectedImage(reader.result as string);
                setErrorMessage("");
              }
            };
            img.src = reader.result as string;
          };
          reader.readAsDataURL(selectedFile);
        } else {
          setErrorMessage("La taille de l'image ne doit pas dépasser 2 Mo.");
        }
      } else {
        setErrorMessage("Veuillez sélectionner une image au format JPEG, SVG ou PNG.");
      }
    }
    else {
      setErrorMessage("Veuillez sélectionner un fichier.");
    }
  }

  const createOrganismeSubmit = async () => {
    console.log('........submit')
    let isValid = true;

    if (organizationname === '') {
      setorganizationnameErr("Nom champ est requis");
      isValid = false;
    } else {
      setorganizationnameErr("");
    }

    if (organizationMail === '') {
      setorganizationMailErr("Mail champ est requis");
      isValid = false;
    } else {
      setorganizationMailErr("");
      const emailErrMsg = testEmail(organizationMail);
      if (emailErrMsg !== '') {
        setorganizationMailErr(emailErrMsg);
        isValid = false;
      }
    }

    if (industry === '') {
      setindustryErr("Industrie est requis");
      isValid = false;
    } else {
      setindustryErr("");
    }

    setTelephoneErr(true)

    if (selectedImage === '') {
      const fileInput = document.querySelector('.file-upload') as HTMLInputElement;
      if (fileInput && fileInput.files && fileInput.files[0]) {
        setErrorMessage("");
      } else {
        setErrorMessage("Veuillez attacher le logo de votre organisation");
        isValid = false;
      }
    }

    console.log(isValid, 'isValid')
    if (isValid) {
      // Update Organiation Details
      let result = fetchLocalUserDetails();
      
      await OrganizationSettingsUpdate(result?.userID, organisationID, organizationname, acronyme, industry, selectedColor, description, tagvalue, organizationMail, telephoneValue, identifiant, webSite, selectedImage)

        .then(
          (result) => {
            console.log('OrganizationSettingsUpdateresult',result)
            if (result?.Exception) {
              console.log(result);
              addToast(result?.Exception, 'error');
            } else if (result?.result == 'Done') {
              addToast('Organisation créée avec succès', 'success');
              localStorage.setItem('organizationData', JSON.stringify(result));
              console.log('OrganizationSettingsUpdate')
            }
          },
          (error => {
            console.log("API_ERROR", error);
          })
        );
    }
    
    return isValid;
  }

  console.log(address,'........................address')

  useEffect(() => {
    const fetchData = async () => {
      try {
        let loggedinUser = fetchLocalUserProfile();

        const fetchDataResult = await OrganizationSettingsService(loggedinUser?.userId, location.pathname.split("/")?.[2]);
        setorganisationData(fetchDataResult?.data);
        setorganizationname(fetchDataResult?.data?.name)
        setAcronyme(fetchDataResult?.data?.acronyme)
        setOwner(fetchDataResult?.data?.proprietaire)
        setindustry(fetchDataResult?.data?.industry)
        setselectedColor(fetchDataResult?.data?.color)
        setDescription(fetchDataResult?.data?.description)
        setTagvalue(fetchDataResult?.data?.listOfTags.tags.tag)
        setorganizationMail(fetchDataResult?.data?.email)
        setTelephoneValue(fetchDataResult?.data?.phoneNumber)
        setIdentifiant(fetchDataResult?.data?.identifiant)
        setWebSite(fetchDataResult?.data?.site)
        setSelectedImage("/karazal/DownloadFile?gedId=" + fetchDataResult?.data?.logoGedId);
        console.log(fetchDataResult?.data?.adresses,"address");

        setAddress(fetchDataResult?.data?.adresses?.adresse)
      }
      catch (error) {
        console.error("Error fetching organization settings:", error);
      }
    }
    fetchData()
  }, [props,APInewData])

  console.log('setHashTag',tagvalue)
  
  useEffect(() => {
    if (selectedColor == '') {
      setselectedColor('#0000FF')
    }
  }, [selectedColor])

  console.log('setselectedColor', selectedColor)

  return (
    <div className="organisation-component">
      <SettingsContainerHeader title="Organisation" subtitle="Gérez votre organisation" />
      <div className="organisation-imgSec-main">
        {errorMessage && !selectedImage && <div className="error-message">
          <span className="icon error-logo-org">error_outline</span>
          <div className="orgination-error-message">{errorMessage}</div>
        </div>}
        <div className="organisation-imgSec">
          <div className="organisation-logo">
            {selectedImage ? (
              <img className="Uploaded-logo-organisation" src={selectedImage} alt="logo" />
            ) : (
              <img className="login-logo-organisation" src="/photos/OrganizationLogo.svg" alt="logo" />
            )}
            <span className="icon org-edit-icon" onClick={handleEditClick}>
              <span className="icon new-edit-logo-org">edit</span>
            </span>
            <input type="file" className="file-upload" onChange={handleFileUpload} required />
          </div>
          <div className="Imgrestriction-Organisation Img-Org-desktop">
            <p className="Img-title">Formats autorisés : <span>.png et .svg</span></p>
            <p className="Img-title">Taille maximale autorisée : <span>2 Mo</span></p>
            <p className="Img-title">Dimensions idéales de l’image : <span>100px * 100 px</span> </p>
          </div>
          <div className="Imgrestriction-Organisation Img-Org-mobile">
            <p className="Img-title">Formats : <span>.png et .svg</span></p>
            <p className="Img-title">Max : <span>2 Mo</span></p>
            <p className="Img-title">Dim : <span>100px * 100 px</span> </p>
          </div>

        </div>
      </div>
      <div className="organisation-form">
        <div className="organisation-form-header">
          <h1 className="organisation-form-head">Informations générales</h1>
        </div>
        <div className="organisation-personal-detials">
          <div className="organisation-name-detials">
            <h1>Nom</h1>
            <Input size='md' args={{
              placeholder: 'Entrez le nom de votre organisation', required: true,
              value: organizationname,
              onChange: (e) => {
                setorganizationname(e.target.value);
                setorganizationnameErr(e.target.value == '' ? "Ce champ est requis" : "")
              }
            }} errorMessage={organizationnameErr} />
            <p>ID : {organizationname?.toLocaleLowerCase().replaceAll(' ', '_')}</p>
          </div>
          <div className="organisation-subname-detials">
            <h1>Acronyme</h1>
            <Input size='md' value={acronyme} args={{ onChange: (e) => { setAcronyme(e.target.value) }, placeholder: 'Entrez l’acronyme de votre organisation' ,value:acronyme}} />
          </div>
          <div className="organisation-owner-detials">
            <h1>Propriétaire</h1>
            <Input size='md' value={owner} args={{ onChange: (e) => { setOwner(e.target.value) }, placeholder: 'Jalal ALE', disabled: true ,value:owner}} />
          </div>
        </div>
        <div className="organisation-transport-detials">
          <div className="oragnisation-industryList">
            <h1>Industrie</h1>
            <Dropdown
              value={industry}
              size="md"
              elementSize={36}
              setValue={(e) => { setindustry(e); setindustryErr('') }}
              elements={industries}
              label=""
              listTopPos="105%"
              listBottomPos=""
              listLeftPos=""
              listRightPos=""
              errorMessage={industryErr}
              args={{
                placeholder: 'Choisissez une industrie', required: true,
                onChange: (e) => {
                  setorganizationDrop(e.target.value);
                  setindustryErr(e.target.value === '' ? "Ce champ est requis" : "")
                }
              }}
            />
          </div>
          <div className="organisation-car-color">
            <ColorInput color={selectedColor} onColorChange={(e) => setselectedColor(e)} />
          </div>
        </div>
        <div className="organisation-description">
          <h1>Description</h1>
          <TextArea size='md' infoText={description} args={{ onChange: (e) => { setDescription(e.target.value) }, placeholder: 'Décrivez brièvement votre organisation',value:description }} />
        </div>
        <div className="organisation-tags">
          {/* <h1>Tags</h1> */}
          {/* <HashtagInput tagPlaceholder={'Entrez les tags de votre organisation...'} /> */}
          <TagInput tags={hashTag} onTagsChange={handleTagsChange}  />
          {/* <span className='icon org-input-hashtag-clear' onClick={handleClearValue}>close</span> */}
        </div>
      </div>
      <div className="organisation-Contact">
        <div className="organisation-contact-header">
          <h1 className="organisation-contact-head">Coordonnées</h1>
        </div>
        <div className="organisation-contact-detials">
          <div className="organisation-contact-firstdiv">
            <div className="organisation-email-detials">
              <h1>Email</h1>
              <Input size='md' value={organizationMail} args={{
                placeholder: 'Entrez l’email de votre organisation', required: true,
                value:organizationMail,
                onChange: (e) => {
                  setorganizationMail(e.target.value);
                  setorganizationMailErr(e.target.value == '' ? "Ce champ est requis" : "")
                }
              }}
                errorMessage={organizationMailErr}
              />
            </div>
            <div className="organisation-number-detials">
              <h1>Téléphone</h1>
              <TelephoneInput errorMessage={telephoneErr} value={telephoneValue} setValue={ setTelephoneValue} />
            </div>
          </div>
          <div className="organisation-contact-seconddiv">
            <div className="organisation-identification-detials">
              <h1>N° identifiant</h1>
              <Input size='md' value={identifiant} args={{ onChange: (e) => { setIdentifiant(e.target.value) }, placeholder: 'Entrez l’identifiant de votre organisation',value:identifiant }} />
            </div>
            <div className="organisation-web-detials">
              <h1>Site web</h1>
              <Input size='md' value={webSite} args={{ onChange: (e) => { setWebSite(e.target.value) }, placeholder: 'Entrez le site web de votre organisation' ,value:webSite }} />
            </div>
          </div>
        </div>
      </div>
      <AddressList setAPInewData={setAPInewData} address={address} organisationData={organisationData} />
      <div className="organisation-button">
        <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { createOrganismeSubmit() } }} />
      </div>
    </div>
  );
}
