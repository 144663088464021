import "./settingsContainerHeader.css"
import { Button } from "kls-ui";
interface SettingsProps {
    title?: string;
    subtitle?: string;
    enableAction?: boolean;
    buttonName?: string;
    icon?: string;
    onClick?: () => void;
    hintType?: string;
}

export const SettingsContainerHeader = (props: SettingsProps) => {
    const { title, subtitle } = props;
    const newUserStyle = props.hintType === "userSetting" ? {
        zIndex: '461', 
        background: 'white',
        padding: '10px 12px',
        borderRadius: '8px',
      } : {}
    return (
        <div className="container-head">
            <div className="container-content">
                <p className="container-title">{title}</p>
                <p className="container-subtitle">{subtitle}</p>
            </div>
            {props?.enableAction && <div className="container-button">
                <Button args={{ onClick: props?.onClick, style: { height: '40px', gap: '4px', ...newUserStyle, position: 'relative' }, className: "settings-action-btn" }} color="primary" label={props?.buttonName} rightIcon="" styling="solid" size={'xs'} leftIcon="add" />
            </div>}
        </div>

    )
}