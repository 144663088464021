import "./course-details.css";
import "./home-page.css";
import { NewModelWizard } from "../module/new-model-wizard"
import { CourseDetails } from "./course-details"
import MyAppsPage from "./my-apps"
import { MyOrganization } from "kls-catalog";


export function Home(props: any) {
    return <div>

        <MyOrganization gs={props.gs} hintType={props.hintType}></MyOrganization>
        <div className="organizationSec">
            <MyAppsPage gs={props.gs} />
        </div>

        <CourseDetails />
    </div>
}


function creationWizard() {
    return (onOk: Function, onClose: Function) => <NewModelWizard onClose={onClose} />
}