import { useEffect, useState } from "react";
import { SettingsContainerHeader } from "../settings";
import './workspace.css'
import { Button, Dropdown, Input, addToast } from "kls-ui";
import { countries } from "../../common/countries";
import { timeZonesValue } from "../../common/timeZone";
import { fetchLocalUserDetails, fetchLocalUserProfile } from "kls-commons/service/userService";
import { WorkspaceService, WorkspaceUpdateService } from "../settings-services/workspace-service";

const timeZone = timeZonesValue.map(value => ({ 'label': value, 'value': value }))

export function Workspace(props: any) {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  let [civility, setCivility] = useState<any[]>([{ "label": "Monsieur", "value": "Monsieur" }, { "label": "Madame", "value": "Madame" }]);
  let [TimeZone, setTimeZone] = useState<any[]>(timeZone);
  let [language, setLanguage] = useState<any[]>([{ "label": "Français", "value": "Français" }, { "label": "Anglais", "value": "Anglais" }, { "label": "Arabe", "value": "Arabe" }]);
  let [civilitydropdown, setCivilitydropdown] = useState('');
  let [civilitydropdownErr, setCivilitydropdownErr] = useState('');
  let [organizationnameErr, setorganizationnameErr] = useState('');
  let [organizationname, setorganizationname] = useState('');
  let [organizationFirstnameErr, setorganizationFirstnameErr] = useState('');
  let [organizationFirstname, setorganizationFirstname] = useState('');
  let [countryValue, setcountryValue] = useState('Maroc');
  let [languageValue, setlanguageValue] = useState('Français');
  let [utcValue, setutcValue] = useState(`UTC+01:00 - Heure d' Europe centrale`);

  console.log('...............,', timeZone)

  function handleEditClick() {
    const fileInput = document.querySelector('.file-upload') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const allowedFormats = ['image/jpeg', 'image/svg+xml', 'image/png'];
      if (allowedFormats.includes(selectedFile.type)) {
        const fileSize = selectedFile.size / 1024 / 1024;
        if (fileSize <= 2) {
          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = () => {
              if (img.width >= 1 && img.height >= 1) {
                setSelectedImage(reader.result as string);
                setErrorMessage("");
              }
            };
            img.src = reader.result as string;
          };
          reader.readAsDataURL(selectedFile);
        } else {
          setErrorMessage("La taille de l'image ne doit pas dépasser 2 Mo.");
        }
      } else {
        setErrorMessage("Veuillez sélectionner une image au format JPEG, SVG ou PNG.");
      }
    }
    else {
      setErrorMessage("Veuillez sélectionner un fichier.");
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let loggedinUser = fetchLocalUserProfile();
        let fetchDataResult = await WorkspaceService(loggedinUser?.userId);

        console.log("Selected Image:", fetchDataResult?.data?.avatar);
        console.log("Civility Dropdown:", fetchDataResult?.data?.civilite);
        console.log("Organization Firstname:", fetchDataResult?.data?.prenom);
        console.log("Country Value:", fetchDataResult?.data?.pays);
        console.log("Organization Name:", fetchDataResult?.data?.nom);
        console.log("Language Value:", fetchDataResult?.data?.langue);
        console.log("UTC Value:", fetchDataResult?.data?.fuseauHoraire);

        setSelectedImage(fetchDataResult?.data?.avatar)
        setCivilitydropdown(fetchDataResult?.data?.civilite)
        setorganizationFirstname(fetchDataResult?.data?.prenom)
        setcountryValue(fetchDataResult?.data?.pays)
        setorganizationname(fetchDataResult?.data?.nom)
        setlanguageValue(fetchDataResult?.data?.langue)
        setutcValue(fetchDataResult?.data?.fuseauHoraire)
      }
      catch (error) {
        console.error("Error fetching organization settings:", error);
      }
    }
    fetchData()
  }, [])

  console.log('organizationFirstname',organizationFirstname)
  console.log('organizationname',organizationname)

  const WorkspaceSubmit = async () => {
    let isValid = true;

    if (organizationname === '') {
      setorganizationnameErr("Nom champ est requis");
      isValid = false;
    } else {
      setorganizationnameErr("");
    }
    if (organizationFirstname === '') {
      setorganizationFirstnameErr("Nom champ est requis");
      isValid = false;
    } else {
      setorganizationFirstnameErr("");
    }

    if (civilitydropdown === '') {
      setCivilitydropdownErr("Civilité champ est requis");
      isValid = false;
    } else {
      setCivilitydropdownErr("");
    }

    if (!isValid) {
      setErrorMessage("Veuillez remplir tous les champs obligatoires.");
    } else {
      setErrorMessage("");
    }
    console.log(isValid, 'isValid')
    if(isValid){
      let result = fetchLocalUserDetails();
      console.log('selectedImage,',selectedImage)
      console.log('civilitydropdown,',civilitydropdown)
      console.log('organizationFirstname',organizationFirstname)
      console.log('countryValue',countryValue)
      console.log('languageValue',languageValue)
      console.log('utcValue',utcValue)
      console.log('organizationname',organizationname)

      await WorkspaceUpdateService (result?.userID,civilitydropdown,selectedImage,organizationFirstname,countryValue,languageValue,utcValue,organizationname)
      .then(
        (result:any) => {
          if (result?.error) {
            console.log(result);
            addToast(result?.error, 'error');
          } else if (result?.result == 'done') {
            addToast('Organisation créée avec succès', 'success');
            localStorage.setItem('organizationData', JSON.stringify(result));
            console.log('OrganizationSettingsUpdate')
          }
        },
        (error => {
          console.log("API_ERROR", error);
        })
      );

    }
  }

  const countryArray = Object.entries(countries).map(([country]) => ({
    'label': country,
    'value': country,
  }));

  return (
    <div className="workspace-container">
      <SettingsContainerHeader title="Mon compte" subtitle='Gérez votre compte' />
      <div className="workspace-settings-contant">
        <div className="workspace-imgSec-main">
          {errorMessage && !selectedImage &&
            <div className="error-message">
              <span className="icon error-logo-workspace">error_outline</span>
              <div className="workspace-error-message">{errorMessage}</div>
            </div>
          }
          <div className="workspace-imgSec">
            <div className="workspace-logo">
              {selectedImage ? (
                <img className="Uploaded-logo-workspace" src={selectedImage} alt="logo" />
              ) : (
                <img className="login-logo-workspace" src="/photos/icon-logo.svg" alt="logo" />
              )}
              <span className="icon workspace-edit-icon" onClick={handleEditClick}>
                <span className="icon new-edit-logo-workspace">edit</span>
              </span>
              <input type="file" className="file-upload" onChange={handleFileUpload} accept="image/*" required />
            </div>
            <div className="Imgrestriction-workspace workspace-Img-desktop">
              <p className="Img-title">Formats autorisés : <span>.png et .svg</span></p>
              <p className="Img-title">Taille maximale autorisée : <span>2 Mo</span></p>
              <p className="Img-title">Dimensions idéales de l’image : <span>100px * 100 px</span> </p>
            </div>
            <div className="Imgrestriction-workspace workspace-Img-mobile">
              <p className="Img-title">Formats : <span>.png et .svg</span></p>
              <p className="Img-title">maximum : <span>2 Mo</span></p>
              <p className="Img-title">Dimension : <span>100px * 100 px</span> </p>
            </div>
          </div>
        </div>
        <div className="workspace-personal-detials">
          <Dropdown
            value={civilitydropdown}
            size="md"
            elementSize={36}
            elements={civility}
            setValue={(e) => { setCivilitydropdown(e); setCivilitydropdownErr('') }}
            label="Civilité"
            listTopPos="105%"
            listBottomPos=""
            listLeftPos=""
            listRightPos=""
            errorMessage={civilitydropdownErr}
            args={{
              placeholder: 'Sélectionnez votre civilité', required: true,
            }}
          />
          <Input size='md' label="Prénom" args={{
             value:organizationFirstname,
            placeholder: 'Entrez votre prénom', required: true,
            onChange: (e) => {
              setorganizationFirstname(e.target.value);
              setorganizationFirstnameErr('');
              if (e.target.value == '') {
                setorganizationFirstnameErr("Ce champ est requis")
              }
            }
          }} errorMessage={organizationFirstnameErr} />
          <Input size='md' label="Nom" args={{
            placeholder: 'Entrez votre nom', required: true,
            value:organizationname,
            onChange: (e) => {
              setorganizationname(e.target.value);
              setorganizationnameErr('');
              if (e.target.value == '') {
                setorganizationnameErr("Ce champ est requis")
              }
            }
          }} errorMessage={organizationnameErr} />
        </div>
        <div className="workspace-personal-sub-detials">
          <Dropdown
            value={countryValue}
            size="md"
            elementSize={36}
            elements={countryArray}
            setValue={(e) => { setcountryValue(e) }}
            label="Pays/Région"
            listTopPos="105%"
            listBottomPos=""
            listLeftPos=""
            listRightPos=""
          />

          <Dropdown
            value={languageValue}
            setValue={(e) => { setlanguageValue(e) }}
            size="md"
            elementSize={36}
            elements={language}
            label="Langue"
            listTopPos="105%"
            listBottomPos=""
            listLeftPos=""
            listRightPos=""
            args={{value:languageValue}}
          />

          <Dropdown
            value={utcValue}
            setValue={(e) => { setutcValue(e) }}
            size="md"
            elementSize={36}
            elements={TimeZone}
            label="Fuseau horaire"
            listTopPos="105%"
            listBottomPos=""
            listLeftPos=""
            listRightPos=""
          />
        </div>
        <div className="workspace-submit-button">
          <Button size="md" label="Enregistrer" styling="solid" color="primary" args={{ onClick: (e) => { WorkspaceSubmit() } }} />
        </div>
      </div>
    </div>
  );
}
