import { useEffect, useRef, useState } from "react";
import "../../app-params/param-app.css"
import React from 'react';
import { Dropdown, Input, Modal, Tabs, ToggleButton, addToast, Button, CheckboxLabel, ProfileAvatar ,MobileViewVerification} from 'kls-ui';
import { ModalDelete } from 'kls-ui/modal/ModalDelete';
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";
import { Model } from "kls-commons/types/application-model";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { fetchKlsResource, getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { AdvancedDropDown } from "kls-ui/advancedDropDown/AdvancedDropDown";
import { UsersTable, UsersInvitationsTable, UsersDemandesTable } from 'kls-ui/table/table';
import { SearchInput } from "kls-ui/data entry/search-input";
import { Pagination } from "kls-ui/paginationKls/pagination";
import { SettingsContainerHeader } from "../../settings/settings";
import {UsersCard} from './users-card';
interface Invitation {
  num: number,
  email: string,
  souche: string,
  role: string,
  dateInvitation: string,

}
export function Users(props: any) {
  let [viewDelete, setViewDelete] = useState<any>()
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [userNameDeleted, setUserNameDeleted] = useState('');
  const [searchSouche, setSearchSouche] = useState(false);
  const [emailUser, setEmailUser] = useState('');
  const [helpText, setHelpText] = useState('');
  const [activeTab, setActiveTab] = useState<string>('tab1');
  const [activeTabEdit, setActiveTabEdit] = useState<string>('general');
  const { moduleName } = useParams() as any;
  const [directories, setDirectories] = useState<string[]>([]);
  const [appObj, setAppObj] = useState<WorkingDirTree>();
  const [direc, setDirec] = useState<string[]>([]);
  const [selectedSouche, setSelectedSouche] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [mesProcess, setMesProcess] = useState<string[]>([]);
  const [filter, setFilter] = useState<string>("");
  const itemsPerPageUsers: number = 6;
  const itemsPerPageInvitations: number = 5;
  const itemsPerPageDemandes: number = 5;
  const [codeInputs, setCodeInputs] = useState<string[]>(["", "", "", "", "", ""]);
  const [allInputsFilled, setAllInputsFilled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageValidation, setErrorMessageValidation] = useState<string>('');
  const [currentInputIndex, setCurrentInputIndex] = useState<number>(0);
  const [showFilter, setShowFilter] = useState(false);
  const [filterUsers, setFilterUsers] = useState<any>('Status');
  const [isInputModified, setIsInputModified] = useState(false);
  const [activated, setActivated] = useState(false);
  const [errorExistingUser, setErrorExistingUser] = useState(false);
  const [checker, setchecker] = useState<string[]>([])
  const [listView, setlistView] = useState(false)
  const [viewType, setviewType] = useState('list');
  const [requestAction, setrequestAction] = useState(false)
  const [allChecked, setallChecked] = useState(false)
  const [mobileMoreActions, setmobileMoreActions] = useState(false)
  const [aboutData, setAboutData] = useState<any>({
    creationDate: '05/01/2024 22:15',
    lastModification: '10/01/2024 08:44',
    lastConnexion: "10/01/2024 08:44"
  });
  const initialUsersData = [
    {
      num: 1,
      profile: "https://s3-alpha-sig.figma.com/img/337c/3f94/d636b7627d5bc750143193b24bd71cdc?Expires=1714348800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QuemNFLdpHXKzJVb162cSF3RYrxt5TeiZVqCDRJ05kXUGZ5XXOKLD5Qvi9wF1IghB0JkDJjzyhAMm8DNg-F7QN-7cUwpW6-3TZI6XxeXHmEVv-p3Yt3L1I2edo-41~7U8MmX2oqjHucXpOVV8PfL1uiElPqyvPCs~70TQ-~GqLlaaB54ZxRlZ8Og4aUaJfJMH8pedYRIBMej-ZKmVLQhNLdDQXFpzPxlaNJ0SEt3u2rqzGInMu2bO7Vn0TyA5ee8EL-RrRYw-Tarw2IM~VQkpUWYq~WoZVe3rbccecJHxGrYvAMBMrbHSjPQYUtN5~VKNWrcpBmXh2rh9VoHUeoh9A__",
      nom: 'Jalal Alem',
      email: 'a.jalal@ram.co.ma',
      role: 'Proprietaire',
      status: "Actif",
      username: "a.jalal@karaz.org"
    }, {
      num: 2,
      nom: 'Hamza Amrani',
      email: 'hamza.amrani@ram.co.ma',
      profile: "",
      role: 'Administrateur',
      status: "Actif",
      username: "hamza.amrani@karaz.org"
    },
    {
      num: 3,
      nom: '',
      email: 'ines.hermouch@ram.co.ma',
      profile: "",
      role: 'Administrateur',
      status: "Demande envoyée",
      username: ""
    },
    {
      num: 4,
      nom: 'Ilham Jabbouri',
      email: 'i.jabbouri@ram.co.ma',
      profile: "",
      role: 'Citizen Developer',
      status: "Actif",
      username: "i.jabbouri@karaz.org"
    },
    {
      num: 5,
      nom: 'Souhail Hamiddi',
      email: 'souhail.hamiddi@ram.co.ma',
      profile: "",
      role: 'Utilisateur',
      status: "Actif",
      username: "souhail.hamiddi@karaz.org"
    },
    {
      num: 6,
      nom: 'Saad Bouzidi',
      email: 'saad.bouzidii@ram.co.ma',
      profile: "",
      role: 'Citizen Developer',
      status: "En attente approbation",
      username: "saad.bouzidi@karaz.org"
    },

  ];
  const [usersData, setUsersData] = useState(initialUsersData);
  const [invitationsData, setInvitationsData] = useState<Invitation[]>([]);
  const handleAllChecked = () => {
    setallChecked(!allChecked)
  }

    useEffect(()=>{
      const PopupVerification = window.innerWidth >=600 ? <ModalDelete
      closeModalDelete={closeModalDelete}
      handleDeleteButtonClick={handleDeleteButtonClick}
      errorMessage={errorMessage}></ModalDelete> : <MobileViewVerification title="Suppression du rôle" subtitle="Gérez votre organisation" label="Modification d’adresse email" labelContent="Veuillez saisir ci-dessous le code à 6 caractères envoyé à votre nouvelle adresse email :" handleSubmit={handleDeleteButtonClick} handleVerificationClose={closeModalDelete} labelbtn="Mettre à jour email" findTitle="Vous ne trouvez pas votre code ?" findSubtitle="RENVOYER LE CODE" />
      setViewDelete(PopupVerification)
    },[])
  
  // const usersInvitationsData = [
  //   {
  //     num: 1,
  //     email: 'ali.asil@example.com',
  //     souche: 'test',
  //     role: 'Administrateur',
  //     dateInvitation: '2024-02-27',
  //   },
  //   {
  //     num: 2,
  //     email: 'sami.alami@example.com',
  //     souche: 'test',
  //     role: 'Utilisateur',
  //     dateInvitation: '2024-02-27',
  //   },

  // ];
  // const initialUsersDemandesData  = [
  //   {
  //     num: 1,
  //     email: 'meriem.kamal@example.com',
  //     souche: 'test',
  //     role: 'Citizen Developer',
  //     dateDemande: '2024-02-27',
  //   },
  //   {
  //     num: 2,
  //     email: 'sami.alami@example.com',
  //     souche: 'test',
  //     role: 'Utilisateur',
  //     dateDemande: '2024-02-27',
  //   },
  //   {
  //     num: 3,
  //     email: 'meriem.kamal@example.com',
  //     souche: 'test',
  //     role: 'Citizen Developer',
  //     dateDemande: '2024-02-27',
  //   },
  //   {
  //     num: 4,
  //     email: 'sami.alami@example.com',
  //     souche: 'test',
  //     role: 'Utilisateur',
  //     dateDemande: '2024-02-27',
  //   }, {
  //     num: 5,
  //     email: 'meriem.kamal@example.com',
  //     souche: 'test',
  //     role: 'Citizen Developer',
  //     dateDemande: '2024-02-27',
  //   },
  //   {
  //     num: 6,
  //     email: 'sami.alami@example.com',
  //     souche: 'test',
  //     role: 'Utilisateur',
  //     dateDemande: '2024-02-27',
  //   }, {
  //     num: 7,
  //     email: 'meriem.kamal@example.com',
  //     souche: 'test',
  //     role: 'Citizen Developer',
  //     dateDemande: '2024-02-27',
  //   },
  //   {
  //     num: 8,
  //     email: 'sami.alami@example.com',
  //     souche: 'test',
  //     role: 'Utilisateur',
  //     dateDemande: '2024-02-27',
  //   },

  // ];
  // const [usersDemandesData, setUsersDemandesData] = useState(initialUsersDemandesData);
  const filteredUsersData = usersData.filter(r =>
    r && r.nom.toLowerCase().includes(filter.toLowerCase())
  );
  // const filteredUsersInvitationsData = invitationsData.filter(r =>
  //   r && r.email.toLowerCase().includes(filter.toLowerCase())
  // );
  // const filteredUsersDemandesData = usersDemandesData.filter(r =>
  //   r && r.email.toLowerCase().includes(filter.toLowerCase())
  // );

  const [currentPageUsers, setCurrentPageUsers] = useState<number>(1);
  const [currentPageInvitations, setCurrentPageInvitations] = useState<number>(1);
  const [currentPageDemandes, setCurrentPageDemandes] = useState<number>(1);
  const rolesOrganisation = [
    { value: "utilisateur", label: "Utilisateur" },
    { value: "administrateur", label: "Administrateur" },
    { value: "citizenDeveloper", label: "Citizen Developer" }
  ];

  const generateSouches = (label, value) => ({
    icon: 'rule',
    label: label,
    value: value,
    color: '#3B82F6',
  });

  const souches = [
    generateSouches('Souche demandeur citoyen', 'Souche demandeur citoyen'),
    generateSouches('Souche demandeur architecte', 'Souche demandeur architecte'),
    generateSouches('test4', 'test4'),
    generateSouches('test5', 'test5'),
  ];
  const listFilter = [
    {
      label: "Status",
      value: "Status"
    },
    // {
    //   label: "Invitations en attente",
    //   value: "Invitations en attente"
    // },
    // {
    //   label: "Demandes en attente",
    //   value: "Demandes en attente"
    // }
  ];


  const getStartIndexUsers = () => {
    return (currentPageUsers - 1) * itemsPerPageUsers;
  }
  const getStartIndexInvitations = () => {
    return (currentPageInvitations - 1) * itemsPerPageInvitations;
  }
  const getStartIndexDemandes = () => {
    return (currentPageDemandes - 1) * itemsPerPageDemandes;
  }
  const getEndIndexUsers = (table: any) => {
    return Math.min(getStartIndexUsers() + itemsPerPageUsers, table.length);
  }

  const usersFilteredData = filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData))
  // const getEndIndexInvitations = (table: any) => {
  //   return Math.min(getStartIndexInvitations() + itemsPerPageInvitations, table.length);
  // }
  // const getEndIndexDemandes = (table: any) => {
  //   return Math.min(getStartIndexDemandes() + itemsPerPageDemandes, table.length);
  // }
  useEffect(() => {
    const cleanedAndFormattedName = cleanAndFormatNameResource(emailUser);
    setHelpText(`ID : ${cleanedAndFormattedName}`);
  }, [emailUser]);

  useEffect(() => {
    if (checker.length === filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).length) {
      setallChecked(true)
    }
    else {
      setallChecked(false)
    }
  }, [setallChecked, checker])

  useEffect(() => {
    console.log(allChecked, "allcheckedljalkdf")
    const allCheckedData: any[] = [];
    let serviceList = filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData));
    if (allChecked) {
      serviceList.map((s) => {
        allCheckedData.push(s?.nom);
      });
      setchecker(allCheckedData);
    }
    else if (checker.length === serviceList.length) setchecker([])
  }, [setchecker, allChecked])

  useEffect(() => {
    getWorkingDirectorTree(moduleName).then(
      (tree: WorkingDirTree) => {
        setAppObj(tree);
        setDirectories(Object.keys(tree.children || {}));
      }
    )
    listModules().then(
      modules => {
        setDirec(modules);
      }
    )

  }, [moduleName])

  useEffect(() => {
    console.log("appObjet structure", appObj);
    console.log("selected role ikram", selectedRole);
  }, [appObj, selectedRole])

  useEffect(() => {
    setAllInputsFilled(codeInputs.every(input => input !== ''));
  }, [codeInputs]);
  useEffect(() => {
    console.log("currentwhhhh", currentPageUsers, currentPageInvitations, currentPageDemandes);
    setCurrentPageUsers(1);
    setCurrentPageInvitations(1);
    setCurrentPageDemandes(1);
  }, [filter]);
  useEffect(() => {
    if (props.hintType === "userModal") {
      setShowModal(true);
    }
  }, [props.hintType])

  useEffect(() => {
    let count = 0
    filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).map((c) => {
      checker && checker.map((d) => {
        if (c.nom === d) {
          count += 1;
        }
      })
    })
    if (count > 0) setrequestAction(true)
    else setrequestAction(false)
  }, [checker, setrequestAction, filteredUsersData])

  const handleSearchInputChange = (value: string) => {
    setFilter(value);
  };
  const handleSearchSoucheChange = (value: boolean) => {
    setSearchSouche(value);
  };
  const handlePageChangeUsers = (startIndex: number) => {
    setCurrentPageUsers(Math.floor(startIndex / itemsPerPageUsers) + 1);
    if(checker.length === filteredUsersData.slice(((Math.floor(startIndex / itemsPerPageUsers) + 1) - 1) * itemsPerPageUsers , Math.min(((Math.floor(startIndex / itemsPerPageUsers) + 1) - 1) * itemsPerPageUsers + itemsPerPageUsers, usersData.length)).length) setallChecked(true)
    else setallChecked(false)
      console.log("currentwPageUsers", currentPageUsers);
  };

  const handlePageChangeInvitations = (startIndex: number) => {
    setCurrentPageInvitations(Math.floor(startIndex / itemsPerPageInvitations) + 1);
    console.log("currentwPageInvitations", currentPageInvitations);
  };

  const handlePageChangeDemandes = (startIndex: number) => {
    setCurrentPageDemandes(Math.floor(startIndex / itemsPerPageDemandes) + 1);
    console.log("currentwPageDemandes", currentPageDemandes);
  };

  const handleSoucheSelect = (selectedSouche: any) => {

    setSelectedSouche(selectedSouche.label);
    setSearchSouche(false);
  };
  const handleNouvelUserClick = () => {
    setShowModal(true);
  };
  const handleRoleOrganisationChange = (event) => {
    const selectedRoleOrg = event.label;
    console.log("selectedRoleOrg", selectedRoleOrg);
    setSelectedRole(selectedRoleOrg);

  };

  const handleEmailUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUser(event.target.value);
    setIsInputModified(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setShowModalEdit(false);
    setActiveTabEdit('general');
    setIsInputModified(false);
    setErrorExistingUser(false);
    setSelectedRole('');
    setEmailUser('');
    setSelectedSouche('');
  };
  const closeModalDelete = () => {
    setShowModalDelete(false);
    setErrorMessage('');

  };
  const handleDeleteButtonClick = (enteredCode: string) => {
    if (enteredCode !== '111111') {
      setErrorMessage("Le code saisi n'est pas valide. Essayez-le à nouveau !");
    } else {
      const updatedRolesData = usersData.filter(role => role.role !== userNameDeleted);
      // setUsersData(updatedRolesData);
      setShowModalDelete(false);
      setErrorMessage('');
      addToast(' Le rôle a été supprimé avec succès', 'success');
    }
  };
  const handleTabsEdit = (tabName: string) => {
    setActiveTabEdit(tabName);
  }
  const handleTabChange = (tabName: string) => {
    if (tabName === 'edit') {
      setShowModalEdit(true);
      setShowModal(true);

    }
    else if (tabName === 'delete') {
      setShowModalEdit(false);
      setShowModalDelete(true);
      
    }
    else if (tabName === 'duplicate') {
      setShowModal(true);

    }
  };
  
  const handleDeleteOnTable = (userDeleted) => {
    setShowModalEdit(false);
    setShowModalDelete(true);
    setUserNameDeleted(userDeleted);
    window.scrollTo({ top: 0 })
      document.body.classList.add("mobile-verify-tab-view")
  }

  useEffect(() => {
    if (!showModalDelete) {
        document.body.classList.remove("mobile-verify-tab-view")
    }
  }, [showModalDelete])

  const userTableChecker = {
    'filteredData': filteredUsersData,
    'setchecker': setchecker
  }

  const handleActivation = () => {
    console.log("activation");
    setActivated(!activated);
  }
  console.log(requestAction)
  const handleCancelRequest = (nom: string) => {
    setUsersData(usersData.filter((c) => c.nom !== nom))
    addToast(" L'invitation a été annulée avec succès", 'success');
  }
  function MoreActions({ actions, closeCallback, style, status }: { actions: any, closeCallback?: Function, style?: React.CSSProperties, status: string }) {
    const moreOptions = useRef<HTMLDivElement>(null);
    const moreOptionsClass = viewType == 'list' ? status === "Actif" ? "user-moreoptions-list-active" : status === "Demande envoyée" ? "user-moreoptions-list-request" : "user-moreoptions-list" : status === "Actif" ? "user-moreoptions-card-active" : status === "Demande envoyée" ? "user-moreoptions-card-request" : "user-moreoptions-card"
    const listObjActions = status === "Actif" || status === "Demande envoyée" ? [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },
      {
        label: status === "Actif" ? 'Désactiver' : status === "Demande envoyée" ? 'Annuler la demande' : '',
        value: status === "Actif" ? 'deactivate' : status === "Demande envoyée" ? 'cancelRequest' : '',
        icon: status === "Actif" ? 'group_off' : status === "Demande envoyée" ? 'do_not_disturb_alt' : '',
        color: '#64748B',
        handleClick: status === "Actif" ? actions?.['deactivate'] : status === "Demande envoyée" ? actions?.['cancelRequest'] : ''
      },
      {
        label: 'Dupliquer',
        value: 'duplicate',
        icon: 'content_copy',
        color: '#64748B',
        handleClick: actions?.['duplicate']
      },
      {
        label: 'Supprimer',
        value: 'delete',
        icon: 'delete_outline',
        color: '#EF4444 ',
        handleClick: actions?.['delete'],
        labelColor: '#DC2626',
      },

    ] : status === "En attente approbation" ? [
      {
        label: 'Editer',
        value: 'edit',
        icon: 'mode_edit_outline',
        color: '#64748B',
        handleClick: actions?.['edit']
      },
      {
        label: 'Approuver',
        value: 'approve',
        icon: 'check_circle',
        color: '#059669',
        labelColor: '#059669',
        handleClick: actions?.['approve']
      },
      {
        label: 'Refuser',
        value: 'refuse',
        icon: 'highlight_off',
        color: '#EF4444',
        labelColor: '#EF4444',
        handleClick: actions?.['refuse']
      },

    ] : []


    return <div ref={moreOptions} className={moreOptionsClass}>
      <AdvancedDropDown data={listObjActions} enableSearch={false}
        light={true}
        handleChange={(item) => { item?.handleClick?.() }}
        size={1}
        selectedValue={''}
        style={style}
        closeWhenClickOutside={closeCallback}
        closeWhenSelect={closeCallback} />
    </div>
  }

  function getColorClass(role) {
    switch (role.toLowerCase()) {
      case 'administrateur':
        return 'color-admin';
      case 'proprietaire':
        return 'color-proprietaire';
      case 'citizen developer':
        return 'color-citizen-developer';
      case 'utilisateur':
        return 'color-utilisateur';
      default:
        return '';
    }
  }

  const getProfileColor = (role) => {
    switch (role.toLowerCase()) {
      case 'administrateur':
        return '#60A5FA';
      case 'proprietaire':
        return 'color-proprietaire';
      case 'citizen developer':
        return '#A78BFA';
      case 'utilisateur':
        return '#FACC15';
      default:
        return 'black';
    }
  }

  function AppTable({ servicesList }) {
    const data = [servicesList.map((s) => {
      const roleClass = `badge ${getColorClass(s.role)}`;
      return ({
        activation: (
          <div>
            <CheckboxLabel Check={checker.includes(s?.nom) ? true : false} handleChangeValue={() => checker.includes(s?.nom) ? setchecker(checker.filter((d) => d !== s?.nom)) : setchecker([...checker, s?.nom])} disabeled={s?.role === "Propriétaire" ? true : false} label={''} />
          </div>
        ),
        profile: s?.profile === "" && s?.nom !== "" ? <span style={{ color: getProfileColor(s?.role) }}>{s?.nom?.toUpperCase().split(" ")[0][0] + s?.nom?.toUpperCase().split(" ")[1][0]}</span> : s?.profile === "" && s?.nom === "" ? <span>{s?.email.toUpperCase().slice(0, 2)}</span> : <img className="user-grid-profile-image" src={s?.profile} alt="avatar" />,
        nom: s?.nom || '',
        email: s?.email || '',
        userName: s?.username === "" ? <span style={{ textAlign: 'center', width: '50%', display: 'block' }}>-</span> : s?.username,
        role: <div className={roleClass}>{s?.role || ''}</div>,
        status: <span style={{ color: s?.status === "Actif" ? '#10B981' : s?.status === "Demande envoyée" ? '#2C61E7' : s?.status === "En attente approbation" ? '#F59E0B' : "black" }}>• {s?.status}</span>,
        dateModification: s?.dateModification || '',
        actions: (
          <MoreActions
            actions={{
              'edit': () => handleEdit(s),
              'delete': () => handleDeleteOnTable(s?.email),
              'duplicate': () => handleTabChange('duplicate'),
              'cancelRequest': () => handleCancelRequest(s?.nom)
            }}
            style={{ cursor: 'pointer', marginTop: '30px' }}
            status={s?.status}
          />
        ),
        checker: checker.includes(s?.nom) ? "yes" : "no"
      })
    }), { selectAll: allChecked, isSelectAll: handleAllChecked }]
    return(<UsersTable data={data} />);
  }
  // function AppTableInvitations({ servicesList }) {
  //   const data = servicesList.map((s) => {
  //     const roleClass = `badge ${getColorClass(s.role)}`;

  //     return {
  //       num: s?.num || '',
  //       email: s?.email || '',
  //       souche: s?.role === 'Utilisateur' ? s?.souche || '' : <span style={{ color: '#94A3B8' }}>Aucune souche</span>,
  //       role: <div className={roleClass}>{s?.role || ''}</div>,
  //       dateInvitation: s?.dateInvitation || '',
  //       actions: <div><Button label="Annuler" color="error" styling="outline" size="xs" leftIcon="close" args={{ onClick: () => handleCancelInvitation(s?.num) }} /></div>
  //     };
  //   });

  //   return <UsersInvitationsTable data={data} />;
  // }
  // const handleAcceptDemande = (num: number) => {

  //   // setDemandesData(demandesData.filter((demande) => demande.num !== num));
  // setUsersDemandesData(  usersDemandesData.filter((demande) =>  demande.num !== num));  
  // const userDemandeObject = usersDemandesData.find((demande) =>  demande.num === num);
  // console.log("userDemandeObject",userDemandeObject);
  // const newUserConfirme = {
  //   num:Number(usersData.length + 1),
  //   nom :'Utilisateur confirme',
  //   email: userDemandeObject?.email||'',
  //   // souche: userDemandeObject?.souche,
  //   role: userDemandeObject?.role||'',
  //   dateModification:new Date().toISOString().split('T')[0],
  //   activation:true
  // }
  // setUsersData([...usersData, newUserConfirme]);
  //   addToast(" La demande a été acceptée avec succès", 'success');
  // }
  // function AppTableDemandes({ servicesList }) {
  //   const data = servicesList.map((s) => {
  //     const roleClass = `badge ${getColorClass(s.role)}`;

  //     return {
  //       num: s?.num || '',
  //       email: s?.email || '',
  //       souche: s?.souche || '',
  //       role: <div className={roleClass}>{s?.role || ''}</div>,
  //       dateDemande: s?.dateDemande || '',
  //       actions: <div style={{ display: 'flex', gap: '10px' }}>
  //         <Button label="Refuser" color="error" styling="outline" size="xs" leftIcon="close" args={{}} />
  //         <Button label="Accepter" color="success" styling="outline" size="xs" leftIcon="done" args={{onClick: () => handleAcceptDemande(s?.num)}} /></div>
  //     };
  //   });

  //   return <UsersDemandesTable data={data} />;
  // }

  const [nom, setNom] = useState<string>('');
  const [prenom, setPrenom] = useState<string>('');
  const handleEdit = (userInfo) => {
    setShowModalEdit(true);
    setShowModal(true);
    setNom(userInfo.nom.split(' ')[0]);
    setPrenom(userInfo.nom.split(' ')[1]);
    setEmailUser(userInfo.email);
    setSelectedRole(userInfo.role);
    setSelectedSouche(userInfo.selectedSouche);
    setActivated(userInfo.activation);

  }
  const handleAddUserInvitationClick = () => {

    // if(emailUser==='tst'){
    //   console.log("emailUser",emailUser);
    //   console.log("invitationsData",invitationsData);
    //   setErrorExistingUser(true);
    //   return;
    // }

    for (let i = 0; i < invitationsData.length; i++) {
      if (invitationsData[i].email === emailUser) {
        setErrorExistingUser(true);
        return;
      }
    }


    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const UserObject = {
      num: invitationsData.length + 1,
      email: emailUser,
      souche: selectedRole === 'Utilisateur' ? selectedSouche : 'Aucune souche',
      role: selectedRole,
      dateInvitation: formattedDate,

    }
    //num: number, email: string, souche: string, role: string, dateInvitation: string
    console.log("UserObject added", UserObject);
    invitationsData.push(UserObject);
    setInvitationsData([...invitationsData]);
    addToast("L'invitation a été envoyée avec succès", 'success');
    console.log("invitationsData added", invitationsData);
    if (props.hintType === "userModal") {
      props.sethintType("groupHint")
    }
    else {
      closeModal();
    }
  }
  const handleEnregistrerClick = () => {

    closeModal();
    setActivated(!activated);

  }

  const UserMobileMoreActions = ({ actions }: { actions: any }) => {

    const [showActions, setShowActions] = useState<boolean>(false);

    return (<div>
      <span className='material-icon' onClick={() => setShowActions(true)}>more_horiz</span>
      {showActions && React.cloneElement(actions, { closeCallback: () => { setShowActions(false) }, style: { top: '0' } })}
    </div>)
  }

  return (
    <div className='roles-empty-container' style={{ padding: '0px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
      {/* div1 */}
      <div className="group-header-settings">
        <SettingsContainerHeader title="Utilisateurs" subtitle="Gérer les utilisateurs de votre organisation" enableAction={true}
          buttonName="Nouvel utilisateur" icon="add" onClick={handleNouvelUserClick} hintType={props.hintType} />
      </div>
      {/* div2 */}
      <div className="table-users-container">
        <div className="user-search-div">
          <span>
            <SearchInput light={true} size={3} value={filter} setValue={handleSearchInputChange} addClearbutton={true} />
          </span>
          <div className="users-search-view">
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span style={{ display: 'flex', alignItems: 'center', color: '#64748B', fontSize: '13px', textWrap: 'nowrap' }}>Filtrer par :</span>
              <div className='sort-name' onClick={() => { setShowFilter(!showFilter) }}>
                <span>{listFilter.find((f: any) => f?.value === filterUsers)?.label}</span>
                <div className='sort-expand-more' >
                  <span className='material-icon' >expand_more</span>
                  <div style={{position: 'absolute', top: '25px', right: '0'}}>
                    {showFilter &&
                      <AdvancedDropDown data={listFilter} enableSearch={false}
                        light={true}
                        handleChange={(item) => { setFilterUsers(item.value) }}
                        size={1}
                        selectedValue={filterUsers.value}
                        style={{ top: '25px', minWidth: '100px', right: '0px' }}
                        closeWhenSelect={() => { setShowFilter(false) }}
                        closeWhenClickOutside={() => { setShowFilter(false) }} />
                    }
                  </div>
                </div>
              </div>
              <div style={{ width: '1px', height: '24px', backgroundColor: '#E2E8F0' }}></div>
            </div>
            <div className="users-change-view">
              <div className="user-grid-view-icon" style={{ cursor: 'default', backgroundColor: viewType == 'grid' ? '#F8FAFC' : 'white' }} onClick={() => { setviewType('grid'); }}><span className="icon" style={{ color: viewType == 'grid' ? '#2563EB' : '#64748B' }}>grid_view</span></div>
              <div className="user-list-icon" style={{ cursor: 'default', backgroundColor: viewType == 'list' ? '#F8FAFC' : 'white' }} onClick={() => { setviewType('list'); }}><span className="icon" style={{ color: viewType == 'list' ? '#2563EB' : '#64748B', backgroundColor: viewType == 'list' ? '#F8FAFC' : 'white' }}>format_list_bulleted</span></div>
            </div>
          </div>
        </div>
      </div>
      {/* optional div */}
      {requestAction && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '14px' }}>
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#2563EB' }}>
          <span className="icon">file_download</span>
          <span>Exporter l’utilisateur</span>
        </div>
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#DC2626' }}>
          <span className="icon">delete</span>
          <span>Supprimer l’utilisateur</span>
        </div>
      </div>}
      {/*div 3*/}

      {viewType == 'grid' ? <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
        <div className="user-cards">
          {
            filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).map((c) => {
              return (<UsersCard actions={<MoreActions
                actions={{
                  'edit': () => handleEdit(c),
                  'delete': () => handleDeleteOnTable(c?.email),
                  'duplicate': () => handleTabChange('duplicate'),
                  'cancelRequest': () => handleCancelRequest(c?.nom)
                }}
                style={{ cursor: 'pointer', marginTop: '30px' }}
                status={c.status}
              />} avatarInital={c.nom} image={c.profile} type={'organization'} role={c.role} email={c.email} viewType={viewType} setviewType={setviewType} />)
            })
          }
        </div>
        <div style={{ height: '50px' }}>
          <Pagination
            light={true}
            totalItems={filteredUsersData.length}
            itemsPerPage={itemsPerPageUsers}
            maxPage={Math.ceil(filteredUsersData.length / itemsPerPageUsers)}
            handlePageChange={handlePageChangeUsers}
          />
        </div></div> : viewType === 'list' ?
         <><div className="users-table-data-main">
          <div className="users-table-data-scroll"><AppTable servicesList={filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData))} /></div>
          {filteredUsersData.slice(getStartIndexUsers(), getEndIndexUsers(usersData)).map((c) => {
            return (
              <div className="users-table-mobile">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <UserMobileMoreActions actions={<MoreActions
                    actions={{
                      'edit': () => handleEdit(c),
                      'delete': () => handleDeleteOnTable(c?.email),
                      'duplicate': () => handleTabChange('duplicate'),
                      'cancelRequest': () => handleCancelRequest(c?.nom)
                    }}
                    style={{ cursor: 'pointer', marginTop: '30px' }}
                    status={c.status}
                  />} />
                </div>
                <div className="users-list-mobile">
                  <div style={{ width: '20%' }}>{c.profile !== "" ? <img className="user-list-profile-mobile-image" src={c.profile} alt="avatar" /> : <div style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', boxShadow: '0px 4px 6px -4px #0000001A, 0px 10px 15px -3px #0000001A', color: getProfileColor(c.role) }}>{c.nom !== "" ? c.nom.split(" ")[0][0].toUpperCase() + c.nom.split(" ")[1][0].toUpperCase() : c.email.substring(0, 2).toUpperCase()}</div>}</div>
                  <div style={{ width: '45%' }}>
                    <div style={{ fontSize: '16px', fontWeight: '600', paddingLeft: c.nom !== "" ? '0' : '30px', display: 'flex', alignItems: 'center' }}>{c.nom !== "" ? c.nom : '-'}</div>
                    <div style={{ fontSize: '12px', color: '#636363' }}>{c.username}</div>
                  </div>
                  <div>
                    <div className={`badge ${getColorClass(c.role)}`} style={{ height: 'max-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{c.role}</div>
                  </div>
                </div>
              </div>
            )
          })}

        </div>
          <div style={{ height: '50px' }}>
            <Pagination
              light={true}
              totalItems={filteredUsersData.length}
              itemsPerPage={itemsPerPageUsers}
              maxPage={Math.ceil(filteredUsersData.length / itemsPerPageUsers)}
              handlePageChange={handlePageChangeUsers}
            />
          </div></> : <></>
      }
      {showModal && (
        <Modal
          icon=""
          leftBtnArgs={{
            args: { onClick: closeModal },
            color: 'neutral',
            label: 'Annuler',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            args: { onClick: showModalEdit ? handleEnregistrerClick : handleAddUserInvitationClick },
            color: 'primary',
            label: { 'Ajouter': 'Ajouter', 'Enregistrer': 'Enregistrer' }[showModalEdit ? 'Enregistrer' : 'Ajouter'],
            size: 'sm',
            styling: 'solid',

          }}
          title={showModalEdit ? "Détails de l'utilisateur" : "Nouvel utilisateur"}
          onClose={closeModal}
          bodyStyle={{ padding: '20px' }}
        >
          {showModalEdit && (
            <div className='tabs-roles-edit'>
              <Tabs current={'general'}
                align="horizontal"
                defSelected="general"
                icon={[]}
                iconAndName={[]}
                icons={[]}
                item={[]}
                onSelect={handleTabsEdit}
                size="small"
                tabs={[
                  {
                    label: 'Général',
                    name: 'general'
                  },
                  {
                    label: 'À propos',
                    name: 'propos'
                  },

                ]}
              /></div>)}
          {activeTabEdit === "general" ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              {showModalEdit &&
                <div style={{ display: 'grid', marginTop: '20px' }}>

                  <div style={{ float: 'right', display: 'inline-grid', justifyItems: 'right' }}><ToggleButton label="Activer" handleToggle={handleActivation} ischecked={activated} ></ToggleButton></div>
                  <div style={{ display: 'flex', gap: '205px' }}>
                    <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Prénom</span>
                    <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Nom</span>
                  </div>
                  <div style={{ display: 'flex', gap: '20px' }}>
                    <Input
                      size='sm'
                      args={{
                        onChange: handleEmailUserChange,
                        value: nom,
                        disabled: true
                      }}
                    />
                    <Input
                      size='sm'
                      args={{
                        onChange: handleEmailUserChange,
                        value: prenom,
                        disabled: true
                      }}
                    />
                  </div>
                </div>

              }

              <div>
                {errorExistingUser && <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='error-div' style={{ width: '100%', marginBottom: '20px' }}>
                    <span className="material-icon" style={{ color: '#DC2626' }}>error_outline</span>
                    <span className='error-msg'>Un utilisateur avec l'adresse e-mail fournie est déjà ajouté.</span>
                  </div>
                </div>}
                <span className="add-name-modal-text">Email</span>
                <Input
                  size='sm'
                  args={{
                    placeholder: 'Saisissez l’adresse email d’utilisateur',
                    onChange: handleEmailUserChange,
                    value: emailUser,
                    disabled: showModalEdit
                  }}
                  errorMessage={isInputModified && emailUser.trim() === '' ? 'Ce champ est requis' : ''}
                />
              </div>
              <hr className="horizontal-line" />
              <div className="role-organisation-dropdown">
                <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Role dans l'organisation</span>
                <Dropdown
                  args={{
                    style: { position: 'relative' },
                  }}
                  label=""
                  klsCompatible={true}
                  data={rolesOrganisation}
                  size="xs"
                  elementSize={36}
                  value={selectedRole}
                  onSelect={handleRoleOrganisationChange}

                />

              </div>
              {selectedRole === "Utilisateur" && (
                <div>
                  <span style={{ color: '#1E293B', fontFamily: 'Inter', fontWeight: 500, fontSize: '13px' }}>Souche</span>
                  <SearchInput light={true} size={3} value={selectedSouche} setValue={handleSearchSoucheChange} placeholder="Choisissez une souche" onClick={() => { setSearchSouche(!searchSouche) }}
                  />
                  {searchSouche && (
                    <div className="drop-souche">
                      <AdvancedDropDown
                        handleChange={handleSoucheSelect}
                        data={souches}
                        enableSearch
                        selectedValue={selectedSouche}
                        size={3}
                        maxItemsPerPage={2}
                        style={{ width: '100%' }}
                        closeWhenClickOutside={() => { setSearchSouche(false) }}
                      /></div>
                  )}
                </div>)}
            </div>
          ) : (activeTabEdit === "propos" &&
            <div className="about-tabs-informations">

              <div className='flex-space'><span className='title-propos'>Date d'ajout:</span> <span className='descp-propos'>{aboutData.creationDate}</span></div>
              <div className='flex-space'><span className='title-propos'>Dernière modification: </span><span className='descp-propos'>{aboutData.lastModification}</span></div>
              <hr style={{ border: 'none', borderTop: '1px solid #cdd4e0', margin: '10px 0' }} />
              <div className='flex-space'><span className='title-propos'>Dernière connexion:</span> <span className='descp-propos'>{aboutData.lastConnexion}</span></div>
            </div>)}

          <div />
        </Modal>
      )}
      {showModalDelete && 
        viewDelete
      }
    </div>
  );
}

