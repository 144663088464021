import { Hint, ProfileHeader } from "kls-ui"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Header } from "./layout/header"
import { NavBar } from "./App"
import { currentUser, signout, storeLocalUserProfile } from "kls-commons/service/userService"

export function CommonHeader(props: any) {
    const history = useHistory()
    const [position, setposition] = useState("righttop")
    const [orgPosition, setOrgPosition] = useState("left")
    useEffect(() => {
        const updatePosition = () => {
            if (window.innerWidth <= 700) {
                setposition('topright')
                setOrgPosition('bottomright')
            }
            else {
                setposition("righttop")
                setOrgPosition('left')
            }
        }
        updatePosition();
        window.addEventListener('resize', updatePosition)
        return () => {
            window.removeEventListener('resize', updatePosition)
        }
    }, [])
    return (
        <>
            <Header>
                <NavBar selectedItem={props.selectedItem} />

                {/* <StepsBar themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} sethintType={props?.sethintType} /> */}
                <div className="profile-notifications">
                    <div className='notificaitons'>
                        <span className='icon notifications-icon' style={{ fontSize: '24px' }}>notifications</span>
                    </div>
                    <ProfileHeader profileName={currentUser().fullName} profileImage={currentUser().avatarUrl ?? ""} profileEmail={currentUser().id ?? ""} klsVersion="KLS version 0.7" settingsHandler={() => document.location = "/#/settings"} logoutHandler={() => { storeLocalUserProfile(null); signout(); document.location = "/"; }} themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} />
                </div>
            </Header>
            {/* <StepsBarMobile themeHandler={props?.themeHandler} setthemeHandler={props?.setthemeHandler} hintType={props?.hintType} sethintType={props?.sethintType} /> */}
            {props.themeHandler && props.hintType === "theme" ? <div className="common-hint-main">
                <div className="theme-setting-view" onClick={() => document.location = "/#/settings"}>
                    <span className="icon">settings</span>
                    <span>Paramètres</span>
                </div>
                <div className="theme-hint">
                    <Hint
                        title="Choisissez le thème de votre espace de travail"
                        content="Vous pouvez choisir entre le mode sombre et le mode clair en utilisant l'icône en forme de soleil ou de lune située en haut à droite."
                        button="Suivant"
                        currentCount={1}
                        totalCount={9}
                        position={position}
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.setthemeHandler(!props.themeHandler); history.push('/appearance') }}
                    />
                </div>
            </div> : props.themeHandler && props.hintType === "orgData" ? <><div className="common-hint-main orgData-hint-main">
                <div className="orgData-setting-view">
                    <div style={{ padding: "6px 16px" }}>Editer</div>
                    <div style={{ padding: "6px 16px", background: "#F1F5F9" }}>Paramètres</div>
                    <div style={{ padding: "6px 16px" }}>Monitoring</div>
                </div>
                <div className="orgData-hint">
                    <Hint
                        title="Paramètres de l’organisation"
                        content='Vous pouvez compléter les informations de votre organisation en cliquant sur "Paramètres".'
                        button="Suivant"
                        currentCount={2}
                        totalCount={9}
                        position={orgPosition}
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.setthemeHandler(!props.themeHandler); history.push('/organisation-settings/1') }}
                    />
                </div>
            </div></> : props.themeHandler && props.hintType === "users" ? <div className="common-hint-main orgData-hint-main">
                <div className="orgData-setting-view">
                    <div style={{ padding: "6px 16px" }}>Editer</div>
                    <div style={{ padding: "6px 16px", background: "#F1F5F9" }}>Paramètres</div>
                    <div style={{ padding: "6px 16px" }}>Monitoring</div>
                </div>
                <div className="orgData-hint orgData-user-hint-view">
                    <Hint
                        title="Ajouter des collaborateurs"
                        content='Vous pouvez ajouter des collaborateurs en cliquant sur "Paramètres", puis sur "Utilisateurs".'
                        button="Suivant"
                        currentCount={3}
                        totalCount={9}
                        position={orgPosition}
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { history.push('/users', { message: "true" }); props.sethintType("userSetting") }}
                    />
                </div>
            </div> : props.themeHandler && props.hintType === "application" ? <div className="common-hint-main application-hint-main">
                <div className="application-hint">
                    <Hint
                        title="Editer une application"
                        content='Vous pouvez éditer cette application à Vous pouvez regarder le tutoriel pour les étapes complètes.'
                        button="Terminer"
                        currentCount={9}
                        totalCount={9}
                        position="left"
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                    />
                </div>
            </div> : props.themeHandler && props.hintType === "userSetting" ? <div className="common-hint-main">
                <div className="user-setting-hint">
                    <Hint
                        title="Ajouter des collaborateurs"
                        content='Cliquez sur le bouton "Nouvel utilisateur" pour ouvrir une fenêtre contextuelle et remplir les informations.'
                        button="Suivant"
                        currentCount={4}
                        totalCount={9}
                        position="right"
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.sethintType("userModal"); props.setthemeHandler(false) }}
                    />
                </div>
                <div className="users-setting-view ">
                    <span className="icon">people_alt</span>
                    <span>Utilisateurs</span>
                </div>
            </div> : props.themeHandler && props.hintType === "groupHint" ? <div className="common-hint-main">
                <div className="user-setting-hint">
                    <Hint
                        title="Créer des groupes"
                        content={`Pour créer des "groupes", veuillez regarder les tutoriels sur la page d'accueil expliquant comment ajouter des profils et ressources.`}
                        button="Suivant"
                        currentCount={5}
                        totalCount={9}
                        position="left"
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.sethintType("addgroup") }}
                    />
                </div>
            </div> : props.themeHandler && props.hintType === "addGroup" ? <div className="common-hint-main">
                <div className="user-setting-hint">
                    <Hint
                        title="Ajouter des groupes"
                        content={`Cliquez sur le bouton "Nouveau groupe" pour ouvrir une fenêtre contextuelle et remplir les informations.`}
                        button="Suivant"
                        currentCount={6}
                        totalCount={9}
                        position="right"
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.sethintType("groupModal"); props.setthemeHandler(false) }}
                    />
                </div>
            </div> : props.themeHandler && props.hintType === "soucheHint" ? <div className="common-hint-main">
                <div className="user-setting-hint">
                    <Hint
                        title="Créer des souches"
                        content={`Pour créer des "Souches", veuillez regarder les tutoriels sur la page d'accueil expliquant comment ajouter des utilisateurs.`}
                        button="Suivant"
                        currentCount={7}
                        totalCount={9}
                        position="left"
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.sethintType("addSouche") }}
                    />
                </div>
            </div> : props.themeHandler && props.hintType === "addSouche" ? <div className="common-hint-main">
                <div className="user-setting-hint">
                    <Hint
                        title="Ajouter des souches"
                        content={`Cliquez sur le bouton "Nouvelle souche" pour ouvrir une fenêtre contextuelle et remplir les informations.`}
                        button="Suivant"
                        currentCount={8}
                        totalCount={9}
                        position="left"
                        close={props.themeHandler}
                        onClose={() => props.setthemeHandler(!props.themeHandler)}
                        onClick={() => { props.sethintType("soucheModal") }}
                    />
                </div>
            </div> : <></>
            }
        </>
    )
}