export const countries = {
    "Afghanistan": {
        "localite": [
            "Badakhshan",
            "Badghis",
            "Baghlan",
            "Balkh",
            "Bamyan",
            "Daykundi",
            "Farah",
            "Faryab",
            "Ghazni",
            "Ghor",
            "Helmand",
            "Herat",
            "Jowzjan",
            "Kabul",
            "Kandahar",
            "Kapisa",
            "Khost",
            "Kunar",
            "Kunduz",
            "Laghman",
            "Logar",
            "Nangarhar",
            "Nimroz",
            "Nuristan",
            "Paktia",
            "Paktika",
            "Panjshir",
            "Parwan",
            "Samangan",
            "Sar-e Pol",
            "Takhar",
            "Urozgan",
            "Zabul"
        ]
    },
    "Albania": {
        "localite": [
            "Berat",
            "Dibër",
            "Durrës",
            "Elbasan",
            "Fier",
            "Gjirokastër",
            "Korçë",
            "Kukës",
            "Lezhë",
            "Shkodër",
            "Tiranë",
            "Vlorë"
        ]
    },
    "Algeria": {
        "localite": [
            "Adrar",
            "Aïn Defla",
            "Aïn Témouchent",
            "Algiers",
            "Annaba",
            "Batna",
            "Béchar",
            "Béjaïa",
            "Biskra",
            "Blida",
            "Bordj Bou Arréridj",
            "Bouira",
            "Boumerdès",
            "Chlef",
            "Constantine",
            "Djelfa",
            "El Bayadh",
            "El Oued",
            "El Tarf",
            "Ghardaïa",
            "Guelma",
            "Illizi",
            "Jijel",
            "Khenchela",
            "Laghouat",
            "M'Sila",
            "Mascara",
            "Médéa",
            "Mila",
            "Mostaganem",
            "Naama",
            "Oran",
            "Ouargla",
            "Oum El Bouaghi",
            "Relizane",
            "Saïda",
            "Sétif",
            "Sidi Bel Abbès",
            "Skikda",
            "Souk Ahras",
            "Tamanghasset",
            "Tébessa",
            "Tiaret",
            "Tindouf",
            "Tipaza",
            "Tissemsilt",
            "Tizi Ouzou",
            "Tlemcen"
        ]
    },
    "Andorra": {
        "localite": [
            "Andorra la Vella",
            "Canillo",
            "Encamp",
            "Escaldes-Engordany",
            "La Massana",
            "Ordino",
            "Sant Julià de Lòria"
        ]
    },
    "Angola": {
        "localite": [
            "Bengo",
            "Benguela",
            "Bié",
            "Cabinda",
            "Cuando Cubango",
            "Cuanza Norte",
            "Cuanza Sul",
            "Cunene",
            "Huambo",
            "Huíla",
            "Luanda",
            "Lunda Norte",
            "Lunda Sul",
            "Malanje",
            "Moxico",
            "Namibe",
            "Uíge",
            "Zaire"
        ]
    },
    "Antigua and Barbuda": {
        "localite": [
            "Saint George",
            "Saint John",
            "Saint Mary",
            "Saint Paul",
            "Saint Peter",
            "Saint Philip"
        ]
    },
    "Argentina": {
        "localite": [
            "Buenos Aires",
            "Catamarca",
            "Chaco",
            "Chubut",
            "Córdoba",
            "Corrientes",
            "Entre Ríos",
            "Formosa",
            "Jujuy",
            "La Pampa",
            "La Rioja",
            "Mendoza",
            "Misiones",
            "Neuquén",
            "Río Negro",
            "Salta",
            "San Juan",
            "San Luis",
            "Santa Cruz",
            "Santa Fe",
            "Santiago del Estero",
            "Tierra del Fuego, Antarctica, and South Atlantic Islands",
            "Tucumán"
        ]
    },
    "Armenia": {
        "localite": [
            "Aragatsotn",
            "Ararat",
            "Armavir",
            "Gegharkunik",
            "Kotayk",
            "Lori",
            "Shirak",
            "Syunik",
            "Tavush",
            "Vayots Dzor",
            "Yerevan"
        ]
    },
    "Australia": {
        "localite": [
            "New South Wales",
            "Queensland",
            "South Australia",
            "Tasmania",
            "Victoria",
            "Western Australia",
            "Australian Capital Territory",
            "Northern Territory"
        ]
    },
    "Austria": {
        "localite": [
            "Burgenland",
            "Carinthia",
            "Lower Austria",
            "Upper Austria",
            "Salzburg",
            "Styria",
            "Tyrol",
            "Vorarlberg",
            "Vienna"
        ]
    },
    "Azerbaijan": {
        "localite": [
            "Absheron",
            "Agdam",
            "Agdash",
            "Agjabadi",
            "Agstafa",
            "Agsu",
            "Astara",
            "Babek",
            "Baku",
            "Balakan",
            "Barda",
            "Beylagan",
            "Bilasuvar",
            "Dashkasan",
            "Fizuli",
            "Gadabay",
            "Ganja",
            "Goranboy",
            "Goychay",
            "Goygol",
            "Hajigabul",
            "Imishli",
            "Ismailli",
            "Jabrayil",
            "Julfa",
            "Kalbajar",
            "Kangarli",
            "Khachmaz",
            "Khizi",
            "Khojavend",
            "Kurdamir",
            "Lachin",
            "Lankaran",
            "Lerik",
            "Masally",
            "Mingachevir",
            "Naftalan",
            "Nakhchivan Autonomous Republic",
            "Neftchala",
            "Oghuz",
            "Ordubad",
            "Qabala",
            "Qakh",
            "Qazakh",
            "Quba",
            "Qubadli",
            "Qusar",
            "Saatly",
            "Sabirabad",
            "Sadarak",
            "Salyan",
            "Shabran",
            "Shahbuz",
            "Shaki",
            "Shamakhi",
            "Shamkir",
            "Sharur",
            "Shirvan",
            "Siazan",
            "Sumqayit",
            "Tartar",
            "Tovuz",
            "Ujar",
            "Yardimli",
            "Yevlakh",
            "Zangilan",
            "Zaqatala",
            "Zardab"
        ]
    },
    "Bahamas": {
        "localite": [
            "Abaco Islands",
            "Acklins",
            "Andros",
            "Berry Islands",
            "Bimini",
            "Cat Island",
            "Crooked Island",
            "Eleuthera",
            "Exuma",
            "Grand Bahama",
            "Inagua",
            "Long Island",
            "Mayaguana",
            "New Providence",
            "Ragged Island",
            "Rum Cay",
            "San Salvador"
        ]
    },
    "Bahrain": {
        "localite": [
            "Capital Governorate",
            "Central Governorate",
            "Muharraq Governorate",
            "Northern Governorate",
            "Southern Governorate"
        ]
    },
    "Bangladesh": {
        "localite": [
            "Barisal",
            "Chittagong",
            "Dhaka",
            "Khulna",
            "Mymensingh",
            "Rajshahi",
            "Rangpur",
            "Sylhet"
        ]
    },
    "Barbados": {
        "localite": [
            "Christ Church",
            "Saint Andrew",
            "Saint George",
            "Saint James",
            "Saint John",
            "Saint Joseph",
            "Saint Lucy",
            "Saint Michael",
            "Saint Peter",
            "Saint Philip",
            "Saint Thomas"
        ]
    },
    "Belarus": {
        "localite": [
            "Brest Region",
            "Gomel Region",
            "Grodno Region",
            "Minsk Region",
            "Mogilev Region",
            "Vitebsk Region",
            "Minsk"
        ]
    },
    "Belgium": {
        "localite": [
            "Brussels-Capital Region",
            "Flanders",
            "Wallonia"
        ]
    },
    "Belize": {
        "localite": [
            "Belize",
            "Cayo",
            "Corozal",
            "Orange Walk",
            "Stann Creek",
            "Toledo"
        ]
    },
    "Benin": {
        "localite": [
            "Alibori",
            "Atakora",
            "Atlantique",
            "Borgou",
            "Collines",
            "Donga",
            "Kouffo",
            "Littoral",
            "Mono",
            "Ouémé",
            "Plateau",
            "Zou"
        ]
    },
    "Bhutan": {
        "localite": [
            "Bumthang",
            "Chhukha",
            "Dagana",
            "Gasa",
            "Haa",
            "Lhuntse",
            "Mongar",
            "Paro",
            "Pemagatshel",
            "Punakha",
            "Samdrup Jongkhar",
            "Samtse",
            "Sarpang",
            "Thimphu",
            "Trashigang",
            "Trongsa",
            "Tsirang",
            "Wangdue Phodrang",
            "Zhemgang"
        ]
    },
    "Bolivia": {
        "localite": [
            "Beni",
            "Chuquisaca",
            "Cochabamba",
            "La Paz",
            "Oruro",
            "Pando",
            "Potosí",
            "Santa Cruz",
            "Tarija"
        ]
    },
    "Bosnia and Herzegovina": {
        "localite": [
            "Federation of Bosnia and Herzegovina",
            "Republika Srpska",
            "Brčko District"
        ]
    },
    "Botswana": {
        "localite": [
            "Central",
            "Ghanzi",
            "Kgalagadi",
            "Kgatleng",
            "Kweneng",
            "North-East",
            "North-West",
            "South-East",
            "Southern"
        ]
    },
    "Brazil": {
        "localite": [
            "Acre",
            "Alagoas",
            "Amapá",
            "Amazonas",
            "Bahia",
            "Ceará",
            "Espírito Santo",
            "Federal District",
            "Goiás",
            "Maranhão",
            "Mato Grosso",
            "Mato Grosso do Sul",
            "Minas Gerais",
            "Pará",
            "Paraíba",
            "Paraná",
            "Pernambuco",
            "Piauí",
            "Rio de Janeiro",
            "Rio Grande do Norte",
            "Rio Grande do Sul",
            "Rondônia",
            "Roraima",
            "Santa Catarina",
            "São Paulo",
            "Sergipe",
            "Tocantins"
        ]
    },
    "Brunei": {
        "localite": [
            "Belait",
            "Brunei-Muara",
            "Temburong",
            "Tutong"
        ]
    },
    "Bulgaria": {
        "localite": [
            "Blagoevgrad",
            "Burgas",
            "Dobrich",
            "Gabrovo",
            "Haskovo",
            "Kardzhali",
            "Kyustendil",
            "Lovech",
            "Montana",
            "Pazardzhik",
            "Pernik",
            "Pleven",
            "Plovdiv",
            "Razgrad",
            "Ruse",
            "Shumen",
            "Silistra",
            "Sliven",
            "Smolyan",
            "Sofia City Province",
            "Sofia Province",
            "Stara Zagora",
            "Targovishte",
            "Varna",
            "Veliko Tarnovo",
            "Vidin",
            "Vratsa",
            "Yambol"
        ]
    },
    "Burkina Faso": {
        "localite": [
            "Boucle du Mouhoun",
            "Cascades",
            "Centre",
            "Centre-Est",
            "Centre-Nord",
            "Centre-Ouest",
            "Centre-Sud",
            "Est",
            "Hauts-Bassins",
            "Nord",
            "Plateau-Central",
            "Sahel",
            "Sud-Ouest"
        ]
    },
    "Burundi": {
        "localite": [
            "Bubanza",
            "Bujumbura Mairie",
            "Bujumbura Rural",
            "Bururi",
            "Cankuzo",
            "Cibitoke",
            "Gitega",
            "Karuzi",
            "Kayanza",
            "Kirundo",
            "Makamba",
            "Muramvya",
            "Muyinga",
            "Mwaro",
            "Ngozi",
            "Rutana",
            "Ruyigi"
        ]
    },
    "Cabo Verde": {
        "localite": [
            "Boa Vista",
            "Brava",
            "Maio",
            "Mosteiros",
            "Paul",
            "Porto Novo",
            "Praia",
            "Ribeira Brava",
            "Ribeira Grande",
            "Ribeira Grande de Santiago",
            "Sal",
            "Santa Catarina",
            "Santa Catarina do Fogo",
            "Santa Cruz",
            "São Domingos",
            "São Filipe",
            "São Lourenço dos Órgãos",
            "São Miguel",
            "São Salvador do Mundo",
            "São Vicente",
            "Tarrafal",
            "Tarrafal de São Nicolau"
        ]
    },
    "Cambodia": {
        "localite": [
            "Banteay Meanchey",
            "Battambang",
            "Kampong Cham",
            "Kampong Chhnang",
            "Kampong Speu",
            "Kampong Thom",
            "Kampot",
            "Kandal",
            "Koh Kong",
            "Kratie",
            "Mondulkiri",
            "Oddar Meanchey",
            "Pailin",
            "Phnom Penh",
            "Preah Sihanouk",
            "Preah Vihear",
            "Pursat",
            "Ratanakiri",
            "Siem Reap",
            "Stung Treng",
            "Svay Rieng",
            "Takéo",
            "Tbong Khmum"
        ]
    },
    "Cameroon": {
        "localite": [
            "Adamaoua",
            "Centre",
            "East",
            "Far North",
            "Littoral",
            "North",
            "Northwest",
            "South",
            "Southwest",
            "West"
        ]
    },
    "Canada": {
        "localite": [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Nova Scotia",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Northwest localite",
            "Nunavut",
            "Yukon"
        ]
    },
    "Central African Republic": {
        "localite": [
            "Bamingui-Bangoran",
            "Bangui",
            "Basse-Kotto",
            "Haut-Mbomou",
            "Haute-Kotto",
            "Kémo",
            "Lobaye",
            "Mambéré-Kadéï",
            "Mbomou",
            "Nana-Grébizi",
            "Nana-Mambéré",
            "Ombella-M'Poko",
            "Ouaka",
            "Ouham",
            "Ouham-Pendé",
            "Sangha-Mbaéré",
            "Vakaga"
        ]
    },
    "Chad": {
        "localite": [
            "Bahr el Gazel",
            "Batha",
            "Borkou",
            "Chari-Baguirmi",
            "Ennedi",
            "Ennedi-Est",
            "Guéra",
            "Hadjer-Lamis",
            "Kanem",
            "Lac",
            "Logone Occidental",
            "Logone Oriental",
            "Mandoul",
            "Mayo-Kebbi Est",
            "Mayo-Kebbi Ouest",
            "Moyen-Chari",
            "N'Djamena",
            "Ouaddaï",
            "Salamat",
            "Sila",
            "Tandjilé",
            "Tibesti",
            "Wadi Fira"
        ]
    },
    "Chile": {
        "localite": [
            "Aisén del General Carlos Ibáñez del Campo",
            "Antofagasta",
            "Araucanía",
            "Arica y Parinacota",
            "Atacama",
            "Biobío",
            "Coquimbo",
            "Los Lagos",
            "Los Ríos",
            "Magallanes y de la Antártica Chilena",
            "Maule",
            "Ñuble",
            "O'Higgins",
            "Región Metropolitana de Santiago",
            "Tarapacá",
            "Valparaíso"
        ]
    },
    "China": {
        "localite": [
            "Anhui",
            "Beijing",
            "Chongqing",
            "Fujian",
            "Gansu",
            "Guangdong",
            "Guangxi",
            "Guizhou",
            "Hainan",
            "Hebei",
            "Heilongjiang",
            "Henan",
            "Hubei",
            "Hunan",
            "Inner Mongolia",
            "Jiangsu",
            "Jiangxi",
            "Jilin",
            "Liaoning",
            "Ningxia",
            "Qinghai",
            "Shaanxi",
            "Shandong",
            "Shanghai",
            "Shanxi",
            "Sichuan",
            "Tianjin",
            "Tibet",
            "Xinjiang",
            "Yunnan",
            "Zhejiang",
            "Guangxi",
            "Inner Mongolia",
            "Ningxia",
            "Tibet",
            "Xinjiang",
            "Beijing",
            "Chongqing",
            "Shanghai",
            "Tianjin"
        ]
    },
    "Colombia": {
        "localite": [
            "Amazonas",
            "Antioquia",
            "Arauca",
            "Atlántico",
            "Bolívar",
            "Boyacá",
            "Caldas",
            "Caquetá",
            "Casanare",
            "Cauca",
            "Cesar",
            "Chocó",
            "Córdoba",
            "Cundinamarca",
            "Guainía",
            "Guaviare",
            "Huila",
            "La Guajira",
            "Magdalena",
            "Meta",
            "Nariño",
            "Norte de Santander",
            "Putumayo",
            "Quindío",
            "Risaralda",
            "San Andrés y Providencia",
            "Santander",
            "Sucre",
            "Tolima",
            "Valle del Cauca",
            "Vaupés",
            "Vichada"
        ]
    },
    "Comoros": {
        "localite": [
            "Anjouan",
            "Grande Comore",
            "Mohéli"
        ]
    },
    "Congo (Congo-Brazzaville)": {
        "localite": [
            "Bouenza",
            "Brazzaville",
            "Cuvette",
            "Cuvette-Ouest",
            "Kouilou",
            "Lékoumou",
            "Likouala",
            "Niari",
            "Plateaux",
            "Pointe-Noire",
            "Pool",
            "Sangha"
        ]
    },
    "Costa Rica": {
        "localite": [
            "Alajuela",
            "Cartago",
            "Guanacaste",
            "Heredia",
            "Limón",
            "Puntarenas",
            "San José"
        ]
    },
    "Croatia": {
        "localite": [
            "Bjelovar-Bilogora",
            "Brod-Posavina",
            "Dubrovnik-Neretva",
            "Istria",
            "Karlovac",
            "Koprivnica-Križevci",
            "Krapina-Zagorje",
            "Lika-Senj",
            "Međimurje",
            "Osijek-Baranja",
            "Požega-Slavonia",
            "Primorje-Gorski Kotar",
            "Šibenik-Knin",
            "Sisak-Moslavina",
            "Split-Dalmatia",
            "Varaždin",
            "Virovitica-Podravina",
            "Vukovar-Srijem",
            "Zadar",
            "Zagreb",
            "Zagreb County"
        ]
    },
    "Cuba": {
        "localite": [
            "Artemisa",
            "Camagüey",
            "Ciego de Ávila",
            "Cienfuegos",
            "Granma",
            "Guantánamo",
            "Holguín",
            "Isla de la Juventud",
            "La Habana",
            "Las Tunas",
            "Matanzas",
            "Mayabeque",
            "Pinar del Río",
            "Sancti Spíritus",
            "Santiago de Cuba",
            "Villa Clara"
        ]
    },
    "Cyprus": {
        "localite": [
            "Famagusta",
            "Kyrenia",
            "Larnaca",
            "Limassol",
            "Nicosia",
            "Paphos"
        ]
    },
    "Czechia (Czech Republic)": {
        "localite": [
            "Central Bohemian",
            "Hradec Králové",
            "Karlovy Vary",
            "Liberec",
            "Moravian-Silesian",
            "Olomouc",
            "Pardubice",
            "Plzeň",
            "Prague",
            "South Bohemian",
            "South Moravian",
            "Ústí nad Labem",
            "Vysočina",
            "Zlín"
        ]
    },
    "Democratic Republic of the Congo": {
        "localite": [
            "Bas-Uele",
            "Équateur",
            "Haut-Katanga",
            "Haut-Lomami",
            "Haut-Uele",
            "Ituri",
            "Kasaï",
            "Kasaï-Central",
            "Kasaï-Oriental",
            "Kinshasa",
            "Kongo-Central",
            "Kwango",
            "Kwilu",
            "Lomami",
            "Lualaba",
            "Mai-Ndombe",
            "Maniema",
            "Mongala",
            "Nord-Ubangi",
            "Nord-Kivu",
            "Sankuru",
            "Sud-Ubangi",
            "Sud-Kivu",
            "Tanganyika",
            "Tshopo",
            "Tshuapa"
        ]
    },
    "Denmark": {
        "localite": [
            "Capital Region of Denmark",
            "Central Denmark Region",
            "North Denmark Region",
            "Region of Southern Denmark",
            "Zealand Region"
        ]
    },
    "Djibouti": {
        "localite": [
            "Ali Sabieh",
            "Arta",
            "Dikhil",
            "Djibouti",
            "Obock",
            "Tadjourah"
        ]
    },
    "Dominica": {
        "localite": [
            "Saint Andrew",
            "Saint David",
            "Saint George",
            "Saint John",
            "Saint Joseph",
            "Saint Luke",
            "Saint Mark",
            "Saint Patrick"
        ]
    },
    "Dominican Republic": {
        "localite": [
            "Azua",
            "Baoruco",
            "Barahona",
            "Dajabón",
            "Duarte",
            "Elías Piña",
            "El Seibo",
            "Espaillat",
            "Hato Mayor",
            "Hermanas Mirabal",
            "Independencia",
            "La Altagracia",
            "La Romana",
            "La Vega",
            "María Trinidad Sánchez",
            "Monseñor Nouel",
            "Monte Cristi",
            "Monte Plata",
            "Pedernales",
            "Peravia",
            "Puerto Plata",
            "Samaná",
            "San Cristóbal",
            "San José de Ocoa",
            "San Juan",
            "San Pedro de Macorís",
            "Sánchez Ramírez",
            "Santiago",
            "Santiago Rodríguez",
            "Santo Domingo",
            "Valverde"
        ]
    },
    "East Timor (Timor-Leste)": {
        "localite": [
            "Aileu",
            "Ainaro",
            "Baucau",
            "Bobonaro",
            "Cova Lima",
            "Dili",
            "Ermera",
            "Lautém",
            "Liquiçá",
            "Manatuto",
            "Manufahi",
            "Oecusse",
            "Viqueque"
        ]
    },
    "Ecuador": {
        "localite": [
            "Azuay",
            "Bolívar",
            "Cañar",
            "Carchi",
            "Chimborazo",
            "Cotopaxi",
            "El Oro",
            "Esmeraldas",
            "Galápagos",
            "Guayas",
            "Imbabura",
            "Loja",
            "Los Ríos",
            "Manabí",
            "Morona-Santiago",
            "Napo",
            "Orellana",
            "Pastaza",
            "Pichincha",
            "Santa Elena",
            "Santo Domingo de los Tsáchilas",
            "Sucumbíos",
            "Tungurahua",
            "Zamora-Chinchipe"
        ]
    },
    "Egypt": {
        "localite": [
            "Ad Daqahliyah",
            "Al Bahr al Ahmar",
            "Al Buhayrah",
            "Al Fayyum",
            "Al Gharbiyah",
            "Al Iskandariyah",
            "Al Isma'iliyah",
            "Al Jizah",
            "Al Minufiyah",
            "Al Minya",
            "Al Qahirah",
            "Al Qalyubiyah",
            "Al Sharqiyah",
            "Al Wadi al Jadid",
            "As Suways",
            "Aswan",
            "Asyut",
            "Bani Suwayf",
            "Bur Sa'id",
            "Dumyat",
            "Janub Sina'",
            "Kafr ash Shaykh",
            "Matruh",
            "Qina",
            "Shamal Sina'",
            "Suhaj"
        ]
    },
    "El Salvador": {
        "localite": [
            "Ahuachapán",
            "Cabañas",
            "Chalatenango",
            "Cuscatlán",
            "La Libertad",
            "La Paz",
            "La Unión",
            "Morazán",
            "San Miguel",
            "San Salvador",
            "San Vicente",
            "Santa Ana",
            "Sonsonate",
            "Usulután"
        ]
    },
    "Equatorial Guinea": {
        "localite": [
            "Annobón",
            "Bioko Norte",
            "Bioko Sur",
            "Centro Sur",
            "Kié-Ntem",
            "Litoral",
            "Wele-Nzas"
        ]
    },
    "Eritrea": {
        "localite": [
            "Anseba",
            "Debub",
            "Debubawi Keyih Bahri",
            "Gash-Barka",
            "Maekel",
            "Semienawi Keyih Bahri"
        ]
    },
    "Estonia": {
        "localite": [
            "Harju County",
            "Hiiu County",
            "Ida-Viru County",
            "Jõgeva County",
            "Järva County",
            "Lääne County",
            "Lääne-Viru County",
            "Põlva County",
            "Pärnu County",
            "Rapla County",
            "Saare County",
            "Tartu County",
            "Valga County",
            "Viljandi County",
            "Võru County"
        ]
    },
    "Eswatini (fmr. 'Swaziland')": {
        "localite": [
            "Hhohho",
            "Lubombo",
            "Manzini",
            "Shiselweni"
        ]
    },
    "Ethiopia": {
        "localite": [
            "Addis Ababa",
            "Afar",
            "Amhara",
            "Benishangul-Gumuz",
            "Dire Dawa",
            "Gambela",
            "Harari",
            "Oromia",
            "Sidama",
            "Somali",
            "Southern Nations, Nationalities, and Peoples' Region",
            "Tigray"
        ]
    },
    "Fiji": {
        "localite": [
            "Central",
            "Eastern",
            "Northern",
            "Western"
        ]
    },
    "Finland": {
        "localite": [
            "Åland Islands",
            "Central Finland",
            "Central Ostrobothnia",
            "Eastern Finland",
            "Finland Proper",
            "Kainuu",
            "Kymenlaakso",
            "Lapland",
            "North Karelia",
            "Northern Ostrobothnia",
            "Northern Savonia",
            "Ostrobothnia",
            "Päijät-Häme",
            "Pirkanmaa",
            "Satakunta",
            "South Karelia",
            "Southern Ostrobothnia",
            "Southern Savonia",
            "Tavastia Proper",
            "Uusimaa"
        ]
    },
    "France": {
        "localite": [
            "Auvergne-Rhône-Alpes",
            "Bourgogne-Franche-Comté",
            "Brittany",
            "Centre-Val de Loire",
            "Corsica",
            "Grand Est",
            "Hauts-de-France",
            "Île-de-France",
            "Normandy",
            "Nouvelle-Aquitaine",
            "Occitanie",
            "Pays de la Loire",
            "Provence-Alpes-Côte d'Azur"
        ]
    },
    "Gabon": {
        "localite": [
            "Estuaire",
            "Haut-Ogooué",
            "Moyen-Ogooué",
            "Ngounié",
            "Nyanga",
            "Ogooué-Ivindo",
            "Ogooué-Lolo",
            "Ogooué-Maritime",
            "Woleu-Ntem"
        ]
    },
    "Gambia": {
        "localite": [
            "Banjul",
            "Central River",
            "Lower River",
            "North Bank",
            "Upper River",
            "Western"
        ]
    },
    "Georgia": {
        "localite": [
            "Adjara",
            "Guria",
            "Imereti",
            "Kakheti",
            "Kvemo Kartli",
            "Mtskheta-Mtianeti",
            "Racha-Lechkhumi and Kvemo Svaneti",
            "Samegrelo-Zemo Svaneti",
            "Samtskhe-Javakheti",
            "Shida Kartli",
            "Tbilisi"
        ]
    },
    "Germany": {
        "localite": [
            "Baden-Württemberg",
            "Bavaria",
            "Berlin",
            "Brandenburg",
            "Bremen",
            "Hamburg",
            "Hesse",
            "Lower Saxony",
            "Mecklenburg-Vorpommern",
            "North Rhine-Westphalia",
            "Rhineland-Palatinate",
            "Saarland",
            "Saxony",
            "Saxony-Anhalt",
            "Schleswig-Holstein",
            "Thuringia"
        ]
    },
    "Ghana": {
        "localite": [
            "Ashanti",
            "Brong-Ahafo",
            "Central",
            "Eastern",
            "Greater Accra",
            "Northern",
            "Upper East",
            "Upper West",
            "Volta",
            "Western"
        ]
    },
    "Greece": {
        "localite": [
            "Attica",
            "Central Greece",
            "Central Macedonia",
            "Crete",
            "Eastern Macedonia and Thrace",
            "Epirus",
            "Ionian Islands",
            "North Aegean",
            "Peloponnese",
            "South Aegean",
            "Thessaly",
            "Western Greece",
            "Western Macedonia"
        ]
    },
    "Grenada": {
        "localite": [
            "Saint Andrew",
            "Saint David",
            "Saint George",
            "Saint John",
            "Saint Mark",
            "Saint Patrick"
        ]
    },
    "Guatemala": {
        "localite": [
            "Alta Verapaz",
            "Baja Verapaz",
            "Chimaltenango",
            "Chiquimula",
            "El Progreso",
            "Escuintla",
            "Guatemala",
            "Huehuetenango",
            "Izabal",
            "Jalapa",
            "Jutiapa",
            "Petén",
            "Quetzaltenango",
            "Quiché",
            "Retalhuleu",
            "Sacatepéquez",
            "San Marcos",
            "Santa Rosa",
            "Sololá",
            "Suchitepéquez",
            "Totonicapán",
            "Zacapa"
        ]
    },
    "Guinea": {
        "localite": [
            "Boké",
            "Conakry",
            "Faranah",
            "Kankan",
            "Kindia",
            "Labé",
            "Mamou",
            "Nzérékoré"
        ]
    },
    "Guinea-Bissau": {
        "localite": [
            "Bafatá",
            "Biombo",
            "Bolama-Bijagos",
            "Cacheu",
            "Gabú",
            "Oio",
            "Quinara",
            "Tombali"
        ]
    },
    "Guyana": {
        "localite": [
            "Barima-Waini",
            "Cuyuni-Mazaruni",
            "Demerara-Mahaica",
            "East Berbice-Corentyne",
            "Essequibo Islands-West Demerara",
            "Mahaica-Berbice",
            "Pomeroon-Supenaam",
            "Potaro-Siparuni",
            "Upper Demerara-Berbice",
            "Upper Takutu-Upper Essequibo"
        ]
    },
    "Haiti": {
        "localite": [
            "Artibonite",
            "Centre",
            "Grand'Anse",
            "Nippes",
            "Nord",
            "Nord-Est",
            "Nord-Ouest",
            "Ouest",
            "Sud",
            "Sud-Est"
        ]
    },
    "Honduras": {
        "localite": [
            "Atlántida",
            "Bay Islands",
            "Choluteca",
            "Colón",
            "Comayagua",
            "Copán",
            "Cortés",
            "El Paraíso",
            "Francisco Morazán",
            "Gracias a Dios",
            "Intibucá",
            "La Paz",
            "Lempira",
            "Ocotepeque",
            "Olancho",
            "Santa Bárbara",
            "Valle",
            "Yoro"
        ]
    },
    "Hungary": {
        "localite": [
            "Bács-Kiskun",
            "Baranya",
            "Békés",
            "Borsod-Abaúj-Zemplén",
            "Csongrád",
            "Fejér",
            "Győr-Moson-Sopron",
            "Hajdú-Bihar",
            "Heves",
            "Jász-Nagykun-Szolnok",
            "Komárom-Esztergom",
            "Nógrád",
            "Pest",
            "Somogy",
            "Szabolcs-Szatmár-Bereg",
            "Tolna",
            "Vas",
            "Veszprém",
            "Zala"
        ]
    },
    "Iceland": {
        "localite": [
            "Capital Region",
            "Northeastern Region",
            "Northwestern Region",
            "Southern Peninsula",
            "Southern Region",
            "Western Region",
            "Westfjords"
        ]
    },
    "India": {
        "localite": [
            "Andaman and Nicobar Islands",
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chandigarh",
            "Chhattisgarh",
            "Dadra and Nagar Haveli and Daman and Diu",
            "Delhi",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jammu and Kashmir",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Ladakh",
            "Lakshadweep",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Puducherry",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttar Pradesh",
            "Uttarakhand",
            "West Bengal"
        ]
    },
    "Indonesia": {
        "localite": [
            "Aceh",
            "Bali",
            "Bangka Belitung Islands",
            "Banten",
            "Bengkulu",
            "Central Java",
            "Central Kalimantan",
            "Central Sulawesi",
            "East Java",
            "East Kalimantan",
            "East Nusa Tenggara",
            "Gorontalo",
            "Jakarta Special Capital Region",
            "Jambi",
            "Lampung",
            "Maluku",
            "North Kalimantan",
            "North Maluku",
            "North Sulawesi",
            "North Sumatra",
            "Papua",
            "Riau",
            "Riau Islands",
            "Southeast Sulawesi",
            "South Kalimantan",
            "South Sulawesi",
            "South Sumatra",
            "Special Region of Yogyakarta",
            "West Java",
            "West Kalimantan",
            "West Nusa Tenggara",
            "West Papua",
            "West Sulawesi",
            "West Sumatra"
        ]
    },
    "Iran": {
        "localite": [
            "Alborz",
            "Ardabil",
            "Bushehr",
            "Chaharmahal and Bakhtiari",
            "East Azerbaijan",
            "Fars",
            "Gilan",
            "Golestan",
            "Hamadan",
            "Hormozgan",
            "Ilam",
            "Isfahan",
            "Kerman",
            "Kermanshah",
            "Khuzestan",
            "Kohgiluyeh and Boyer-Ahmad",
            "Kurdistan",
            "Lorestan",
            "Markazi",
            "Mazandaran",
            "North Khorasan",
            "Qazvin",
            "Qom",
            "Razavi Khorasan",
            "Semnan",
            "Sistan and Baluchestan",
            "South Khorasan",
            "Tehran",
            "West Azerbaijan",
            "Yazd",
            "Zanjan"
        ]
    },
    "Iraq": {
        "localite": [
            "Al Anbar",
            "Al Muthanna",
            "Al-Qādisiyyah",
            "Babylon",
            "Baghdad",
            "Basra",
            "Dhi Qar",
            "Diyala",
            "Erbil",
            "Karbala",
            "Kirkuk",
            "Maysan",
            "Najaf",
            "Nineveh",
            "Saladin",
            "Sulaymaniyah",
            "Wasit"
        ]
    },
    "Ireland": {
        "localite": [
            "Carlow",
            "Cavan",
            "Clare",
            "Cork",
            "Donegal",
            "Dublin",
            "Galway",
            "Kerry",
            "Kildare",
            "Kilkenny",
            "Laois",
            "Leitrim",
            "Limerick",
            "Longford",
            "Louth",
            "Mayo",
            "Meath",
            "Monaghan",
            "Offaly",
            "Roscommon",
            "Sligo",
            "Tipperary",
            "Waterford",
            "Westmeath",
            "Wexford",
            "Wicklow"
        ]
    },
    "Italy": {
        "localite": [
            "Abruzzo",
            "Aosta Valley",
            "Apulia",
            "Basilicata",
            "Calabria",
            "Campania",
            "Emilia-Romagna",
            "Friuli-Venezia Giulia",
            "Lazio",
            "Liguria",
            "Lombardy",
            "Marche",
            "Molise",
            "Piedmont",
            "Sardinia",
            "Sicily",
            "Trentino-South Tyrol",
            "Tuscany",
            "Umbria",
            "Veneto"
        ]
    },
    "Ivory Coast": {
        "localite": [
            "Abidjan",
            "Bas-Sassandra",
            "Comoé",
            "Denguélé",
            "Gôh-Djiboua",
            "Lacs",
            "Lagunes",
            "Montagnes",
            "Sassandra-Marahoué",
            "Savanes",
            "Vallée du Bandama",
            "Woroba",
            "Yamoussoukro",
            "Zanzan"
        ]
    },
    "Jamaica": {
        "localite": [
            "Clarendon",
            "Hanover",
            "Kingston",
            "Manchester",
            "Portland",
            "Saint Andrew",
            "Saint Ann",
            "Saint Catherine",
            "Saint Elizabeth",
            "Saint James",
            "Saint Mary",
            "Saint Thomas",
            "Trelawny",
            "Westmoreland"
        ]
    },
    "Japan": {
        "localite": [
            "Chūbu",
            "Chūgoku",
            "Hokkaido",
            "Kansai",
            "Kantō",
            "Kyushu",
            "Shikoku",
            "Tōhoku"
        ]
    },
    "Jordan": {
        "localite": [
            "Ajloun",
            "Amman",
            "Aqaba",
            "Balqa",
            "Irbid",
            "Jerash",
            "Karak",
            "Ma'an",
            "Madaba",
            "Mafraq",
            "Tafilah",
            "Zarqa"
        ]
    },
    "Kazakhstan": {
        "localite": [
            "Akmola",
            "Aktobe",
            "Almaty",
            "Almaty Region",
            "Atyrau",
            "Baikonur",
            "East Kazakhstan",
            "Jambyl",
            "Karaganda",
            "Kostanay",
            "Kyzylorda",
            "Mangystau",
            "North Kazakhstan",
            "Nur-Sultan",
            "Pavlodar",
            "Turkestan",
            "West Kazakhstan"
        ]
    },
    "Kenya": {
        "localite": [
            "Baringo",
            "Bomet",
            "Bungoma",
            "Busia",
            "Elgeyo-Marakwet",
            "Embu",
            "Garissa",
            "Homa Bay",
            "Isiolo",
            "Kajiado",
            "Kakamega",
            "Kericho",
            "Kiambu",
            "Kilifi",
            "Kirinyaga",
            "Kisii",
            "Kisumu",
            "Kitui",
            "Kwale",
            "Laikipia",
            "Lamu",
            "Machakos",
            "Makueni",
            "Mandera",
            "Marsabit",
            "Meru",
            "Migori",
            "Mombasa",
            "Murang'a",
            "Nairobi",
            "Nakuru",
            "Nandi",
            "Narok",
            "Nyamira",
            "Nyandarua",
            "Nyeri",
            "Samburu",
            "Siaya",
            "Taita-Taveta",
            "Tana River",
            "Tharaka-Nithi",
            "Trans-Nzoia",
            "Turkana",
            "Uasin Gishu",
            "Vihiga",
            "Wajir",
            "West Pokot"
        ]
    },
    "Kiribati": {
        "localite": [
            "Abaiang",
            "Abemama",
            "Aranuka",
            "Arorae",
            "Banaba",
            "Beru",
            "Butaritari",
            "Kanton",
            "Kiritimati",
            "Kuria",
            "Maiana",
            "Makin",
            "Marakei",
            "Nikunau",
            "Nonouti",
            "Onotoa",
            "Tabiteuea",
            "Tabuaeran",
            "Tamana",
            "Tarawa",
            "Teraina"
        ]
    },
    "Kosovo": {
        "localite": [
            "District of Gjakova",
            "District of Gjilan",
            "District of Mitrovica",
            "District of Peć",
            "District of Pristina",
            "District of Prizren"
        ]
    },
    "Kuwait": {
        "localite": [
            "Al Ahmadi",
            "Al Farwaniyah",
            "Al Jahra",
            "Capital",
            "Hawalli",
            "Mubarak Al-Kabeer"
        ]
    },
    "Kyrgyzstan": {
        "localite": [
            "Batken",
            "Bishkek",
            "Chuy",
            "Jalal-Abad",
            "Naryn",
            "Osh",
            "Talas"
        ]
    },
    "Laos": {
        "localite": [
            "Attapeu",
            "Bokeo",
            "Bolikhamsai",
            "Champasak",
            "Houaphanh",
            "Khammouane",
            "Luang Namtha",
            "Luang Prabang",
            "Oudomxay",
            "Phongsaly",
            "Salavan",
            "Savannakhet",
            "Sekong",
            "Vientiane",
            "Vientiane Prefecture",
            "Xaisomboun",
            "Xaisomboun Special Zone",
            "Xiangkhouang"
        ]
    },
    "Latvia": {
        "localite": [
            "Daugavpils",
            "Jelgava",
            "Jēkabpils",
            "Liepāja",
            "Rēzekne",
            "Rīga",
            "Valmiera",
            "Ventspils"
        ]
    },
    "Lebanon": {
        "localite": [
            "Akkar",
            "Baalbek-Hermel",
            "Beirut",
            "Beqaa",
            "Mount Lebanon",
            "Nabatieh",
            "North",
            "South"
        ]
    },
    "Lesotho": {
        "localite": [
            "Berea",
            "Butha-Buthe",
            "Leribe",
            "Mafeteng",
            "Maseru",
            "Mohale's Hoek",
            "Mokhotlong",
            "Qacha's Nek",
            "Quthing",
            "Thaba-Tseka"
        ]
    },
    "Liberia": {
        "localite": [
            "Bomi",
            "Bong",
            "Gbarpolu",
            "Grand Bassa",
            "Grand Cape Mount",
            "Grand Gedeh",
            "Grand Kru",
            "Lofa",
            "Margibi",
            "Maryland",
            "Montserrado",
            "Nimba",
            "River Cess",
            "River Gee",
            "Sinoe"
        ]
    },
    "Libya": {
        "localite": [
            "Al Wahat",
            "Benghazi",
            "Butnan",
            "Derna",
            "Ghat",
            "Jabal al Akhdar",
            "Jabal al Gharbi",
            "Jafara",
            "Jufra",
            "Kufra",
            "Marj",
            "Misrata",
            "Murqub",
            "Murzuq",
            "Nalut",
            "Nuqat al Khams",
            "Sabha",
            "Sirte",
            "Tripoli",
            "Wadi al Hayaa",
            "Wadi al Shatii",
            "Zawiya"
        ]
    },
    "Liechtenstein": {
        "localite": [
            "Balzers",
            "Eschen",
            "Gamprin",
            "Mauren",
            "Planken",
            "Ruggell",
            "Schaan",
            "Schellenberg",
            "Triesen",
            "Triesenberg",
            "Vaduz"
        ]
    },
    "Lithuania": {
        "localite": [
            "Alytus County",
            "Kaunas County",
            "Klaipėda County",
            "Marijampolė County",
            "Panevėžys County",
            "Šiauliai County",
            "Tauragė County",
            "Telšiai County",
            "Utena County",
            "Vilnius County"
        ]
    },
    "Luxembourg": {
        "localite": [
            "Canton of Capellen",
            "Canton of Clervaux",
            "Canton of Diekirch",
            "Canton of Echternach",
            "Canton of Esch-sur-Alzette",
            "Canton of Grevenmacher",
            "Canton of Luxembourg",
            "Canton of Mersch",
            "Canton of Redange",
            "Canton of Remich",
            "Canton of Vianden",
            "Canton of Wiltz"
        ]
    },
    "Madagascar": {
        "localite": [
            "Alaotra-Mangoro",
            "Amoron'i Mania",
            "Analamanga",
            "Analanjirofo",
            "Androy",
            "Anosy",
            "Atsimo-Andrefana",
            "Atsimo-Atsinanana",
            "Atsinanana",
            "Betsiboka",
            "Boeny",
            "Bongolava",
            "Diana",
            "Haute Matsiatra",
            "Ihorombe",
            "Itasy",
            "Melaky",
            "Menabe",
            "Sava",
            "Sofia",
            "Vakinankaratra",
            "Vatovavy-Fitovinany"
        ]
    },
    "Malawi": {
        "localite": [
            "Central Region",
            "Northern Region",
            "Southern Region"
        ]
    },
    "Malaysia": {
        "localite": [
            "Johor",
            "Kedah",
            "Kelantan",
            "Kuala Lumpur",
            "Labuan",
            "Melaka",
            "Negeri Sembilan",
            "Pahang",
            "Penang",
            "Perak",
            "Perlis",
            "Putrajaya",
            "Sabah",
            "Sarawak",
            "Selangor",
            "Terengganu"
        ]
    },
    "Maldives": {
        "localite": [
            "Raa Atoll",
            "Faafu Atoll",
            "Gaafu Alif Atoll",
            "Gnaviyani Atoll",
            "Haa Alif Atoll",
            "Haa Dhaalu Atoll",
            "Kaafu Atoll",
            "Laamu Atoll",
            "Lhaviyani Atoll",
            "Meemu Atoll",
            "Noonu Atoll",
            "Raa Atoll",
            "Seenu Atoll",
            "Shaviyani Atoll",
            "Thaa Atoll",
            "Vaavu Atoll"
        ]
    },
    "Mali": {
        "localite": [
            "Bamako",
            "Gao",
            "Kayes",
            "Kidal",
            "Koulikoro",
            "Ménaka",
            "Mopti",
            "Segou",
            "Sikasso",
            "Tombouctou"
        ]
    },
    "Malta": {
        "localite": [
            "Gozo",
            "Gżira",
            "Mdina",
            "Msida",
            "Pietà",
            "Rabat",
            "Saint John",
            "Saint Julian's",
            "Saint Lawrence",
            "Saint Paul's Bay",
            "Senglea",
            "Sliema",
            "Valletta",
            "Żebbuġ"
        ]
    },
    "Marshall Islands": {
        "localite": [
            "Ailinglaplap Atoll",
            "Ailuk Atoll",
            "Arno Atoll",
            "Aur Atoll",
            "Bikini Atoll",
            "Ebon Atoll",
            "Enewetak Atoll",
            "Jabat Island",
            "Jaluit Atoll",
            "Kili Island",
            "Kwajalein Atoll",
            "Lae Atoll",
            "Lib Island",
            "Likiep Atoll",
            "Majuro",
            "Maloelap Atoll",
            "Mejit Island",
            "Mili Atoll",
            "Namdrik Atoll",
            "Namu Atoll",
            "Rongelap Atoll",
            "Rongrik Atoll",
            "Toke Atoll",
            "Ujae Atoll",
            "Ujelang Atoll",
            "Utirik Atoll",
            "Wotho Atoll",
            "Wotje Atoll"
        ]
    },
    "Mauritania": {
        "localite": [
            "Adrar",
            "Assaba",
            "Brakna",
            "Dakhlet Nouadhibou",
            "Gorgol",
            "Guidimaka",
            "Hodh Ech Chargui",
            "Hodh El Gharbi",
            "Inchiri",
            "Nouakchott",
            "Tagant",
            "Tiris Zemmour",
            "Trarza"
        ]
    },
    "Mauritius": {
        "localite": [
            "Black River",
            "Flacq",
            "Grand Port",
            "Moka",
            "Pamplemousses",
            "Plaines Wilhems",
            "Port Louis",
            "Rivière du Rempart",
            "Rodrigues",
            "Savanne"
        ]
    },
    "Mexico": {
        "localite": [
            "Aguascalientes",
            "Baja California",
            "Baja California Sur",
            "Campeche",
            "Chiapas",
            "Chihuahua",
            "Coahuila",
            "Colima",
            "Durango",
            "Guanajuato",
            "Guerrero",
            "Hidalgo",
            "Jalisco",
            "Mexico City",
            "Mexico State",
            "Michoacán",
            "Morelos",
            "Nayarit",
            "Nuevo León",
            "Oaxaca",
            "Puebla",
            "Querétaro",
            "Quintana Roo",
            "San Luis Potosí",
            "Sinaloa",
            "Sonora",
            "Tabasco",
            "Tamaulipas",
            "Tlaxcala",
            "Veracruz",
            "Yucatán",
            "Zacatecas"
        ]
    },
    "Micronesia": {
        "localite": [
            "Chuuk",
            "Kosrae",
            "Pohnpei",
            "Yap"
        ]
    },
    "Moldova": {
        "localite": [
            "Anenii Noi",
            "Bălți",
            "Basarabeasca",
            "Bender",
            "Briceni",
            "Cahul",
            "Călărași",
            "Căușeni",
            "Cimișlia",
            "Criuleni",
            "Dondușeni",
            "Drochia",
            "Dubăsari",
            "Edineț",
            "Fălești",
            "Florești",
            "Glodeni",
            "Hîncești",
            "Ialoveni",
            "Leova",
            "Nisporeni",
            "Ocnița",
            "Orhei",
            "Rezina",
            "Rîșcani",
            "Sîngerei",
            "Soroca",
            "Strășeni",
            "Șoldănești",
            "Ștefan Vodă",
            "Taraclia",
            "Telenești",
            "Ungheni"
        ]
    },
    "Monaco": {
        "localite": [
            "Fontvieille",
            "La Colle",
            "La Condamine",
            "Monaco-Ville",
            "Moneghetti",
            "Monte Carlo"
        ]
    },
    "Mongolia": {
        "localite": [
            "Arkhangai",
            "Bayan-Ölgii",
            "Bayankhongor",
            "Bulgan",
            "Darkhan-Uul",
            "Dornod",
            "Dornogovi",
            "Dundgovi",
            "Govi-Altai",
            "Govisümber",
            "Khentii",
            "Khovd",
            "Khövsgöl",
            "Orkhon",
            "Ömnögovi",
            "Övörkhangai",
            "Selenge",
            "Sükhbaatar",
            "Töv",
            "Uvs",
            "Zavkhan"
        ]
    },
    "Montenegro": {
        "localite": [
            "Andrijevica",
            "Bar",
            "Berane",
            "Bijelo Polje",
            "Budva",
            "Cetinje",
            "Danilovgrad",
            "Gusinje",
            "Herceg Novi",
            "Kolašin",
            "Kotor",
            "Mojkovac",
            "Nikšić",
            "Petnjica",
            "Plav",
            "Pljevlja",
            "Plužine",
            "Podgorica",
            "Rožaje",
            "Šavnik",
            "Tivat",
            "Tuzi",
            "Ulcinj",
            "Žabljak"
        ]
    },
    "Morocco": {
        "localite": [
            "Béni Mellal-Khénifra",
            "Casablanca-Settat",
            "Dakhla-Oued Ed-Dahab",
            "Drâa-Tafilalet",
            "Fès-Meknès",
            "Guelmim-Oued Noun",
            "Laâyoune-Sakia El Hamra",
            "Marrakesh-Safi",
            "Oriental",
            "Rabat-Salé-Kénitra",
            "Souss-Massa",
            "Tanger-Tetouan-Al Hoceima"
        ]
    },
    "Mozambique": {
        "localite": [
            "Cabo Delgado",
            "Gaza",
            "Inhambane",
            "Manica",
            "Maputo",
            "Nampula",
            "Niassa",
            "Sofala",
            "Tete",
            "Zambezia"
        ]
    },
    "Myanmar (formerly Burma)": {
        "localite": [
            "Ayeyarwady",
            "Bago",
            "Chin",
            "Kachin",
            "Kayah",
            "Kayin",
            "Magway",
            "Mandalay",
            "Mon",
            "Naypyidaw",
            "Rakhine",
            "Sagaing",
            "Shan",
            "Tanintharyi",
            "Yangon"
        ]
    },
    "Namibia": {
        "localite": [
            "Erongo",
            "Hardap",
            "Karas",
            "Kavango East",
            "Kavango West",
            "Khomas",
            "Kunene",
            "Ohangwena",
            "Omaheke",
            "Omusati",
            "Oshana",
            "Oshikoto",
            "Otjozondjupa",
            "Zambezi"
        ]
    },
    "Nauru": {
        "localite": [
            "Aiwo",
            "Anabar",
            "Anetan",
            "Anibare",
            "Baiti",
            "Boe",
            "Buada",
            "Denigomodu",
            "Ewa",
            "Ijuw",
            "Meneng",
            "Nibok",
            "Uaboe",
            "Yaren"
        ]
    },
    "Nepal": {
        "localite": [
            "Bagmati Province",
            "Gandaki Province",
            "Karnali Province",
            "Lumbini Province",
            "Province No. 1",
            "Sudurpashchim Province"
        ]
    },
    "Netherlands": {
        "localite": [
            "Drenthe",
            "Flevoland",
            "Friesland",
            "Gelderland",
            "Groningen",
            "Limburg",
            "North Brabant",
            "North Holland",
            "Overijssel",
            "South Holland",
            "Utrecht",
            "Zeeland"
        ]
    },
    "New Zealand": {
        "localite": [
            "Auckland",
            "Bay of Plenty",
            "Canterbury",
            "Chatham Islands",
            "Gisborne",
            "Hawke's Bay",
            "Manawatū-Whanganui",
            "Marlborough",
            "Nelson",
            "Northland",
            "Otago",
            "Southland",
            "Taranaki",
            "Tasman",
            "Waikato",
            "Wellington",
            "West Coast"
        ]
    },
    "Nicaragua": {
        "localite": [
            "Boaco",
            "Carazo",
            "Chinandega",
            "Chontales",
            "Estelí",
            "Granada",
            "Jinotega",
            "León",
            "Madriz",
            "Managua",
            "Masaya",
            "Matagalpa",
            "Nueva Segovia",
            "Rivas",
            "Río San Juan"
        ]
    },
    "Niger": {
        "localite": [
            "Agadez",
            "Diffa",
            "Dosso",
            "Maradi",
            "Tahoua",
            "Tillabéri",
            "Zinder"
        ]
    },
    "Nigeria": {
        "localite": [
            "Abia",
            "Adamawa",
            "Akwa Ibom",
            "Anambra",
            "Bauchi",
            "Bayelsa",
            "Benue",
            "Borno",
            "Cross River",
            "Delta",
            "Ebonyi",
            "Edo",
            "Ekiti",
            "Enugu",
            "Gombe",
            "Imo",
            "Jigawa",
            "Kaduna",
            "Kano",
            "Katsina",
            "Kebbi",
            "Kogi",
            "Kwara",
            "Lagos",
            "Nasarawa",
            "Niger",
            "Ogun",
            "Ondo",
            "Osun",
            "Oyo",
            "Plateau",
            "Rivers",
            "Sokoto",
            "Taraba",
            "Yobe",
            "Zamfara"
        ]
    },
    "North Korea": {
        "localite": [
            "Chagang Province",
            "Kangwon Province",
            "North Hamgyong Province",
            "North Hwanghae Province",
            "Pyongyang",
            "Rason",
            "Ryanggang Province",
            "South Hamgyong Province",
            "South Hwanghae Province"
        ]
    },
    "North Macedonia (formerly Macedonia)": {
        "localite": [
            "Eastern",
            "Northeastern",
            "Pelagonia",
            "Polog",
            "Skopje",
            "Southeastern",
            "Southwestern",
            "Vardar"
        ]
    },
    "Norway": {
        "localite": [
            "Agder",
            "Innlandet",
            "Møre og Romsdal",
            "Nordland",
            "Oslo",
            "Rogaland",
            "Troms og Finnmark",
            "Trøndelag",
            "Vestfold og Telemark",
            "Vestland",
            "Viken"
        ]
    },
    "Oman": {
        "localite": [
            "Ad Dakhiliyah",
            "Al Batinah North",
            "Al Batinah South",
            "Al Buraimi",
            "Al Wusta",
            "Ash Sharqiyah North",
            "Ash Sharqiyah South",
            "Dhofar",
            "Musandam",
            "Muscat"
        ]
    },
    "Pakistan": {
        "localite": [
            "Azad Kashmir",
            "Balochistan",
            "Gilgit-Baltistan",
            "Islamabad Capital Territory",
            "Khyber Pakhtunkhwa",
            "Punjab",
            "Sindh"
        ]
    },
    "Palau": {
        "localite": [
            "Aimeliik",
            "Airai",
            "Angaur",
            "Hatohobei",
            "Kayangel",
            "Koror",
            "Melekeok",
            "Ngaraard",
            "Ngarchelong",
            "Ngardmau",
            "Ngatpang",
            "Ngchesar",
            "Ngeremlengui",
            "Ngiwal",
            "Peleliu",
            "Sonsorol"
        ]
    },
    "Palestine State": {
        "localite": [
            "Governorate of Jenin",
            "Governorate of Tubas",
            "Governorate of Tulkarm",
            "Governorate of Nablus",
            "Governorate of Qalqilya",
            "Governorate of Salfit",
            "Governorate of Ramallah and al-Bireh",
            "Governorate of Jericho and al-Aghwar",
            "Governorate of Jerusalem",
            "Governorate of Bethlehem",
            "Governorate of Hebron",
            "Governorate of North Gaza",
            "Governorate of Gaza"
        ]
    },
    "Panama": {
        "localite": [
            "Bocas del Toro",
            "Chiriquí",
            "Coclé",
            "Colón",
            "Darién",
            "Emberá",
            "Herrera",
            "Guna Yala",
            "Los Santos",
            "Ngöbe-Buglé",
            "Panamá",
            "Panamá Oeste",
            "Veraguas"
        ]
    },
    "Papua New Guinea": {
        "localite": [
            "Bougainville",
            "Central Province",
            "Chimbu",
            "East New Britain",
            "Eastern Highlands",
            "Enga",
            "Gulf",
            "Hela",
            "Jiwaka",
            "Madang",
            "Manus",
            "Milne Bay",
            "Morobe",
            "New Ireland",
            "Oro",
            "Sandaun",
            "Simbu",
            "Southern Highlands",
            "West New Britain",
            "Western Province",
            "Western Highlands"
        ]
    },
    "Paraguay": {
        "localite": [
            "Alto Paraguay",
            "Alto Paraná",
            "Amambay",
            "Boquerón",
            "Caaguazú",
            "Caazapá",
            "Canindeyú",
            "Central",
            "Concepción",
            "Cordillera",
            "Guairá",
            "Itapúa",
            "Misiones",
            "Ñeembucú",
            "Paraguarí",
            "Presidente Hayes",
            "San Pedro"
        ]
    },
    "Peru": {
        "localite": [
            "Amazonas",
            "Áncash",
            "Apurímac",
            "Arequipa",
            "Ayacucho",
            "Cajamarca",
            "Callao",
            "Cusco",
            "Huancavelica",
            "Huanuco",
            "Ica",
            "Junín",
            "La Libertad",
            "Lambayeque",
            "Lima",
            "Loreto",
            "Madre de Dios",
            "Moquegua",
            "Pasco",
            "Piura",
            "Puno",
            "San Martín",
            "Tacna",
            "Tumbes",
            "Ucayali"
        ]
    },
    "Philippines": {
        "localite": [
            "Autonomous Region in Muslim Mindanao",
            "Bicol Region",
            "Cagayan Valley",
            "Calabarzon",
            "Caraga",
            "Central Luzon",
            "Central Visayas",
            "Cordillera Administrative Region",
            "Davao Region",
            "Eastern Visayas",
            "Ilocos Region",
            "Mimaropa",
            "National Capital Region",
            "Northern Mindanao",
            "Soccsksargen",
            "Western Visayas",
            "Zamboanga Peninsula"
        ]
    },
    "Poland": {
        "localite": [
            "Greater Poland Voivodeship",
            "Kuyavian-Pomeranian Voivodeship",
            "Lesser Poland Voivodeship",
            "Łódź Voivodeship",
            "Lower Silesian Voivodeship",
            "Lublin Voivodeship",
            "Lubusz Voivodeship",
            "Masovian Voivodeship",
            "Opole Voivodeship",
            "Podkarpackie Voivodeship",
            "Podlaskie Voivodeship",
            "Pomeranian Voivodeship",
            "Silesian Voivodeship",
            "Świętokrzyskie Voivodeship",
            "Warmian-Masurian Voivodeship",
            "West Pomeranian Voivodeship"
        ]
    },
    "Portugal": {
        "localite": [
            "Azores",
            "Centro",
            "Lisbon",
            "Madeira",
            "North",
            "Alentejo",
            "Algarve"
        ]
    },
    "Qatar": {
        "localite": [
            "Ad Dawhah",
            "Al Daayen",
            "Al Khor",
            "Al Wakrah",
            "Ar Rayyan",
            "Madinat ash Shamal",
            "Umm Salal"
        ]
    },
    "Romania": {
        "localite": [
            "Alba",
            "Arad",
            "Argeș",
            "Bacău",
            "Bihor",
            "Bistrița-Năsăud",
            "Botoșani",
            "Brăila",
            "Brașov",
            "București",
            "Buzău",
            "Călărași",
            "Caraș-Severin",
            "Cluj",
            "Constanța",
            "Covasna",
            "Dâmbovița",
            "Dolj",
            "Galați",
            "Giurgiu",
            "Gorj",
            "Harghita",
            "Hunedoara",
            "Ialomița",
            "Iași",
            "Ilfov",
            "Maramureș",
            "Mehedinți",
            "Mureș",
            "Neamț",
            "Olt",
            "Prahova",
            "Satu Mare",
            "Sălaj",
            "Sibiu",
            "Suceava",
            "Teleorman",
            "Timiș",
            "Tulcea",
            "Vâlcea",
            "Vaslui",
            "Vrancea"
        ]
    },
    "Russia": {
        "localite": [
            "Altai Krai",
            "Altai Republic",
            "Amur Oblast",
            "Arkhangelsk Oblast",
            "Astrakhan Oblast",
            "Bashkortostan Republic",
            "Belgorod Oblast",
            "Bryansk Oblast",
            "Buryatia Republic",
            "Chechnya Republic",
            "Chelyabinsk Oblast",
            "Chukotka Autonomous Okrug",
            "Chuvashia Republic",
            "Crimea Republic",
            "Dagestan Republic",
            "Ingushetia Republic",
            "Irkutsk Oblast",
            "Ivanovo Oblast",
            "Jewish Autonomous Oblast",
            "Kabardino-Balkarian Republic",
            "Kaliningrad Oblast",
            "Kalmykia Republic",
            "Kaluga Oblast",
            "Kamchatka Krai",
            "Karachay-Cherkess Republic",
            "Karelia Republic",
            "Kemerovo Oblast",
            "Khabarovsk Krai",
            "Khakassia Republic",
            "Khanty-Mansi Autonomous Okrug - Yugra",
            "Kirov Oblast",
            "Komi Republic",
            "Kostroma Oblast",
            "Krasnodar Krai",
            "Krasnoyarsk Krai",
            "Kurgan Oblast",
            "Kursk Oblast",
            "Leningrad Oblast",
            "Lipetsk Oblast",
            "Magadan Oblast",
            "Mari El Republic",
            "Mordovia Republic",
            "Moscow",
            "Moscow Oblast",
            "Murmansk Oblast",
            "Nenets Autonomous Okrug",
            "Nizhny Novgorod Oblast",
            "North Ossetia-Alania Republic",
            "Novgorod Oblast",
            "Novosibirsk Oblast",
            "Omsk Oblast",
            "Orenburg Oblast",
            "Oryol Oblast",
            "Penza Oblast",
            "Perm Krai",
            "Primorsky Krai",
            "Pskov Oblast",
            "Rostov Oblast",
            "Ryazan Oblast",
            "Saint Petersburg",
            "Sakha Republic (Yakutia)",
            "Sakhalin Oblast",
            "Samara Oblast",
            "Saratov Oblast",
            "Smolensk Oblast",
            "Stavropol Krai",
            "Sverdlovsk Oblast",
            "Tambov Oblast",
            "Tatarstan Republic",
            "Tomsk Oblast",
            "Tula Oblast",
            "Tuva Republic",
            "Tver Oblast",
            "Tyumen Oblast",
            "Udmurt Republic",
            "Ulyanovsk Oblast",
            "Vladimir Oblast",
            "Volgograd Oblast",
            "Vologda Oblast",
            "Voronezh Oblast",
            "Yamalo-Nenets Autonomous Okrug",
            "Yaroslavl Oblast",
            "Zabaykalsky Krai"
        ]
    },
    "Rwanda": {
        "localite": [
            "Eastern Province",
            "Kigali Province",
            "Northern Province",
            "Southern Province",
            "Western Province"
        ]
    },
    "Saint Kitts and Nevis": {
        "localite": [
            "Christ Church Nichola Town",
            "Saint Anne Sandy Point",
            "Saint George Basseterre",
            "Saint George Gingerland",
            "Saint James Windward",
            "Saint John Capisterre",
            "Saint John Figtree",
            "Saint Mary Cayon",
            "Saint Paul Capisterre",
            "Saint Paul Charlestown",
            "Saint Peter Basseterre",
            "Saint Thomas Lowland",
            "Saint Thomas Middle Island",
            "Trinity Palmetto Point"
        ]
    },
    "Saint Lucia": {
        "localite": [
            "Anse la Raye",
            "Castries",
            "Choiseul",
            "Dauphin",
            "Dennery",
            "Gros Islet",
            "Laborie",
            "Micoud",
            "Praslin",
            "Soufrière",
            "Vieux Fort"
        ]
    },
    "Saint Vincent and the Grenadines": {
        "localite": [
            "Charlotte",
            "Grenadines",
            "Saint Andrew",
            "Saint David",
            "Saint George",
            "Saint Patrick"
        ]
    },
    "Samoa": {
        "localite": [
            "A'ana",
            "Aiga-i-le-Tai",
            "Atua",
            "Fa'asaleleaga",
            "Gaga'emauga",
            "Gaga'ifomauga",
            "Palauli",
            "Satupa'itea",
            "Tuamasaga",
            "Va'a-o-Fonoti",
            "Vaisigano"
        ]
    },
    "San Marino": {
        "localite": [
            "Acquaviva",
            "Borgo Maggiore",
            "Chiesanuova",
            "Domagnano",
            "Faetano",
            "Fiorentino",
            "Montegiardino",
            "San Marino",
            "Serravalle"
        ]
    },
    "Sao Tome and Principe": {
        "localite": [
            "Príncipe",
            "São Tomé"
        ]
    },
    "Saudi Arabia": {
        "localite": [
            "Al Bahah",
            "Al Jawf",
            "Al Madinah",
            "Al Qassim",
            "Asir",
            "Eastern Province",
            "Ha'il",
            "Jizan",
            "Makkah",
            "Najran",
            "Northern Borders",
            "Riyadh",
            "Tabuk"
        ]
    },
    "Senegal": {
        "localite": [
            "Dakar",
            "Diourbel",
            "Fatick",
            "Kaffrine",
            "Kaolack",
            "Kédougou",
            "Kolda",
            "Louga",
            "Matam",
            "Saint-Louis",
            "Sédhiou",
            "Tambacounda",
            "Thiès",
            "Ziguinchor"
        ]
    },
    "Serbia": {
        "localite": [
            "Belgrade",
            "Borski",
            "Braničevski",
            "Jablanički",
            "Južnobački",
            "Južnobanatski",
            "Kolubarski",
            "Kosovski",
            "Mačvanski",
            "Moravički",
            "Nišavski",
            "Pčinjski",
            "Pećki",
            "Pirot",
            "Podunavski",
            "Pomoravski",
            "Prizrenski",
            "Rasinski",
            "Raški",
            "Severnobački",
            "Severnobanatski",
            "Srednjebanatski",
            "Sremski",
            "Šumadijski",
            "Toplički",
            "Zaječarski",
            "Zapadnobački",
            "Zlatiborski"
        ]
    },
    "Seychelles": {
        "localite": [
            "Anse aux Pins",
            "Anse Boileau",
            "Anse Etoile",
            "Anse Royale",
            "Au Cap",
            "Baie Lazare",
            "Baie Sainte Anne",
            "Beau Vallon",
            "Bel Air",
            "Bel Ombre",
            "Cascade",
            "Glacis",
            "Grand'Anse Mahé",
            "Grand'Anse Praslin",
            "La Digue",
            "La Rivière Anglaise",
            "Les Mamelles",
            "Mont Buxton",
            "Mont Fleuri",
            "Plaisance",
            "Pointe La Rue",
            "Port Glaud",
            "Saint Louis",
            "Takamaka"
        ]
    },
    "Sierra Leone": {
        "localite": [
            "Eastern Province",
            "Northern Province",
            "Southern Province",
            "Western Area"
        ]
    },
    "Singapore": {
        "localite": [
            "Central Region",
            "East Region",
            "North Region",
            "North-East Region",
            "West Region"
        ]
    },
    "Slovakia": {
        "localite": [
            "Banská Bystrica Region",
            "Bratislava Region",
            "Košice Region",
            "Nitra Region",
            "Prešov Region",
            "Trenčín Region",
            "Trnava Region",
            "Žilina Region"
        ]
    },
    "Slovenia": {
        "localite": [
            "Carinthia",
            "Central Slovenia",
            "Coastal–Karst",
            "Drava",
            "Gorizia",
            "Littoral–Inner Carniola",
            "Mura",
            "Savinja",
            "Southeast Slovenia"
        ]
    },
    "Solomon Islands": {
        "localite": [
            "Central Province",
            "Choiseul",
            "Guadalcanal",
            "Honiara",
            "Isabel",
            "Makira-Ulawa",
            "Malaita",
            "Rennell and Bellona",
            "Temotu",
            "Western Province"
        ]
    },
    "Somalia": {
        "localite": [
            "Awdal",
            "Bakool",
            "Banaadir",
            "Bari",
            "Bay",
            "Galguduud",
            "Gedo",
            "Hiraan",
            "Lower Juba",
            "Lower Shebelle",
            "Mudug",
            "Nugal",
            "Sanaag",
            "Togdheer",
            "Woqooyi Galbeed"
        ]
    },
    "South Africa": {
        "localite": [
            "Eastern Cape",
            "Free State",
            "Gauteng",
            "KwaZulu-Natal",
            "Limpopo",
            "Mpumalanga",
            "North West",
            "Northern Cape",
            "Western Cape"
        ]
    },
    "South Korea": {
        "localite": [
            "Chungcheongbuk-do",
            "Chungcheongnam-do",
            "Gangwon-do",
            "Gyeonggi-do",
            "Gyeongsangbuk-do",
            "Gyeongsangnam-do",
            "Jeju-do",
            "Jeollabuk-do",
            "Jeollanam-do",
            "Sejong Special Autonomous City",
            "Seoul",
            "Ulsan"
        ]
    },
    "South Sudan": {
        "localite": [
            "Central Equatoria",
            "Eastern Equatoria",
            "Jonglei",
            "Lakes",
            "Northern Bahr el Ghazal",
            "Unity",
            "Upper Nile",
            "Warrap",
            "Western Bahr el Ghazal",
            "Western Equatoria"
        ]
    },
    "Spain": {
        "localite": [
            "Andalusia",
            "Aragon",
            "Asturias",
            "Balearic Islands",
            "Basque Country",
            "Canary Islands",
            "Cantabria",
            "Castile and León",
            "Castilla–La Mancha",
            "Catalonia",
            "Extremadura",
            "Galicia",
            "La Rioja",
            "Madrid",
            "Murcia",
            "Navarre",
            "Valencian Community"
        ]
    },
    "Sri Lanka": {
        "localite": [
            "Central Province",
            "Eastern Province",
            "North Central Province",
            "Northern Province",
            "North Western Province",
            "Sabaragamuwa Province",
            "Southern Province",
            "Uva Province",
            "Western Province"
        ]
    },
    "Sudan": {
        "localite": [
            "Al Jazirah",
            "Blue Nile",
            "Central Darfur",
            "East Darfur",
            "Kassala",
            "Khartoum",
            "North Darfur",
            "North Kordofan",
            "Northern",
            "Red Sea",
            "River Nile",
            "Sennar",
            "South Darfur",
            "South Kordofan",
            "West Darfur",
            "West Kordofan",
            "White Nile"
        ]
    },
    "Suriname": {
        "localite": [
            "Brokopondo",
            "Commewijne",
            "Coronie",
            "Marowijne",
            "Nickerie",
            "Para",
            "Paramaribo",
            "Saramacca",
            "Sipaliwini",
            "Wanica"
        ]
    },
    "Sweden": {
        "localite": [
            "Blekinge County",
            "Dalarna County",
            "Gävleborg County",
            "Gotland County",
            "Halland County",
            "Jämtland County",
            "Jönköping County",
            "Kalmar County",
            "Kronoberg County",
            "Norrbotten County",
            "Örebro County",
            "Östergötland County",
            "Skåne County",
            "Södermanland County",
            "Stockholm County",
            "Uppsala County",
            "Värmland County",
            "Västerbotten County",
            "Västernorrland County",
            "Västmanland County",
            "Västra Götaland County"
        ]
    },
    "Switzerland": {
        "localite": [
            "Aargau",
            "Appenzell Ausserrhoden",
            "Appenzell Innerrhoden",
            "Basel-Landschaft",
            "Basel-Stadt",
            "Bern",
            "Fribourg",
            "Geneva",
            "Glarus",
            "Graubünden",
            "Jura",
            "Lucerne",
            "Neuchâtel",
            "Nidwalden",
            "Obwalden",
            "Schaffhausen",
            "Schwyz",
            "Solothurn",
            "St. Gallen",
            "Thurgau",
            "Ticino",
            "Uri",
            "Valais",
            "Vaud",
            "Zug",
            "Zurich"
        ]
    },
    "Syria": {
        "localite": [
            "Al-Hasakah",
            "Al-Raqqah",
            "Aleppo",
            "As-Suwayda",
            "Damascus",
            "Daraa",
            "Deir ez-Zor",
            "Hama",
            "Homs",
            "Idlib",
            "Latakia",
            "Quneitra",
            "Rif Dimashq",
            "Tartus"
        ]
    },
    "Taiwan": {
        "localite": [
            "Changhua County",
            "Chiayi City",
            "Chiayi County",
            "Hsinchu City",
            "Hsinchu County",
            "Hualien County",
            "Kaohsiung",
            "Keelung",
            "Kinmen",
            "Lienchiang County",
            "Miaoli County",
            "Nantou County",
            "New Taipei",
            "Penghu County",
            "Pingtung County",
            "Taichung",
            "Tainan",
            "Taipei",
            "Taitung County",
            "Taoyuan",
            "Yilan County",
            "Yunlin County"
        ]
    },
    "Tajikistan": {
        "localite": [
            "Districts of Republican Subordination",
            "Gorno-Badakhshan Autonomous Province",
            "Khatlon Region",
            "Sughd Region"
        ]
    },
    "Tanzania": {
        "localite": [
            "Arusha",
            "Dar es Salaam",
            "Dodoma",
            "Geita",
            "Iringa",
            "Kagera",
            "Katavi",
            "Kigoma",
            "Kilimanjaro",
            "Lindi",
            "Manyara",
            "Mara",
            "Morogoro",
            "Mtwara",
            "Mwanza",
            "Njombe",
            "Pemba North",
            "Pemba South",
            "Pwani",
            "Rukwa",
            "Ruvuma",
            "Shinyanga",
            "Simiyu",
            "Singida",
            "Tabora",
            "Tanga",
            "Zanzibar Central/South",
            "Zanzibar North",
            "Zanzibar Urban/West"
        ]
    },
    "Thailand": {
        "localite": [
            "Amnat Charoen",
            "Ang Thong",
            "Bueng Kan",
            "Buri Ram",
            "Chachoengsao",
            "Chai Nat",
            "Chaiyaphum",
            "Chanthaburi",
            "Chiang Mai",
            "Chiang Rai",
            "Chon Buri",
            "Chumphon",
            "Kalasin",
            "Kamphaeng Phet",
            "Kanchanaburi",
            "Khon Kaen",
            "Krabi",
            "Lampang",
            "Lamphun",
            "Loei",
            "Lopburi",
            "Mae Hong Son",
            "Maha Sarakham",
            "Mukdahan",
            "Nakhon Nayok",
            "Nakhon Pathom",
            "Nakhon Phanom",
            "Nakhon Ratchasima",
            "Nakhon Sawan",
            "Nakhon Si Thammarat",
            "Nan",
            "Narathiwat",
            "Nong Bua Lam Phu",
            "Nong Khai",
            "Nonthaburi",
            "Pathum Thani",
            "Pattani",
            "Phangnga",
            "Phatthalung",
            "Phayao",
            "Phetchabun",
            "Phetchaburi",
            "Phichit",
            "Phitsanulok",
            "Phra Nakhon Si Ayutthaya",
            "Phrae",
            "Phuket",
            "Prachin Buri",
            "Prachuap Khiri Khan",
            "Ranong",
            "Ratchaburi",
            "Rayong",
            "Roi Et",
            "Sa Kaeo",
            "Sakon Nakhon",
            "Samut Prakan",
            "Samut Sakhon",
            "Samut Songkhram",
            "Saraburi",
            "Satun",
            "Sing Buri",
            "Sisaket",
            "Songkhla",
            "Sukhothai",
            "Suphan Buri",
            "Surat Thani",
            "Surin",
            "Tak",
            "Trang",
            "Trat",
            "Ubon Ratchathani",
            "Udon Thani",
            "Uthai Thani",
            "Uttaradit",
            "Yala",
            "Yasothon"
        ]
    },
    "Togo": {
        "localite": [
            "Centre",
            "Kara",
            "Maritime",
            "Plateaux",
            "Savanes"
        ]
    },
    "Tonga": {
        "localite": [
            "Eua",
            "Ha'apai",
            "Niuas",
            "Tongatapu",
            "Vava'u"
        ]
    },
    "Trinidad and Tobago": {
        "localite": [
            "Couva-Tabaquite-Talparo",
            "Diego Martin",
            "Eastern Tobago",
            "Penal-Debe",
            "Point Fortin",
            "Port of Spain",
            "Princes Town",
            "Rio Claro-Mayaro",
            "San Fernando",
            "San Juan-Laventille",
            "Sangre Grande",
            "Siparia",
            "Tunapuna-Piarco",
            "Western Tobago"
        ]
    },
    "Tunisia": {
        "localite": [
            "Ariana",
            "Beja",
            "Ben Arous",
            "Bizerte",
            "Gabès",
            "Gafsa",
            "Jendouba",
            "Kairouan",
            "Kasserine",
            "Kebili",
            "Kef",
            "Mahdia",
            "Manouba",
            "Medenine",
            "Monastir",
            "Nabeul",
            "Sfax",
            "Sidi Bouzid",
            "Siliana",
            "Sousse",
            "Tataouine",
            "Tozeur",
            "Tunis",
            "Zaghouan"
        ]
    },
    "Turkey": {
        "localite": [
            "Adana",
            "Adıyaman",
            "Afyonkarahisar",
            "Ağrı",
            "Aksaray",
            "Amasya",
            "Ankara",
            "Antalya",
            "Ardahan",
            "Artvin",
            "Aydın",
            "Balıkesir",
            "Bartın",
            "Batman",
            "Bayburt",
            "Bilecik",
            "Bingöl",
            "Bitlis",
            "Bolu",
            "Burdur",
            "Bursa",
            "Çanakkale",
            "Çankırı",
            "Çorum",
            "Denizli",
            "Diyarbakır",
            "Düzce",
            "Edirne",
            "Elazığ",
            "Erzincan",
            "Erzurum",
            "Eskişehir",
            "Gaziantep",
            "Giresun",
            "Gümüşhane",
            "Hakkâri",
            "Hatay",
            "Iğdır",
            "Isparta",
            "Istanbul",
            "İzmir",
            "Kahramanmaraş",
            "Karabük",
            "Karaman",
            "Kars",
            "Kastamonu",
            "Kayseri",
            "Kırıkkale",
            "Kırklareli",
            "Kırşehir",
            "Kilis",
            "Kocaeli",
            "Konya",
            "Kütahya",
            "Malatya",
            "Manisa",
            "Mardin",
            "Mersin",
            "Muğla",
            "Muş",
            "Nevşehir",
            "Niğde",
            "Ordu",
            "Osmaniye",
            "Rize",
            "Sakarya",
            "Samsun",
            "Siirt",
            "Sinop",
            "Sivas",
            "Şırnak",
            "Tekirdağ",
            "Tokat",
            "Trabzon",
            "Tunceli",
            "Uşak",
            "Van",
            "Yalova",
            "Yozgat",
            "Zonguldak"
        ]
    },
    "Turkmenistan": {
        "localite": [
            "Ahal Region",
            "Ashgabat",
            "Balkan Region",
            "Dasoguz Region",
            "Lebap Region",
            "Mary Region"
        ]
    },
    "Tuvalu": {
        "localite": [
            "Funafuti",
            "Nanumanga",
            "Nanumea",
            "Niulakita",
            "Niutao",
            "Nui",
            "Nukufetau",
            "Nukulaelae",
            "Vaitupu"
        ]
    },
    "Uganda": {
        "localite": [
            "Central Region",
            "Eastern Region",
            "Northern Region",
            "Western Region"
        ]
    },
    "Ukraine": {
        "localite": [
            "Cherkasy Oblast",
            "Chernihiv Oblast",
            "Chernivtsi Oblast",
            "Dnipropetrovsk Oblast",
            "Donetsk Oblast",
            "Ivano-Frankivsk Oblast",
            "Kharkiv Oblast",
            "Kherson Oblast",
            "Khmelnytskyi Oblast",
            "Kiev",
            "Kirovohrad Oblast",
            "Kyiv Oblast",
            "Luhansk Oblast",
            "Lviv Oblast",
            "Mykolaiv Oblast",
            "Odessa Oblast",
            "Poltava Oblast",
            "Rivne Oblast",
            "Sumy Oblast",
            "Ternopil Oblast",
            "Vinnytsia Oblast",
            "Volyn Oblast",
            "Zakarpattia Oblast",
            "Zaporizhzhia Oblast",
            "Zhytomyr Oblast"
        ]
    },
    "United Arab Emirates": {
        "localite": [
            "Abu Dhabi",
            "Ajman",
            "Dubai",
            "Fujairah",
            "Ras Al Khaimah",
            "Sharjah",
            "Umm Al Quwain"
        ]
    },
    "United Kingdom": {
        "localite": [
            "England",
            "Scotland",
            "Wales",
            "Northern Ireland"
        ]
    },
    "United localite of America": {
        "localite": [
            "Alabama",
            "Alaska",
            "Arizona",
            "Arkansas",
            "California",
            "Colorado",
            "Connecticut",
            "Delaware",
            "Florida",
            "Georgia",
            "Hawaii",
            "Idaho",
            "Illinois",
            "Indiana",
            "Iowa",
            "Kansas",
            "Kentucky",
            "Louisiana",
            "Maine",
            "Maryland",
            "Massachusetts",
            "Michigan",
            "Minnesota",
            "Mississippi",
            "Missouri",
            "Montana",
            "Nebraska",
            "Nevada",
            "New Hampshire",
            "New Jersey",
            "New Mexico",
            "New York",
            "North Carolina",
            "North Dakota",
            "Ohio",
            "Oklahoma",
            "Oregon",
            "Pennsylvania",
            "Rhode Island",
            "South Carolina",
            "South Dakota",
            "Tennessee",
            "Texas",
            "Utah",
            "Vermont",
            "Virginia",
            "Washington",
            "West Virginia",
            "Wisconsin",
            "Wyoming"
        ]
    },
    "Uruguay": {
        "localite": [
            "Artigas",
            "Canelones",
            "Cerro Largo",
            "Colonia",
            "Durazno",
            "Flores",
            "Florida",
            "Lavalleja",
            "Maldonado",
            "Montevideo",
            "Paysandú",
            "Río Negro",
            "Rivera",
            "Rocha",
            "Salto",
            "San José",
            "Soriano",
            "Tacuarembó",
            "Treinta y Tres"
        ]
    },
    "Uzbekistan": {
        "localite": [
            "Andijan Region",
            "Bukhara Region",
            "Fergana Region",
            "Karakalpakstan",
            "Namangan Region",
            "Navoiy Region",
            "Qashqadaryo Region",
            "Samarqand Region",
            "Sirdaryo Region",
            "Surxondaryo Region",
            "Tashkent",
            "Tashkent Region",
            "Xorazm Region"
        ]
    },
    "Vanuatu": {
        "localite": [
            "Malampa",
            "Penama",
            "Sanma",
            "Shefa",
            "Tafea",
            "Torba"
        ]
    },
    "Vatican City (Holy See)": {
        "localite": []
    },
    "Venezuela": {
        "localite": [
            "Amazonas",
            "Anzoátegui",
            "Apure",
            "Aragua",
            "Barinas",
            "Bolívar",
            "Carabobo",
            "Cojedes",
            "Delta Amacuro",
            "Falcón",
            "Federal Dependencies",
            "Guárico",
            "Lara",
            "Mérida",
            "Miranda",
            "Monagas",
            "Nueva Esparta",
            "Portuguesa",
            "Sucre",
            "Táchira",
            "Trujillo",
            "Vargas",
            "Yaracuy",
            "Zulia"
        ]
    },
    "Vietnam": {
        "localite": [
            "An Giang",
            "Bà Rịa–Vũng Tàu",
            "Bắc Giang",
            "Bắc Kạn",
            "Bạc Liêu",
            "Bắc Ninh",
            "Bến Tre",
            "Bình Định",
            "Bình Dương",
            "Bình Phước",
            "Bình Thuận",
            "Cà Mau",
            "Cần Thơ",
            "Cao Bằng",
            "Đà Nẵng",
            "Đắk Lắk",
            "Đắk Nông",
            "Điện Biên",
            "Đồng Nai",
            "Đồng Tháp",
            "Gia Lai",
            "Hà Giang",
            "Hà Nam",
            "Hà Nội",
            "Hà Tĩnh",
            "Hải Dương",
            "Hải Phòng",
            "Hậu Giang",
            "Hòa Bình",
            "Hồ Chí Minh (Saigon)",
            "Hưng Yên",
            "Khánh Hòa",
            "Kiên Giang",
            "Kon Tum",
            "Lai Châu",
            "Lâm Đồng",
            "Lạng Sơn",
            "Lào Cai",
            "Long An",
            "Nam Định",
            "Nghệ An",
            "Ninh Bình",
            "Ninh Thuận",
            "Phú Thọ",
            "Phú Yên",
            "Quảng Bình",
            "Quảng Nam",
            "Quảng Ngãi",
            "Quảng Ninh",
            "Quảng Trị",
            "Sóc Trăng",
            "Sơn La",
            "Tây Ninh",
            "Thái Bình",
            "Thái Nguyên",
            "Thanh Hóa",
            "Thừa Thiên–Huế",
            "Tiền Giang",
            "Trà Vinh",
            "Tuyên Quang",
            "Vĩnh Long",
            "Vĩnh Phúc",
            "Yên Bái"
        ]
    },
    "Yemen": {
        "localite": [
            "Abyan",
            "Aden",
            "Al Bayda",
            "Al Hudaydah",
            "Al Jawf",
            "Al Mahrah",
            "Al Mahwit",
            "Dhamar",
            "Hadhramaut",
            "Hajjah",
            "Ibb",
            "Lahij",
            "Ma'rib",
            "Raymah",
            "Saada",
            "Sana'a",
            "Shabwah",
            "Socotra",
            "Taiz"
        ]
    },
    "Zambia": {
        "localite": [
            "Central Province",
            "Copperbelt Province",
            "Eastern Province",
            "Luapula Province",
            "Lusaka Province",
            "Muchinga Province",
            "North-Western Province",
            "Northern Province",
            "Southern Province",
            "Western Province"
        ]
    },
    "Zimbabwe": {
        "localite": [
            "Bulawayo",
            "Harare",
            "Manicaland",
            "Mashonaland Central",
            "Mashonaland East",
            "Mashonaland West",
            "Masvingo",
            "Matabeleland North",
            "Matabeleland South",
            "Midlands"
        ]
    }
};