import { useEffect, useState } from 'react'
import { currentUser } from "kls-commons/service/userService";
import { SettingsHeader } from '../settings/settings';
import { SettingsSideBar } from "./settings-sidebar";
import { GS } from "kls-commons/state/global-state";
import { Button } from 'kls-ui';
import style from '../menu/style';

export function SettingsModule(props: any) {
  return <div>
    <div className="settings-modules">
      {props?.type !== 'applications' && props?.type !== 'courses' ? <SettingsHeader type={props?.type} image={currentUser().avatarUrl ?? ""} username={currentUser().fullName} /> : props?.type === 'applications' ? <div className='applications-settings-header'>Paramètres de l’application <span style={{color: '#2563EB'}}>Gestion des autorisation</span></div> : <div className='course-details-header'><div className='applications-settings-header'>Cours et tutoriels - Créez votre première Application</div><Button size='md' color='primary' styling='solid' label='Suivant' rightIcon='chevron_right' args={{ onClick: (e) => { console.log("courses") }, style: {height: '40px'} }} /></div>}
      <SettingsSideBar tab={props?.tab} type={props?.type} gs={GS} connected={props.connected} hintType={props.hintType} sethintType={props.sethintType}></SettingsSideBar>
    </div>
  </div>
}