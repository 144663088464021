interface RoleData {
  apiKey: string;
  userId: string;
  name: string;
  appname: string;
  orgname: string;
  description: string;
  profiles: any[];
  resources: any[];
}

interface RoleDataToUpdate {
  apiKey: string;
  userId: string;
  name: string;
  NewName: string;
  description: string;
  profiles: any[];
  resources: any[];
}


export async function createRole (roleData: RoleData)  {
  try {
    const response = await fetch("/karazal/ow-kls-extrat/usermanager/createFullGroupe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(roleData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Response from service create:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error; 
  }
};
export async function updateRole(roleData: RoleDataToUpdate) {
  try {
    const response = await fetch("/karazal/ow-kls-extrat/usermanager/updateFullGroupe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(roleData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Response from service update:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getAllRoles(apiKey, userId, domainName) {
  try {
    const formData = new URLSearchParams();
    formData.append('apiKey', apiKey);
    formData.append('userId', userId);
    /*formData.append('offset',offset);
    formData.append('limit',limit);
    formData.append('key',key);
    formData.append('name',name);
    formData.append('status',status);
    formData.append('reference',reference);
    formData.append('email',email);

*/
    formData.append('domainName', domainName);
   // formData.append('email', email);

    const response = await fetch("/karazal/ow-kls-extrat/usermanager/allgroup", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Response from service getAll:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
export async function deleteRole(apiKey, userId, groupName) {
  try {
    const formData = new URLSearchParams();
    formData.append('apiKey', apiKey);
    formData.append('userId', userId);
    formData.append('GroupName', groupName);

    const response = await fetch("/karazal/ow-kls-extrat/usermanager/DeleteGroup", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("Response from service delete:", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getDetailsRole(apiKey, userId, groupName) {
  try {
    const formData = new URLSearchParams();
    formData.append('apiKey', apiKey);
    formData.append('userId', userId);
    formData.append('GroupName', groupName);

    const response = await fetch("/karazal/ow-kls-extrat/usermanager/groupdetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    // console.log("Response from service getDetails :", responseData);

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
