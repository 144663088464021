import { useEffect, useState } from "react"; 
import  t from "kls-i18n";
import { GS, saveGlobaleState } from "kls-commons/state/global-state";
import { b64DecodeUnicode } from "kls-commons/help/tools";
import { Model } from "kls-commons/types/application-model";
import { Input, Modal } from "kls-ui";
import {Base64} from "js-base64" 
import "./kls-module.css"
import { useHistory } from "react-router-dom";
import { NewModel, createDirectory } from "./module.service";
import { getWorkingDirectorTree, listModules } from "kls-commons/service/repo-service";
import { WorkingDirTree } from "kls-commons/types/repo-model";
import { cleanAndFormatNameResource } from "kls-commons/service/resource-service";

const MAX_CARACTER_NAME = 40 ;

export function NewModelWizard({moduleName="" as string,onClose}:{moduleName?:string,onClose:Function}) { 
  
  let history= useHistory();
  let [newModel, setNewModel] = useState<NewModel>({
      module: {
          name : moduleName,
          title : '',
      },
      model: {
          name : '',
          title : '',
      }
  } as any);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [directories, setDirectories] = useState<string[]>([]);


  useEffect(
    () => {
      if( moduleName ) {
          getWorkingDirectorTree(moduleName).then(
              (tree : WorkingDirTree) => {
                  setDirectories(Object.keys(tree.children||{})) ;
              }
          )
      } else {
          listModules().then(
              modules => {
                  setDirectories(modules) ;
              }
          )
      }
    } , []
  )


  let onAction = async (name:string) => { 
      if (name === "close" || name === "cancel") {
          saveGlobaleState(GS); 
          onClose(); 
          return;
      }
      if( errorMessage ) return;

      if( !(moduleName?newModel.model.title:newModel.module.title) ) {
        setErrorMessage( "Veuillez saisir le nom " + (!moduleName?"de l'application ":"du module ") ) ;
        return;
      }

      if( !moduleName ) {
        newModel.model = {
          name : 'basic_module',
          title : 'Module de base',
        }
      }

      let repertoire = await createDirectory(newModel);

      if( repertoire ) {
          history.push( "/editService/" + newModel.module.name + "/" + newModel.model.name );
          onClose("ok");
      }
  };

  let onChange = async (value:string, path:string)=> {
    if( MAX_CARACTER_NAME && value.length > MAX_CARACTER_NAME ) {
      return ;
    }
    setErrorMessage( "" ) ;
    let listProperties = path.split( "." ) ;
    if( listProperties && listProperties.length===2 )
      newModel[listProperties[0]][listProperties[1]] = value
    if( listProperties[1] === 'title' ) {
        newModel[listProperties[0]]['name'] = cleanAndFormatNameResource( value ) ;
        
        if( !value ) return setErrorMessage( "Veuillez saisir le nom " + (!moduleName?"de l'application ":"du module ") ) ;
        if( value.length < 2 ) return setErrorMessage( "Le nom " + (!moduleName?"de l'application ":"du module ") + "est invalide" ) ;
  
        let directoryName:string = newModel[listProperties[0]]['name'] ;
        let incrementId:any = '' ;

        while( directories.includes( directoryName + incrementId ) ) { 
            if(!incrementId) {incrementId=1; continue;}
            incrementId++ ;
        }
        newModel[listProperties[0]]['name'] = directoryName + incrementId;
    }

    setNewModel( {...newModel} )
  }

  return (    
    <Modal  onClose={()=>onAction("cancel")} 
            title={!moduleName ?"Nouvelle application":"Nouveau module"} 
            icon=""
            leftBtnArgs={ {
                label:'Annuler',
                args:{onClick:()=>onAction("cancel")},
                color: 'neutral',
                size: 'sm',
                styling: 'outline'
            } } 
            rightBtnArgs={ {
                label:'Valider',
                args:{onClick:()=>onAction("ok")},
                color: 'primary',
                size: 'sm',
                styling: 'solid'  
            } }
            bodyStyle={ {minHeight:"fit-content"} }
    >
      <div className="create-app-modal-body">
            <div className="name-directory">
                <Input  label={!moduleName ?"Nom de l'application":"Nom du module"} size="sm" 
                        errorMessage={ errorMessage }
                        args={
                              {
                                placeholder:!moduleName ?"Nom de l'application":"Nom du module",
                                value:newModel[!moduleName ?"module":"model"].title||"",
                                onChange:(evt) => { 
                                    onChange(evt.target.value, (!moduleName ?"module":"model") + ".title" )
                                }
                              }
                        }  
                        charCounter={MAX_CARACTER_NAME}

                />
                <div className="id-directory">
                  <span>ID :</span>
                  <span>{ " " + newModel[!moduleName ?"module":"model"].name||""}</span>
                </div>
            </div>
      </div>

    </Modal>
  );
}

function refactorGenericResources(rsc: any, proto:Model, name: string, qn:string)  {
  let sqn = proto.modelName;
  console.log("sqn=", sqn);
  let lsi = sqn.lastIndexOf("/");
  let sName =  sqn.substring(lsi+1) ||  sqn;
  console.log("sName=", sName);
  let sNameLC  = sName.toLocaleLowerCase();
  let nameLC  = name.toLocaleLowerCase();
  
  console.log("refactorGenericResources "+ name+" qn="+qn+" rsc.name="+ rsc.name, rsc);
  if(rsc.initialXmlData) {
      rsc.initialXmlData = replaceNameAndQN(rsc.initialXmlData , sqn, sName, sNameLC, qn, name, nameLC);
     }
  if(rsc.resourceQN) { 
       rsc.resourceQN= replaceNameAndQN(rsc.resourceQN.toLowerCase() , sqn, sName, sNameLC, qn, name, nameLC);
    }
  if(rsc.root) {
         rsc.root=replaceNameAndQN(rsc.root , sqn, sName, sNameLC, qn, name, nameLC);
  }
  rsc.fileName =  replaceNameAndQN(rsc.fileName , sqn, sName, sNameLC, qn, name, nameLC);
  rsc.name =  replaceNameAndQN(rsc.name , sqn, sName, sNameLC, qn, name, nameLC);
  if(rsc.contentB64) {
    let dcdd = b64DecodeUnicode(rsc.contentB64);
    dcdd = replaceNameAndQN(dcdd, sqn, sName, sNameLC, qn, name, nameLC);
    console.log("dcdd", dcdd);
    rsc.contentB64=Base64.encode(dcdd);
  }

}
function replaceNameAndQN(s: string, sqn: string, sName: string, sNameLC: string, qn:string, name: string, nameLC: string) :string{
  if(!s) return s;
  return s.replace(sqn, qn).replace(capitalize(sName), capitalize(name)).replace(sName, name).replace(sNameLC, nameLC);
}

 



function capitalize(s: string) {
 if(!s) return "";
 return (s.substring(0, 1).toUpperCase()+s.substring(1)).trim();
}

