import { useState, useEffect } from "react";
import { CourseTabs, Tabs } from "kls-ui";
import './settings-sidebar.css'
import { AccessSecurity, Appearance, AccountDeletion, Performance, Roles, Workspace } from "../settings/settings";
import { OrganisationSettings, VerificationSettings, DomainSettings, Users, Groups, Strains, PaymentSettings, Support, OrganisationDeletion } from "./index";
import { CreateAppCourse } from "../on-boarding-pages/create-apps-course";
export function SettingsSideBar(props: any) {
  let [selectedTab, setSelectedTab] = useState(props?.tab);
  let [isSidebarOpen, setIsSidebarOpen] = useState(true);
  let [isSidebarContent, setIsSidebarcontent] = useState(false);
  useEffect(() => {
    setSelectedTab(props?.tab);
  }, [props?.tab]);

  const listParamOrganization = [
    { name: 'Organisation', label: <><span className="icon">corporate_fare</span>Organisation</>, component: <><OrganisationSettings /></> },
    { name: 'Verification', label: <><span className="icon">verified</span>Vérification</>, component: <VerificationSettings /> },
    { name: 'Domain name', label: <><span className="icon">language</span>Nom de domaine</>, component: <DomainSettings /> },
    { name: 'Users', label: <><span className="icon">people_alt</span>Utilisateurs</>, component: <Users hintType={props.hintType ? props.hintType : ""} sethintType={props.sethintType ? props.sethintType : ""} /> },
    { name: 'Groups', label: <><span className="icon">workspaces</span>Groupes</>, component: <Groups /> },
    { name: 'Strains', label: <><span className="icon">rule</span>Souches</>, component: <Strains /> },
    { name: 'Payments', label: <><span className="icon">payment</span>Paiements</>, component: <PaymentSettings /> },
    { name: 'Support and help', label: <><span className="icon">help_outline</span>Support et aide</>, component: <Support /> },
    { name: 'Organization deletion', label: < ><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression d’organisation</span> </>, component: <OrganisationDeletion /> },

  ]
  const listParamSettings = [
    { name: 'workspace', label: <><span className="icon">work_outline</span>Mon compte</>, component: <Workspace /> },
    { name: 'appearance', label: <><span className="icon">format_paint</span>Apparence</>, component: <Appearance /> },
    { name: 'accesssecurity', label: <><span className="icon">lock</span>Accès et sécurité</>, component: <AccessSecurity /> },
    { name: 'roles', label: <><span className="icon">rule</span>Rôles et permissions</>, component: <Roles /> },
    { name: 'performance', label: <><span className="icon">multiline_chart</span>Performance</>, component: <Performance /> },
    { name: 'delete', label: <><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression de compte</span></>, component: <AccountDeletion /> },

  ]

  const listParamApplications = [
    { name: 'application', label: <><span className="icon">bubble_chart</span>Application</>, component: <></> },
    { name: 'modules', label: <><span className="icon">apps</span>Modules</>, component: <></> },
    { name: 'schema', label: <><span className="icon">schema</span>Schéma relationnel</>, component: <></> },
    { name: 'app roles', label: <><span className="icon">workspaces</span>Rôles</>, component: <></> },
    { name: 'menus', label: <><span className="icon">menu</span>Menus</>, component: <></> },
    { name: 'access', label: <><span className="icon">login</span>Contrôles d’accès</>, component: <></> },
    { name: 'app delete', label: <><span className="icon red-600">remove_circle_outline</span><span className="red-600">Suppression d’application</span></>, component:<></> },
  ]

  const listParamCourses = [
    { name: 'create apps', label: <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><span style={{backgroundColor: selectedTab === 'create apps' ? '#2563EB' : '#D1D5DB', width: '6px', height: '6px', borderRadius: '50%'}}></span><span style={{color: selectedTab === 'create apps' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'create apps' ? '500' : '400'}}>Créer une application</span><span className="icon" style={{background: '#10B981', borderRadius: '50%', color: 'white'}}>check</span></div>, component: <CreateAppCourse /> },
    { name: 'explore apps', label: <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><span style={{backgroundColor: selectedTab === 'explore apps' ? '#2563EB' : '#D1D5DB', width: '6px', height: '6px', borderRadius: '50%'}}></span><span style={{color: selectedTab === 'explore apps' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'explore apps' ? '500' : '400'}}>Explorez le catalogue d'apps</span><span className="icon" style={{color: '#D1D5DB'}}>brightness_1</span></div>, component: <></> },
    { name: 'create module', label: <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><span style={{backgroundColor: selectedTab === 'create module' ? '#2563EB' : '#D1D5DB', width: '6px', height: '6px', borderRadius: '50%'}}></span><span style={{color: selectedTab === 'create module' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'create module' ? '500' : '400'}}>Créer un module</span><span className="icon" style={{color: '#D1D5DB'}}>brightness_1</span></div>, component: <></> },
    { name: 'design process', label: <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><span style={{backgroundColor: selectedTab === 'design process' ? '#2563EB' : '#D1D5DB', width: '6px', height: '6px', borderRadius: '50%'}}></span><span style={{color: selectedTab === 'design process' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'design process' ? '500' : '400'}}>Concevoir un processus</span><span className="icon" style={{color: '#D1D5DB'}}>brightness_1</span></div>, component: <></> },
    { name: 'design page', label: <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}><span style={{backgroundColor: selectedTab === 'design page' ? '#2563EB' : '#D1D5DB', width: '6px', height: '6px', borderRadius: '50%'}}></span><span style={{color: selectedTab === 'design page' ? '#1F2937' : '#6B7280', fontWeight: selectedTab === 'design page' ? '500' : '400'}}>Concevoir une page</span><span className="icon" style={{color: '#D1D5DB'}}>brightness_1</span></div>, component: <></> },
  ]

  const handleTabSelect = (tabName: string) => {
    setSelectedTab(tabName);
    setIsSidebarOpen(false)
    setIsSidebarcontent(true)
  };
  const handleBackArrow = () => {
    setIsSidebarOpen(true)
    setIsSidebarcontent(false)
  }
  const width = window.innerWidth;
  
  return (
    <>
      {width > 800 ?
        <div className='tab-container Desktop-version'>
          <div className='side-navbar' style={{width: props?.type !== 'courses' ? '240px' : '275px'}}>
            {props?.type !== 'courses' ? 
            <Tabs tabs={props?.type == 'settings' ? listParamSettings : props?.type === 'applications' ? listParamApplications : props?.type === 'organizationSettings' ? listParamOrganization : listParamCourses} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab}
              defSelected={"app"} /> : 
            <CourseTabs tabs={listParamCourses} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab} defSelected={"app"} />
            }
          </div>
          <div className="settings-content">
            {props?.type == 'settings' ? listParamSettings.find(tab => tab.name === selectedTab)?.component : props?.type == 'applications' ? listParamApplications.find(tab => tab.name === selectedTab)?.component : props?.type === 'organizationSettings' ? listParamOrganization.find(tab => tab.name === selectedTab)?.component :listParamCourses.find(tab => tab.name === selectedTab)?.component}
          </div>
        </div> :
        <div className='tab-container mobile-version'>

          {isSidebarOpen &&
            <div className='side-navbar'>
              <div className="settings-sidebar-mobile-header">
                <a href="#"><span className="icon arrow-back-settings">arrow_back</span></a>
                <h1>{props?.type === 'applications' ? 'Paramètres de l’application' : props?.type !== 'courses' ? 'Paramètres du compte' : 'Créez votre première Application'}</h1>
              </div>
              <Tabs tabs={props?.type == 'settings' ? listParamSettings : props?.type == 'applications' ? listParamApplications : props?.type === 'organizationSettings' ? listParamOrganization : listParamCourses} size="medium" align={'vertical'} onSelect={handleTabSelect} current={selectedTab}
                defSelected={"app"} />
            </div>}
          {isSidebarContent &&
            <div className="settings-content mobile-version-content">
              {props?.type !== 'courses' && <span className="icon arrow-back-settings" onClick={handleBackArrow}>arrow_back</span>}
              {props?.type == 'settings' ? listParamSettings.find(tab => tab.name === selectedTab)?.component : props?.type == 'applications' ? listParamApplications.find(tab => tab.name === selectedTab)?.component : props?.type === 'organizationSettings' ? listParamOrganization.find(tab => tab.name === selectedTab)?.component :listParamCourses.find(tab => tab.name === selectedTab)?.component}
            </div>}
        </div>}
    </>
  )
}



