export const typeOfLocal = [
    { "label": "Siège social", "value": "Siège social" },
    { "label": "Bureaux", "value": "Bureaux" },
    { "label": "Salles de réunion", "value": "Salles de réunion" },
    { "label": "Ateliers ou usines", "value": "Ateliers ou usines" },
    { "label": "Entrepôts", "value": "Entrepôts" },
    { "label": "Espaces de vente au détail", "value": "Espaces de vente au détail" },
    { "label": "Laboratoires", "value": "Laboratoires" },
    { "label": "Espaces de restauration", "value": "Espaces de restauration" },
    { "label": "Salles informatiques", "value": "Salles informatiques" },
    { "label": "Espaces de détente et de loisirs", "value": "Espaces de détente et de loisirs" },
    { "label": "Espaces extérieurs", "value": "Espaces extérieurs" }
]