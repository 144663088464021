import React, { useEffect, useState } from 'react'
import { ResourceContent, WorkingDirTree } from "kls-commons/types/repo-model";
import { getWorkingDirectorTree, writeKlsResource } from 'kls-commons/service/repo-service';
import { GS, saveGlobaleState } from 'kls-commons/state/global-state';
import { useHistory, useParams } from 'react-router-dom';
import { ModelContentMenu } from '../menu/menu';
import { FormViewEditor } from "kls-view-editor/view-editor";  
import { FormSearchEditor } from "kls-search-editor/";
import { BpmnEditor } from "kls-bpmn-editor/process-editor/bpmn-editor"; 
import { Header } from '../layout/header';
import { LocalModelesSearch } from './module-navigation';
import { ParameterModule } from './module-parameter';
import { MenuEditor } from "kls-generic-editor/menu-editor";
import { KConfigEditor } from "kls-generic-editor/kconfig-editor";
import { menuEditorSchemaRoot } from "kls-generic-editor/menu-editor-schema";
import { configsEditorSchemaRoot } from "kls-generic-editor/kconfig-editor-schema";
import { createResourceQN, getResourceName, getResourceType, onStartEdit } from './module.service';
import { Loading } from 'kls-ui';
import { ReportEditor } from 'kls-report-editor';
import { validationController } from '../validation/validation-controller';
import { ValidationResult } from '../validation/validation-result';
import ValidationPanel from '../validation/validation-panel';
import {ResourceCoordinate} from 'kls-validation/types/validation.type';
import SplitPane from 'react-split-pane';
 
const emptyWDT : WorkingDirTree = {
  name: ''
}
const emptyResourceContent: ResourceContent = {
  content: ''
}

const ModuleEditor:React.FC<{}> = () => {

    let {moduleName, modelName, resourceType, resourceName} = useParams() as any;  
    const [currentModel, setCurrentModel] = useState<WorkingDirTree>(emptyWDT);
    const [currentResource, setCurrentResource] = useState<string>("");
    const [resourceContent, setResourceContent] = useState(emptyResourceContent);
    const [loading, setLoading] = useState<boolean>(false);
    const [spotResource, setSpotResource] = useState<string>();

    const history = useHistory();

    useEffect(
      () => {
        setLoading(true)

        console.log( 'resourceName : ' , moduleName, modelName, resourceType, resourceName )

        getWorkingDirectorTree(moduleName).then(
          (tree:WorkingDirTree) => {

            let firstModelName = "" ;
            for(let k of Object.keys((tree.children)||{})) {
                let c = tree?.children?.[k];
                if(c && c.directory) {
                    if( modelName === c.name ) { firstModelName = "" ; break ; }
                    if( !firstModelName ) { firstModelName = c.name; }
                }
            }

            modelName = firstModelName||modelName ;

            if(tree.children) { 
              setCurrentModel(tree.children[modelName]);
              console.log( "tree.children[modelName] ", modelName, tree, tree.children[modelName] );
              GS.currentModuleName=moduleName;
              GS.currentModelName=modelName;
              saveGlobaleState(GS);
            }

            setLoading(false) ;
          }
        ).catch(
          () => {
            setLoading(false)
          }
        )


        if( moduleName && modelName && resourceName ) {
          console.log( "debuging handleChangeResource before : ", moduleName , modelName , resourceName, createResourceQN(moduleName, modelName, resourceType, resourceName) )
          handleChangeResource( createResourceQN(moduleName, modelName, resourceType, resourceName) ) ;
          setLoading(false) ;
          return ;
        } 

        setCurrentResource( "" ) ;

      } , [moduleName, modelName, resourceType, resourceName]
    )

    const onSaveResource = async (rc: ResourceContent) => {
      if (!rc || !rc.content || !rc.info) {
        return;
      }
      console.log("debuging handleChangeResource  onSaveResource", rc);
      let validationResult : ValidationResult | null = await validationController.verifiyResource(rc, currentResource);
      if(validationResult && validationResult.error && validationResult.blocking) { 
          return;
      }
  
      if (rc.extraContent) {
        Object.keys(rc.extraContent).forEach(k => {
          let ec = rc.extraContent![k];
          let path = rc.info!.filePath.replace(rc.info!.fileName, k);
          writeKlsResource(GS.currentModuleName, path, ec);
        })
      }
      return await writeKlsResource(GS.currentModuleName, rc.info.filePath, rc.content);
    }

    useEffect(
      () => {
        console.log( "debuging resource content", resourceContent )
      } , [resourceContent]
    )

    const onSpotValidationError = (resourceLocation: ResourceCoordinate ) => {
      console.log("debuging handleChangeResource  onSpotValidationError",resourceLocation, currentResource);
      if(resourceLocation.resourceBundlePath !== currentResource) {
          onChangeResourceLocation(resourceLocation.resourceBundlePath);
          if(resourceLocation.resourcePath)
              setSpotResource(resourceLocation.resourcePath);
      } else 
          setSpotResource(resourceLocation.resourcePath);
    }


    const handleChangeResource = async (resource:any) => {
      console.log( "debuging handleChangeResource ", resource ) ;
      setCurrentResource( resource ) ;
      let updatedRc:ResourceContent|undefined = await onStartEdit( GS.currentModuleName, resource ) ; 

      console.log( "debuging updatedRc : ", updatedRc ) ;

      if( updatedRc ){
        setResourceContent( updatedRc ) ;
        console.log("DBGX FETCH END ", resource, updatedRc);
      }

      return updatedRc ;
    }

    useEffect(
      () => {
        console.log( "currentResource : " , currentResource )
      } , [currentResource]
    )
    
    const onChangeResourceLocation = async (resource:any) => {
      history.push( "/editService/" + moduleName + '/' + currentModel.name + '/' + getResourceType(resource) + '/' + getResourceName(resource) );
    }

    return <>

        <Header>
            <div style={headerContent}>
              <LocalModelesSearch module={moduleName} model={currentModel.name}/>
              {   currentModel && <ModelContentMenu moduleName={moduleName} model={currentModel} currentResource={currentResource} handleChangeResource={(r)=>onChangeResourceLocation(r.value)}/>   }
              <ParameterModule module={moduleName}/>
            </div>
        </Header>

        {
            loading && <Loading message='Nous configurons votre application...'/>
        }

        <div className='studio-container'>
          
          <SplitPane split='horizontal' defaultSize={'100%'} minSize={'100%'}>
            <>
              {
                  currentResource &&
                  <div className="service-editor">
                      <div className="file-editor">
                          {currentResource.endsWith("-menu.xml") &&  !currentResource.includes("/view-") && <MenuEditor onSave={onSaveResource} rubrique={currentResource} resourceContent={resourceContent} schema={menuEditorSchemaRoot}></MenuEditor>} 

                          {currentResource.endsWith("config.xml") && !currentResource.includes("/view-") && <KConfigEditor onSave={onSaveResource} rubrique={currentResource} resourceContent={resourceContent} schema={configsEditorSchemaRoot}></KConfigEditor>} 

                          {currentResource.includes("/search/") &&  <FormSearchEditor  moduleName={moduleName} modelName={modelName}  onSave={onSaveResource}    resourceContent={resourceContent} ></FormSearchEditor>}
                          {currentResource.includes("/taskList") && currentResource.endsWith(".xml") &&  <FormSearchEditor moduleName={moduleName} modelName={modelName}  onSave={onSaveResource}    resourceContent={resourceContent} />}

                          {currentResource.endsWith(".bpmn") &&  <BpmnEditor  onSave={onSaveResource}    resourceContent={resourceContent}        ></BpmnEditor>}
                          {currentResource.includes("/report/") &&  <ReportEditor  onSave={onSaveResource}    resourceContent={resourceContent}        ></ReportEditor>}

                          {(currentResource.endsWith(".xml") && currentResource.includes("/view-")) &&  <FormViewEditor onSave={onSaveResource} moduleName={moduleName} modelName={modelName}   resourceContent={resourceContent} spotXpath={spotResource} ></FormViewEditor>}   
                      </div>
                  </div>
              }
            </>
            <ValidationPanel activeRubrique={currentResource} activeModule={modelName} onMoveTo={onSpotValidationError} />

          </SplitPane>

        </div>
    </>
}

const headerContent = {
  display:'flex', 
  justifyContent:'space-between', 
  alignItems: 'center', 
  flexGrow: 1, 
  gap: "20px"
}



export default  ModuleEditor ;