import { toYYYMMDDHHMM } from "kls-commons/help/dates";
import { cloneModel, fetchKlsResource, getModuleJson, getWorkingDirectorTree, listModules, listTags, writeKlsResource } from "kls-commons/service/repo-service";
import { getNameOfResource, getTenant } from "kls-commons/service/resource-service";
import { currentUser } from "kls-commons/service/userService";
import { GS, getFromStorage, saveGlobaleState } from "kls-commons/state/global-state";
import { Model } from "kls-commons/types/application-model";
import { ResourceContent, WorkingDirTree } from "kls-commons/types/repo-model";

export function generateResourcesFromModel(model: WorkingDirTree): any {
    console.log("generateResourcesFromModel", model);
    let modelName = model.info?.filePath;
    model.children =model.children || {}; 
    let files = Object.keys(model.children).map(k=>model.children![k]);
    files = files.flatMap((x) => {
        if(x.name==="process" || x.name==="search" ) {
            return Object.keys(x.children!).map(k=>x.children![k])|| [];
        }
        if(x.name==="report") {
            return Object.keys(x.children!).filter(k=>Object.values(x.children![k].children as {[key:string]:WorkingDirTree}).find((f)=>f?.name==="default")).map(k=>Object.values(x.children![k].children as {[key:string]:WorkingDirTree}).find(((f)=>f?.name?.endsWith('.xml') && !f.name.includes('qrcodes') ))) as WorkingDirTree[];
        }
        return [x];
    })


    files.forEach(async (f) => {

        if(f.info?.filePath && !f.directory &&  !f.model )  {
            f.label = await getNameOfResource(f.info?.filePath);
        }

    }); 
    console.log("files / ", files);

    return  [

        {
            id: "view", label:"Page", icon: "web_stories",
            newElement : { label: "Ajouter nouvelle page" },
            children: files.filter(x=>x.name.startsWith("view-") && x.name.endsWith(".xml")).map(v => { 
                return { type: "view", 
                    name: v.name, label:v.label ?? v.name.replace("view-","").replace(".xml",""), 
                    icon: "web_stories", color: "#EF4444",
                    resourceQN:  v.info?.filePath,
                    link: "/edit/edit-view/" + v.name , 
                };
            }),
            modelName: modelName
        }
        ,
        {
            id: "process", label:"Processus", icon: "account_tree",
            newElement : { label: "Nouveau processus" },
            modelName:  modelName,
            children: files.filter(x=>x.name.endsWith(".bpmn")).map(p => { return { 
                
                resourceQN:  p.info?.filePath, type: "bpmn", name: p.name, label: p.label ??  p.name.replace(".bpmn",""), icon: "account_tree",  color: "#10B981", link: "/edit/edit-process/" + p.name }; })
        },
        {
            id: "search", label:'Recherche', icon: "search", 
            newElement : { label: "Ajouter nouvelle recherche" },
            children: files.filter(x=>x.info?.filePath.includes("/search/"))?.map(p => {
                return {
                    type: "search", name: p.name, label: p.label ?? p.name,
                    icon: "search",  color: "#E97316", link: "/edit/edit-search/" + p.name, 
                     resourceQN: p.info?.filePath
                };
            })
        },
        {
            id: "tasklist",label:"Tasklist", icon: "checklist", 
            newElement : { label: "Ajouter nouvelle tasklist" },
            children: files.filter(x=>x.name.includes("taskList"))?.map(p => { return {  
                resourceQN:  p.info?.filePath,
                type: "search", name: p.name, label: p.label ?? p.name, 
                icon: "checklist", color: "#0EA5E9"}; })
        },
        {
            id: "report", label:"Rapport", icon: "summarize",  
            newElement : { label: "Ajouter nouveau rapport" },
            modelName:  modelName,
            children: files.filter(x=>x.info?.filePath.includes('/report/') && x.name.endsWith(".xml"))?.map(p => { return { 
                resourceQN:  p.info?.filePath,
                type: "report", name: p.name, label:  p.label ?? p.name, icon: p.name.startsWith('notif-') ?"notifications":"summarize", color: p.name.startsWith('notif-') ? '#F43F5E' : "#8B5CF6", link: "/edit/edit-report/" + p.name }; })
        },
        {
            id: "functions", label:"Fonctions", icon: "functions",  
            newElement : { label: "Ajouter nouvelle fonction" },
            modelName:  modelName,
            children: files.filter(x=>x.name.endsWith("-report.xml"))?.map(p => { return { 
                resourceQN:  p.info?.filePath,
                type: "report", name: p.name, label: p.label ?? p.name, icon: "functions", color: "#EC4899", link: "/edit/edit-report/" + p.name }; })
        },
        {
            id: "configuration", label:"Configurations", icon: "settings", 
            modelName: modelName,
            children: [{
                label:'Menus', icon: "settings", 
                children: files.filter(x=>x.name.endsWith("-menu.xml"))?.map(p => {
                    return {
                        resourceQN:  p.info?.filePath,
                        type: "menu", name: p.name, label: p.label ?? p.name,
                        icon: "fa fa-bars", link: "/edit/edit-menu/" + p.name
                    };
                })
            },
            {
                label: "Option Lists", icon: "fa fa-list-ol",
                modelName: modelName,
                children:files.filter(x=>x.name.endsWith("-dynamiclists.xml"))?.map(p => { 
                    return { type: "option-list", name: p.name, label: p.label ?? p.name,  resourceQN:  p.info?.filePath, icon: "manifiying-glass.svg", link: "/edit/edit-option-list/" + p.name }; })
            },
            {
                label: "KConfig", icon: "fa fa-cog", type: "kconfig", link: "/edit/edit-config",
                modelName:  modelName,
                resourceQN:  model.info?.filePath+"/kconfig.xml",
            }
            ]
        },
    ];
}

export async function loadAllModule():Promise<Directory[]> {
    let modulesNameList = await listModules() ;

    let modulesList:Directory[] = [] ;
    
    await Promise.all(
        modulesNameList.map(async (moduleName) => {
          let moduleLabel: string = await getLabelDirectory(moduleName);
          let moduleItem: Directory = { title: moduleLabel, name: moduleName };
          modulesList.push(moduleItem);
        })
    );

    return modulesList ;
}

export async function  loadAllModel(callback:Function, moduleName: string) {
    const tree : WorkingDirTree  =  await getWorkingDirectorTree(moduleName || GS.currentModuleName);
    let models: Model[] = [];
    const models_ = await mapTreeToModels(tree,  models);
    callback(models_);
}

export async function mapTreeToModels(tree: WorkingDirTree, models: Model[]) {

    if(tree.model) {
        //  const pattern = new RegExp(`klsapp/[a-z0-9]+/${GS.currentModuleName}/`, 'g');
        const pattern = new RegExp(`klsapp/[a-z0-9]+/`, 'g');
      
        let moduleName = tree.info!.filePath.replaceAll(pattern, '');
        moduleName = moduleName.replace('/'+tree.info!.fileName, '');
        moduleName = moduleName.replaceAll(/\//g, '::'); 
        const label = await getLabelDirectory(moduleName, tree.name) ;

        let model: Model = {
            installionTime: toYYYMMDDHHMM(new Date(tree.info!.creationTime)),
            changeTime: tree.info!.changeTime ,
            label: label, 
            modelName: tree.name,
            moduleName: moduleName, 
            description: "", 
        }; 
        models.push(model);

    }
    if(tree.children) {
        for(let k of Object.keys(tree.children)) {
            let c= tree.children[k];
            if(c.directory) {
                console.log( "debuging call mapTreeToModels " , c, models )
                models = await mapTreeToModels(c, models);
            }
        }
    }
    return models;
}

export const onStartEdit= async (moduleName:string, resource?:string)  => { 
    if(!resource) return;
    return fetchKlsResource(moduleName, resource)
} 

export async function getLabelDirectory( module:string , model?:string ):Promise<string> {
    let moduleJson:any = null ;
    let directoryLabel:string = '' ;
    try{
        moduleJson = await getModuleJson( module ) ;
        directoryLabel = moduleJson?.title ;
        
        if( model ) {
            
            directoryLabel = moduleJson?.children?.find(
                m => m.name === model
            )?.title ;
        }
    } catch(e) {
    }
    if( !directoryLabel )
        directoryLabel = model?model:module ;
    return directoryLabel ;
}

// export async function createDirectory( newModel:NewModel ): Promise<ResourceContent|null>{
//     const templates = await getFromStorage('TEMPLATE_MODELS') ;
//     if( !templates || !templates[0] ) return null ;
//     console.log( "debuging " , newModel, templates[0].modelQN, templates[0].name )
//     let repertoire = await cloneModel(newModel.module.name, newModel.model.name, templates[0].modelQN, templates[0].name );
//     if( !repertoire ) return null ;
//     let applicationsJson:any ;

//     try{
//         let klsResource:ResourceContent = await fetchKlsResource( newModel.module.name, "klsapp/" + getTenant() + '/applications.json' ) ;
//         applicationsJson = JSON.parse(klsResource.content||'') ;

//         if( !applicationsJson[newModel.module.name] ) {
//             applicationsJson[newModel.module.name] = {} ;
//         }

//         applicationsJson[newModel.module.name].title = newModel.module.title ;

//         if( !applicationsJson[newModel.module.name].children ) {
//             applicationsJson[newModel.module.name].children = {} ;
//         }

//         applicationsJson[newModel.module.name].children[newModel.model.name] = newModel.model.title ;
//     } catch(e) {
//         console.log( "fetchKlsResource error : ", e ) ;
//     }

//     saveGlobaleState(GS);
//     let res = await writeKlsResource( '', "klsapp/" + getTenant() + '/module.json' , JSON.stringify( applicationsJson ) ) ;
    
//     return res;
// }


export async function appConfiguration (moduleName:string){
    let moduleJson;
    try{
        let klsResource:ResourceContent = await fetchKlsResource( moduleName, "klsapp/" + getTenant() + "/" + moduleName + '/module.json' ) ;
        moduleJson = JSON.parse(klsResource.content||'') ;
        moduleJson.owner = currentUser()
    } catch(e) {
        console.log( "fetchKlsResource error : ", e ) ;
    }
    return moduleJson
}
export async function saveappConfiguration (appdata,moduleName:string){

    saveGlobaleState(GS);
    let res = await writeKlsResource( moduleName, "klsapp/" + getTenant() + "/" + moduleName + '/module.json' , JSON.stringify( appdata ) ) ;
    
}


export async function createDirectory( newModel:NewModel ): Promise<ResourceContent|null>{
    const templates = await getFromStorage('TEMPLATE_MODELS') ;
    if( !templates || !templates[0] ) return null ;
    console.log( "debuging " , newModel, templates[0].modelQN, templates[0].name )
    let repertoire = await cloneModel(newModel.module.name, newModel.model.name, templates[0].modelQN, templates[0].name );
    if( !repertoire ) return null ;
    let moduleJson:any = initModuleJson( newModel ) ;

    try{
        let klsResource:ResourceContent = await fetchKlsResource( newModel.module.name, "klsapp/" + getTenant() + "/" + newModel.module.name + '/module.json' ) ;
        moduleJson = JSON.parse(klsResource.content||'') ;
        moduleJson.children = [...(moduleJson.children||[]),initModelJson(newModel)] ;
    } catch(e) {
        console.log( "fetchKlsResource error : ", e ) ;
    }

    console.log( "moduleJson :", moduleJson ) ;
    moduleJson.owner = currentUser()

    saveGlobaleState(GS);
    let res = await writeKlsResource( newModel.module.name, "klsapp/" + getTenant() + "/" + newModel.module.name + '/module.json' , JSON.stringify( moduleJson ) ) ;
    
    return res;
}

const initModelJson = ( newModule:NewModel ) => {
    return {
        name: newModule.model.name,
        title: newModule.model.title,
    }
}

const initModuleJson = ( newModule:NewModel ) => {
    return {
        name: newModule.module.name,
        title: newModule.module.title,
        children: [ initModelJson( newModule ) ]
    }
}

export const getLastVersion = async ( moduleName:string ):Promise<string> => {
    const listTag = await listTags(moduleName, '', '', true) ;
    const lastVersion = listTag[0]?.tag.replace("refs/tags/", "") ;
    return lastVersion ;
} 

export interface VersionType{
    prod : string ,
    preprod : string ,
}

export const getActualVersion = async ( moduleName:string ):Promise<VersionType> => {
    let moduleJson:any = null ;

    moduleJson = await getModuleJson( moduleName ) ;
    if( !moduleJson.version ) {
        moduleJson.version = {} ;
        let actualVersion = await getLastVersion( moduleName ) ; 
        if( !actualVersion ) {
            actualVersion = "1.0.0" ;
        }
        moduleJson.version.prod = actualVersion ;
        moduleJson.version.preprod = actualVersion ;
        
        writeKlsResource( moduleName, "klsapp/" + getTenant() + "/" + moduleName + '/module.json' , JSON.stringify( moduleJson ) ) ;
    }
    return moduleJson.version ;
} 

export const setModelJson = async ( moduleName:string, property:string, value:any ) => {
    let moduleJson:any = null ;
    moduleJson = await getModuleJson( moduleName ) ;

    moduleJson[property] = value ;
    
    writeKlsResource( moduleName, "klsapp/" + getTenant() + "/" + moduleName + '/module.json' , JSON.stringify( moduleJson ) ) ;
}




export type NewModel = {
    module: Directory,
    model: Directory
}

export type Directory = {
    name : string,
    title : string,
    dateCreation ?: Date,
    parent ?: string 
}



export const getResourceType = ( currentResource:string ) => {
    if( currentResource.includes("view-") && currentResource.endsWith(".xml") ) {
        return "view" ;
    } else if( currentResource.endsWith(".bpmn") ) {
        return "process" ;
    } else if( currentResource.includes("taskList") ) {
        return "tasklist" ;
    } else if( currentResource.includes("/search/") ) {
        return "search" ;
    } else if( currentResource.includes("/report/") ) {
        return "report" ;
    } else if( currentResource.endsWith("-menu.xml") ) {
        return "configuration" ;
    } else if( currentResource.endsWith("-dynamiclists.xml") ) {
        return "configuration" ;
    } else if( currentResource.endsWith("/kconfig.xml") ) {
        return "configuration" ;
    }
}

export const getResourceName = ( resource:string ) => {
    const resourceArr = resource.split('/') ;

    return resourceArr[resourceArr.length-1]||'' ;
}

export const createResourceQN = ( module:string, model:string, resourceType:string, resourceName:string ):string => {
    if(resourceType==='report'){
        return 'klsapp/' + getTenant() + '/' + module + '/' + model + '/' + resourceType + '/' + resourceName.slice(0,-11) + '/' + resourceName ;
    
    }
    if(resourceType==='tasklist'){
        return 'klsapp/' + getTenant() + '/' + module + '/' + model  + '/' + resourceName ;
    
    }
    return 'klsapp/' + getTenant() + '/' + module + '/' + model + '/' + (!resourceType || resourceType==='view'?'':(resourceType + '/')) + resourceName ;
}