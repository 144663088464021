import { useState, useEffect, useRef } from "react";
import { CourseCard } from 'kls-ui';
import { useHistory } from "react-router-dom";

export function CourseDetails(props: any) {
  const history = useHistory();
  const allCourseDetails = () =>{
    // history.push("/courses");
  }

  return (
    <div className="course-details">
      <div className="course-cards">
        <h3 className="title">Cours et tutoriaux</h3>
        <span className="sub-head" onClick={allCourseDetails}>Afficher tous</span>
      </div>
      <div className="course-section">
        <div /*onClick={() => history.push('/courses/1')*/><CourseCard content="Créez votre première Application" color="#3B82F6" /></div>
        <CourseCard content="Créez vos pages avec La bibliothèque de composants" color="#10B981" />
        <CourseCard content="Concevez vos processus selon Les standards BPMN" color="#F97316" />
        <CourseCard content="Créez et configurez des Groupes d’utilisateurs" color="#8B5CF6" />
      </div>
    </div>
  );
}
