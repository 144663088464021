import { postMap } from 'kls-commons/service/karaz-remote-action'

export async function WorkspaceService(userID: any) {

    let actionUrl = '/karazal/ow-kls-extrat/usermanager/userinfos';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");

    urlencoded.append("userId", userID);
    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}

export async function WorkspaceUpdateService(userID: any,civilitydropdown:any,selectedImage:any,organizationFirstname:any,countryValue:any,languageValue:any,utcValue:any,organizationname:any) {
    let actionUrl = '/karazal/ow-kls-extrat/usermanager/updateuserinfos';

    console.log("userID.service",userID)
    console.log('civilitydropdown.service',civilitydropdown)
    console.log('selectedImage.service',selectedImage)
    console.log('organizationFirstname.service',organizationFirstname)
    console.log('organizationname.service',organizationname)
    console.log('countryValue.servicee',countryValue)
    console.log('languageValue.service',languageValue)
    console.log('utcValue.service',utcValue)

    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userID);
    urlencoded.append("nom", organizationname);
    urlencoded.append("prenom", organizationFirstname);
    urlencoded.append("civilite", civilitydropdown);
    urlencoded.append("langue", languageValue);
    urlencoded.append("pays", countryValue );
    urlencoded.append("avatar", selectedImage);
    urlencoded.append("fuseauHoraire", utcValue);
    
    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;

}