

export function EmptyResult( {resource}:{resource:string} ) {
    if( !resource ) {
      return <></>
    }
  
    let resourceLabel = '' ; 
  
    if( resource === 'view' ) {
      resourceLabel = 'page'
    }
    if( resource === 'process' ) {
      resourceLabel = 'processus'
    }
    if( resource === 'search' ) {
      resourceLabel = 'recherche'
    }
    if( resource === 'tasklist' ) {
      resourceLabel = 'corbeille'
    }
    if( resource === 'configuration' ) {
      resourceLabel = 'menu'
    }
  
    return <div className="empty-result-container">
      <span className="material-icon" style={{height:'32px', width:'32px'}}>
          <img src='/icons/search_off.svg' alt={'empty'}/>        
      </span>
      <div className="empty-result-message">
          <span className="empty-result-message-top">{ "Aucun"+(["page","recherche", "corbeille"].includes(resourceLabel)?"e ":" " ) + resourceLabel} pour l'instant</span>
          <span className="empty-result-message-bottom">La liste des {resourceLabel}s créées sera affichée ici</span>
      </div>
    </div>
  
} 
