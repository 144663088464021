import { postMap } from "kls-commons/service/karaz-remote-action";

export async function UserAccessSecurityService(userID: any) {
    let actionUrl = '/karazal/ow-kls-extrat/usermanager/Accessandsecurity';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userID);
    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}

export async function UserUpdateAccessSecurityService(userID: any, doubleAuthen: any, telmobile: any, reseauSocialLink: any, reseauSocialType: any, emailsecond: any ) {
    let actionUrl = '/karazal/ow-kls-extrat/usermanager/updateAccessandsecurity';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userID);
    urlencoded.append("doubleAuthen", doubleAuthen);
    urlencoded.append("telmobile", telmobile);
    urlencoded.append("reseauSocialLink", reseauSocialLink);
    urlencoded.append("reseauSocialType", reseauSocialType);
    urlencoded.append("emailsecond", emailsecond);

    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}

export async function UserChangePassword(userId: any, actualPassword: any, newPassword: any, confirmPassword: any) {
    let actionUrl = '/karazal/ow-kls-extrat/usermanager/changepassword';
    let urlencoded = new URLSearchParams();
    urlencoded.append("apiKey", "AB90G-BH903-W4EE1-Z66Q9-907E6");
    urlencoded.append("userId", userId);
    urlencoded.append("actualPassword", actualPassword);
    urlencoded.append("newPassword", newPassword);
    urlencoded.append("confirmPassword", confirmPassword);

    let data = await postMap(urlencoded, actionUrl, 'json');
    let result = JSON.parse(data);
    return result;
}