
import React from 'react';
import './spinner.css'; 


const Spinner: React.FC<{scale:number}> = ({scale=1}:{scale:number}) => {
  const radius = 80 ;

  return (
    <div className='spinner-container' style={{ transform: `scale(${scale})`}}>
    <div className="spinner" style={{ width: `${radius * 2}px`, height: `${radius * 2}px` }}>
      <div className="rotating-border">
      </div>
    </div>
    <img className="kls-header-logo" src="/icons/karaz.svg" alt="KSW"></img>
    </div>

  );
};



export default Spinner;
